<div class="mt-2 parent-container">
    <div class="mx-0 mb-3 row" *ngIf="isMobileScreen">
        <div class="text-right col-12">
            <button
                mat-raised-button
                class="gh-button gh-button-primary"
                (click)="composeMessage(user)"
            >
                Compose Message
            </button>
        </div>

        <div class="col-12">
            <mat-form-field class="gh-form-field">
                <mat-label>Messaging Option</mat-label>
                <mat-select (selectionChange)="setType($event)">
                    <mat-option *ngFor="let option of options" [value]="option.value">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="mx-0 row" *ngIf="!isMobileScreen">
        <div class="col-4">
            <button
                mat-raised-button
                class="gh-button gh-button-primary"
                (click)="composeMessage(user)"
            >
                Compose Message
            </button>
        </div>
        <div class="col-8">
            <mat-form-field class="gh-form-field">
                <!-- <mat-label>
                    {{
                        type === 'inbox'
                            ? 'Inbox'
                            : type === 'sent'
                            ? 'Sent'
                            : type === 'archived'
                            ? 'Archived'
                            : 'Messaging Options'
                    }}
                </mat-label> -->
                <mat-select [(value)]="type" (selectionChange)="setType($event)">
                    <mat-option *ngFor="let option of options" [value]="option.value">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div [ngSwitch]="type">
        <!-- * INBOX -->
        <div *ngSwitchDefault="'inbox'">
            <mat-card-title class="gh-sm-title">Inbox</mat-card-title>
            <div class="mt-2">
                <app-messages-table
                    [_dataSource]="recievedMessagesDataSource"
                    [_displayedColumns]="displayedColumnsReceived"
                    [_messageTableType]="inboxMessageTableType"
                    [_user]="user"
                    [_hasDeleteButtons]="hasDeleteButtons"
                    [_hasArchiveButtons]="hasArchiveButtons"
                    (composeMessageEmitter)="composeMessage($event)"
                ></app-messages-table>
            </div>
        </div>

        <!-- * SENT -->
        <div *ngSwitchCase="'sent'">
            <mat-card-title class="gh-sm-title">Sent Messages</mat-card-title>
            <div class="mt-2">
                <app-messages-table
                    [_dataSource]="sentMessagesDataSource"
                    [_displayedColumns]="displayedColumnsSent"
                    [_messageTableType]="sentMessageTableType"
                    [_user]="user"
                    [_hasDeleteButtons]="hasDeleteButtons"
                    [_hasArchiveButtons]="hasArchiveButtons"
                    (composeMessageEmitter)="composeMessage($event)"
                ></app-messages-table>
            </div>
        </div>

        <!-- * ARCHIVED MESSAGES -->
        <div *ngSwitchCase="'archived'">
            <mat-card-title class="gh-sm-title">Archived Messages</mat-card-title>
            <div class="mt-2 px-2">
                <app-messages-table
                    [_dataSource]="archivedMessagesDataSource"
                    [_displayedColumns]="displayedColumnsArchived"
                    [_messageTableType]="archivedMessageTableType"
                    [_user]="user"
                    [_hasDeleteButtons]="hasDeleteButtons"
                    [_hasArchiveButtons]="hasArchiveButtons"
                    (composeMessageEmitter)="composeMessage($event)"
                ></app-messages-table>
            </div>
        </div>
    </div>
</div>
