<div class="parent-container">
    <div class="inner-container">
        <div class="text-center col-12">
            <mat-card-subtitle class="gh-md-subtitle">{{ message }}</mat-card-subtitle>
            <img
                src="../../../../../../assets/images/coolStuff/meeple-looking-down.png"
                alt="no data"
            />
        </div>

        <div class="mt-3 text-center col-12" *ngIf="hasActionButton">
            <button
                mat-raised-button
                class="with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                (click)="openActionComponent()"
            >
                {{ actionButtonText }}
            </button>
        </div>

        <!-- Routing section -->
        <div class="mt-3 text-center col-12" *ngIf="hasRoutingLink">
            <button
                mat-raised-button
                class="with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                (click)="routeTo(routingDestination)"
            >
                Go to {{ routingDestination }}
            </button>
        </div>

        <!-- No LP section -->
        <div class="mt-3 text-center col-12" *ngIf="isLP">
            <button
                mat-raised-button
                class="with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                (click)="openLP()"
            >
                Log a Play!
            </button>
        </div>
    </div>
</div>
