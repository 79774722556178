import { Injectable } from '@angular/core'
import { Auth } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { environment } from 'src/environments/environment'
import {
    iAddRatingInput,
    iCreateGameTrackingItemInput,
    iCreateHavenGameTrackingItem,
    iDeleteFromMyCollectionInput,
    iDeleteFromMyWishlistInput,
    iDeleteGamesILoveInput,
    iDeleteGamesTrackingItemByPkSkInput,
    iDeleteGamesTrackingItemInput,
    iDeleteLookingToPlayInput,
} from '../../../../common/interfaces/GameTrackingInterfaces'
import gql from 'graphql-tag'
import {
    iUpdateSingleAttributeForGameInput,
    iUpdateSingleAttributeForGameTrackingInput,
} from '../../../../common/interfaces/GameInterfaces'
import { BackendAPIGamesService } from './backend-api-games.service'

@Injectable({
    providedIn: 'root',
})
export class BackendApiGameTrackingService {
    constructor(private backendApiGamesService: BackendAPIGamesService) {} // private contentService: ContentService

    private appSyncClient = new AWSAppSyncClient({
        url: environment.apiUrl,
        region: 'us-east-1',
        auth: {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: async () => {
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                return token
            },
        },
        disableOffline: true,
    })

    // ********************************************************************************************
    //!                    CREATE
    // ********************************************************************************************

    async createHavenGameTrackingItem(
        createHavenGameTrackingItemInput: iCreateHavenGameTrackingItem,
    ) {
        let input = createHavenGameTrackingItemInput

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createHavenGameTrackingItem($input: IGQL_CreateHavenGameTrackingItem!) {
                    createHavenGameTrackingItem(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createHavenGameTrackingItem
    }

    // !!!! TEMPLATE
    async createGameTrackingItem(addToMyCollectionInput: iCreateGameTrackingItemInput) {
        let input = addToMyCollectionInput

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createGameTrackingItem($input: IGQL_CreateGameTrackingItem!) {
                    createGameTrackingItem(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createGameTrackingItem
    }

    // !!!! big8Vote
    async createGameTrackingItemAR(addRatingInput: iAddRatingInput) {
        let input = addRatingInput

        const createGameTrackingAddRating = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createGameTrackingAddRating($input: IGQL_CreateGameTrackingAddRating!) {
                    createGameTrackingAddRating(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return createGameTrackingAddRating
    }

    async createGameTrackingAddRating(addRatingInput: iAddRatingInput) {
        let bob = await this.listGameTrackingItemsByGameByUser(
            addRatingInput.gameId,
            `#rating#${addRatingInput.UID}`,
            2,
        )

        let userHasRated = false
        if (bob.games.length == 1) {
            userHasRated = true
        }

        let response = []
        let input = addRatingInput
        const createGameTrackingAddRating = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createGameTrackingAddRating($input: IGQL_CreateGameTrackingAddRating!) {
                    createGameTrackingAddRating(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })

        response.push(createGameTrackingAddRating)

        if (createGameTrackingAddRating.data.createGameTrackingAddRating) {
            const fetchGamePreviousRatingData = await this.fetchGamePreviousRatingData(
                addRatingInput.gameId,
            )

            if (fetchGamePreviousRatingData.numberRatings == null) {
                // set the new number ratings = 1
                let setNumberRatingsInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.gameId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.gameId}`,
                    attributeName: 'numberRatings',
                    attributeValue: '1',
                }

                this.updateSingleAttributeForGame(setNumberRatingsInput)

                //  set the theRating to the rating that the first user just submitted
                let setTheRatingInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.gameId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.gameId}`,
                    attributeName: 'theRating',
                    attributeValue: addRatingInput.rating,
                }
                this.updateSingleAttributeForGame(setTheRatingInput)
            } else {
                // todo fetch the users old rating and set it here
                // todo else set usersOldRating to 0

                let usersOldRating
                let newRating

                if (userHasRated) {
                    usersOldRating = bob.games[0].rating
                    let additionAndSubtractionMath
                    additionAndSubtractionMath = `((${+fetchGamePreviousRatingData.theRating} * ${+fetchGamePreviousRatingData.numberRatings}) + ${+addRatingInput.rating} - ${usersOldRating}) / ${+fetchGamePreviousRatingData.numberRatings}`
                    newRating =
                        (+fetchGamePreviousRatingData.theRating *
                            +fetchGamePreviousRatingData.numberRatings +
                            +addRatingInput.rating -
                            usersOldRating) /
                        +fetchGamePreviousRatingData.numberRatings
                } else {
                    usersOldRating = 0
                    let additionAndSubtractionMath
                    additionAndSubtractionMath = `((${+fetchGamePreviousRatingData.theRating} * ${+fetchGamePreviousRatingData.numberRatings}) + ${+addRatingInput.rating} - ${usersOldRating}) / ${
                        +fetchGamePreviousRatingData.numberRatings + 1
                    }`
                    newRating =
                        (+fetchGamePreviousRatingData.theRating *
                            +fetchGamePreviousRatingData.numberRatings +
                            +addRatingInput.rating -
                            usersOldRating) /
                        (+fetchGamePreviousRatingData.numberRatings + 1)
                    fetchGamePreviousRatingData.numberRatings += 1
                }

                let newNumberRatings = +fetchGamePreviousRatingData.numberRatings

                let setNumberRatingsInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.gameId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.gameId}`,
                    attributeName: 'numberRatings',
                    attributeValue: newNumberRatings,
                }

                this.updateSingleAttributeForGame(setNumberRatingsInput)

                let setTheRatingInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.gameId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.gameId}`,
                    attributeName: 'theRating',
                    attributeValue: newRating,
                }
                this.updateSingleAttributeForGame(setTheRatingInput)
            }
        }
        return response
    }

    // DUPLICATE CODE: BOTH IN GAMESsERVICE AND gAMEtRACKINGsERVICE
    async updateSingleAttributeForGame(input: iUpdateSingleAttributeForGameInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleAttributeForGame(
                    $input: IGQL_UpdateSingleAttributeForGameInput!
                ) {
                    updateSingleAttributeForGame(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })
        return response.data.updateSingleAttributeForGame
    }

    // DUPLICATE CODE: BOTH IN GAMESsERVICE AND gAMEtRACKINGsERVICE
    async GetGame(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        aad
                        id
                        title
                        big8
                        duration
                        age
                        tags
                        big8Votes
                        minPlayers
                        maxPlayers
                        printStatus
                        duration
                        yearPublished
                        minPlaytime
                        maxPlaytime
                        tags
                        minSuggestedAge
                        publishers {
                            username
                            title
                        }
                        designers {
                            username
                            title
                        }
                        artists {
                            username
                            title
                        }
                        isCrowdfunded
                        crowdfundingLink
                        crowdfundingLaunchDate
                        crowdfundingHasLaunched
                        crowdfundingExpiryDate
                        crowdfundingSite
                        createdAt
                        updatedAt
                        mainImageFiles
                        publisherDescription
                        officialLink
                        metaDataForGH
                        internalGhNotes
                        corePK
                        coreSK
                        isExpansion
                        trendingFactor
                        expansions
                        parentItem {
                            id
                            title
                        }
                    }
                }
            `,
            variables: { id },
            fetchPolicy: 'network-only',
        })
        return response.data.getGame
    }

    async createMultipleGameTrackingAddToGamesILove(
        games,
        currentUserID,
        currentUserTitle,
        mainImageFiles,
        state,
        zip,
    ) {
        let result = []
        games.forEach(async (game) => {
            let fetchedGame = await this.GetGame(game.pk)

            // Ensure the currentTrendingFactor is an integer before adding to it.
            // If fetchedGame.trendingFactor is null or undefined, default to 0.
            let currentTrendingFactor = parseInt(fetchedGame.trendingFactor, 10) || 0

            // Perform the addition.
            let newTrendingFactor = currentTrendingFactor + 100

            // Convert back to string and pad.
            let paddedNewTrendingFactor = newTrendingFactor.toString().padStart(10, '0')

            let updateInput = {
                pkName: 'pk',
                pkValue: game.pk,
                skName: 'sk',
                skValue: 'BD#' + game.pk,
                attributeName: 'trendingFactor',
                attributeValue: paddedNewTrendingFactor,
            }

            await this.updateSingleAttributeForGame(updateInput)

            // Your second update logic remains unchanged.
            let updateInput2 = {
                pkName: 'pk',
                pkValue: game.pk,
                skName: 'sk',
                skValue: 'BD#' + game.pk,
                attributeName: 'trendingFactorPK',
                attributeValue: 'g',
            }

            await this.updateSingleAttributeForGame(updateInput2)

            result.push(
                await this.createGameTrackingItem({
                    gameId: game.pk,
                    priority: game.priority,
                    UID: currentUserID,
                    type: 'GIL',
                    userZipCode: zip,
                    userState: state,
                    title: game.title,
                    userTitle: currentUserTitle,

                    mainImageFiles: mainImageFiles,

                    // TODO: using coreSK for now since its the same thing but still need to know why title is null...
                }),
            )
        })

        return result
    }

    async createMultipleGameTrackingAddToGamesWeLove(
        games,
        currentUserID,
        currentUserTitle,
        mainImageFiles,
        state,
        zip,
    ) {
        let result = []
        games.forEach(async (game) => {
            result.push(
                await this.createGameTrackingItem({
                    gameId: game.pk,
                    priority: game.priority,
                    UID: currentUserID,
                    type: 'GWL',
                    userZipCode: zip,
                    userState: state,
                    title: game.coreSK,
                    userTitle: currentUserTitle,
                    mainImageFiles: mainImageFiles,
                    // TODO: using coreSK for now since its the same thing but still need to know why title is null...
                }),
            )
        })
        return result
    }

    async createMultipleGameTrackingAddToLookingToPlay(
        games,
        currentUserID,
        state,
        zip,
        userTitle,
        mainImageFiles,
    ) {
        let result = []
        games.forEach(async (game) => {
            let fetchedGame = await this.GetGame(game.pk)

            let currentTrendingFactor = parseInt(fetchedGame.trendingFactor, 10) || 0

            let newTrendingFactor = currentTrendingFactor + 100

            let paddedNewTrendingFactor = newTrendingFactor.toString().padStart(10, '0')

            let updateInput = {
                pkName: 'pk',
                pkValue: game.pk,
                skName: 'sk',
                skValue: 'BD#' + game.pk,
                attributeName: 'trendingFactor',
                attributeValue: paddedNewTrendingFactor,
            }

            await this.updateSingleAttributeForGame(updateInput)

            let updateInput2 = {
                pkName: 'pk',
                pkValue: game.pk,
                skName: 'sk',
                skValue: 'BD#' + game.pk,
                attributeName: 'trendingFactorPK',
                attributeValue: 'g',
            }

            await this.updateSingleAttributeForGame(updateInput2)

            result.push(
                await this.createGameTrackingItem({
                    gameId: game.pk,
                    priority: game.priority,
                    UID: currentUserID,
                    type: 'LTP',
                    userState: state,
                    userZipCode: zip,
                    title: game.coreSK,
                    userTitle: userTitle,
                    mainImageFiles: mainImageFiles,
                    // TODO: using coreSK for now since its the same thing but still need to know why title is null...
                }),
            )
        })
        return result
    }

    // ! DUPLICATE CODE: BOTH IN GAMESsERVICE AND gAMEtRACKINGsERVICE
    async updateSingleAttributeForGameTrackingItem(
        input: iUpdateSingleAttributeForGameTrackingInput,
    ) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleAttributeForGameTrackingItem(
                    $input: IGQL_UpdateSingleAttributeForGameTrackingInput!
                ) {
                    updateSingleAttributeForGameTrackingItem(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })

        return response.data.updateSingleAttributeForGameTrackingItem
    }

    // ********************************************************************************************
    //!                    DELETE
    // ********************************************************************************************

    async deleteGameTrackingItem(deleteGamesILoveInput: iDeleteGamesTrackingItemInput) {
        let input = deleteGamesILoveInput

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteGameTrackingItem($input: IGQL_DeleteGameTrackingItem!) {
                    deleteGameTrackingItem(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteGameTrackingItem
    }

    async deleteGameTrackingItemByPkSk(
        deleteGamesTrackingItemByPkSkInput: iDeleteGamesTrackingItemByPkSkInput,
    ) {
        let input = deleteGamesTrackingItemByPkSkInput

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteGameTrackingItemByPkSk($input: IGQL_DeleteGameTrackingItemByPkSk!) {
                    deleteGameTrackingItemByPkSk(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteGameTrackingItemByPkSk
    }

    async deleteMultipleGameTrackingGamesILove(games, currentUserId) {
        let res = []

        for (let game of games) {
            let input = {
                gameId: game.id,
                UID: currentUserId,
                type: 'GIL',
                title: game.title, // ! not sure this is needed, we probably want an ID that is set in Backend?
            }

            let deletedGame = await this.deleteGameTrackingItem(input)
            res.push(deletedGame)
        }
        return res
    }

    async deleteMultipleGameTrackingLookingToPlay(games, currentUserId) {
        let res = []

        for (let game of games) {
            let input = {
                gameId: game.id,
                UID: currentUserId,
                type: 'LTP',
                title: game.title, // ! not sure this is needed, we probably want an ID that is set in Backend?
            }

            let deletedGame = await this.deleteGameTrackingItem(input)
            res.push(deletedGame)
        }
        return res
    }

    async deleteMultipleGameTrackingGamesWeLove(games, currentUserId) {
        let res = []

        for (let game of games) {
            let input = {
                gameId: game.id,
                UID: currentUserId,
                type: 'GWL',
                title: game.title, // ! not sure this is needed, we probably want an ID that is set in Backend?
            }

            let deletedGame = await this.deleteGameTrackingItem(input)
            res.push(deletedGame)
        }
        return res
    }

    async deleteGameTrackingRating(pk, sk) {
        let input = {
            pk: pk,
            sk: sk,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteGameTrackingRating($input: IGQL_DeleteGameTrackingRating!) {
                    deleteGameTrackingRating(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteGameTrackingRating
    }

    // ********************************************************************************************
    //!                    LIST
    // ********************************************************************************************

    async listHavenInventoryItems(id: string, type: string, limit: number, nextToken?) {
        // type = type + '#USA'

        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listHavenInventoryItems(
                    $id: String
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listHavenInventoryItems(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        games {
                            id
                            title
                            pk_GAMEID
                            sk_TYPE_UID_ULID
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byGameSK_LPID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            updatedAt
                            rating
                            mainImageFiles
                            SK_byType_byZip
                            PK_GAMEID
                            notes
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listHavenInventoryItems
    }

    async listHavenInventoryItemsByGameByType(id: string, type: string, limit: number, nextToken?) {
        type = '#' + type + '#'
        // type = type + '#USA'

        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listHavenInventoryItemsByGameByType(
                    $id: String
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listHavenInventoryItemsByGameByType(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        games {
                            title
                            pk_GAMEID
                            sk_TYPE_UID_ULID
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byGameSK_LPID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            updatedAt
                            rating
                            mainImageFiles
                            SK_byType_byZip
                            PK_GAMEID
                            userTitle
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })

        return response.data.listHavenInventoryItemsByGameByType
    }

    async listGameTrackingItemsByType(type: string, limit: number, nextToken?: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGameTrackingItemsByType($type: String, $limit: Int, $nextToken: String) {
                    listGameTrackingItemsByType(type: $type, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            rating
                            big8Vote
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byGameSK_LPID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            id
                            mainImageFiles
                            notes
                            pk_GAMEID
                            SK_byType_byZip
                            sk_TYPE_UID_ULID
                            title
                            updatedAt
                            userTitle
                        }
                    }
                }
            `,
            variables: { type, limit, nextToken },
            fetchPolicy: 'network-only',
        })

        // Extract the raw data
        const data = response.data.listGameTrackingItemsByType

        // Sort the games array so that the newest (by createdAt) is first
        const sortedGames = data.games.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )

        // Return the same structure, but with the games array sorted descending
        return {
            ...data,
            games: sortedGames,
        }
    }

    // ! GENERIC TEMPLATE
    async listGameTrackingItemsByUserAndType(id: string, type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGameTrackingItemsByUserAndType(
                    $id: ID!
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listGameTrackingItemsByUserAndType(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        games {
                            id
                            title
                            pk_GAMEID
                            sk_TYPE_UID_ULID
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byGameSK_LPID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            updatedAt
                            mainImageFiles
                            big8Vote
                            notes
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listGameTrackingItemsByUserAndType
    }

    async listMyContentByType(id: string, type: string, limit: number, nextToken?) {
        if (type != 'notification') {
            id = '#' + id
        }

        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listMyContentByType(
                    $id: ID!
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listMyContentByType(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        content {
                            reviewImage
                            id
                            title
                            createdAt
                            updatedAt
                            addedByUID
                            gameToReview
                            havenToReview
                            reviewImage
                            big8
                            editor
                            textOnlyVersionOfReview
                            rating
                            createdByUID
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listMyContentByType
    }

    async listUserRatingsByID(id: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listUserRatingsByID($id: ID!, $limit: Int, $nextToken: String) {
                    listUserRatingsByID(id: $id, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            id
                            pk_GAMEID
                            sk_TYPE_UID_ULID
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byGameSK_LPID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            updatedAt
                            title
                            rating
                            mainImageFiles
                            notes
                        }
                    }
                }
            `,
            variables: { id, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listUserRatingsByID
    }

    async listReviewsByUserID(userId: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listReviewsByUserID($userId: String!, $limit: Int, $nextToken: String) {
                    listReviewsByUserID(userId: $userId, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        reviews {
                            reviewImage
                            id
                            title
                            createdAt
                            updatedAt
                            addedByUID
                            gameToReview
                            havenToReview
                            reviewImage
                            big8
                            editor
                            textOnlyVersionOfReview
                            rating
                            createdByUID
                        }
                    }
                }
            `,
            variables: { userId, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listReviewsByUserID
    }

    // async listGameRatingsByID(id: string, limit: number, nextToken?) {
    //     const response = await this.appSyncClient.query<any>({
    //         query: gql`
    //             query listGameRatingsByID($id: ID!, $limit: Int, $nextToken: String) {
    //                 listGameRatingsByID(id: $id, limit: $limit, nextToken: $nextToken) {
    //                     nextToken
    //                     games {
    //                         pk_GAMEID
    //                         sk_TYPE_UID_ULID
    //                         byGame_byType_byLocation_byUser_PK_GAMEID
    //                         byGameSK_LPID
    //                         byUser_byType_byGame_PK_UID
    //                         byUser_byType_byGame_SK_TYPE_GAMEID_ULID
    //                         createdAt
    //                         updatedAt
    //                         title
    //                         rating
    //                     }
    //                 }
    //             }
    //         `,
    //         variables: { id, limit, nextToken },
    //         fetchPolicy: 'network-only',
    //     })
    //     return response.data.listGameRatingsByID
    // }

    async listGameTrackingItemsByGameByType(id: string, type: string, limit: number, nextToken?) {
        type = '#' + type

        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGameTrackingItemsByGameByType(
                    $id: String!
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listGameTrackingItemsByGameByType(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        games {
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            PK_GAMEID
                            SK_byType_byZip
                            title
                            updatedAt
                            userTitle
                            mainImageFiles
                            sk_TYPE_UID_ULID
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listGameTrackingItemsByGameByType
    }

    async listGameTrackingItemsByGameByUser(id: string, prefix: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGameTrackingItemsByGameByUser(
                    $id: String!
                    $prefix: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listGameTrackingItemsByGameByUser(
                        id: $id
                        prefix: $prefix
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        games {
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            PK_GAMEID
                            SK_byType_byZip
                            title
                            updatedAt
                            userTitle
                            mainImageFiles
                            big8Vote
                            rating
                        }
                    }
                }
            `,
            variables: { id, prefix, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listGameTrackingItemsByGameByUser
    }

    async listHavenTrackingItemsByGameByType(id: string, type: string, limit: number, nextToken?) {
        type = '#' + type

        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listHavenTrackingItemsByGameByType(
                    $id: String!
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listHavenTrackingItemsByGameByType(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        games {
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            PK_GAMEID
                            SK_byType_byZip
                            title
                            updatedAt
                            userTitle
                            mainImageFiles
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listHavenTrackingItemsByGameByType
    }

    // ********************************************************************************************
    //!                    OTHER
    // ********************************************************************************************

    // todo SOMEDAY, fix this issue where this hsould be in the games service Shay's feelings are hurt
    async fetchGamePreviousRatingData(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        numberRatings
                        theRating
                    }
                }
            `,
            variables: { id },
            fetchPolicy: 'network-only',
        })
        return response.data.getGame
    }
}
