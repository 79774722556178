<div class="parent-container">
    <mat-card class="info-card">
        <mat-card-header>
            <mat-card-title>{{ inputData.message }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p class="bio-paragraph">{{ inputData.message2 }}</p>
        </mat-card-content>
    </mat-card>
</div>
