import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGroupsService } from 'src/app/backend-api-services/backend-api-groups.service'
import { BackendAPIHavensService } from 'src/app/backend-api-services/backend-api-havens.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { GroupsService } from 'src/app/dashboard/dashboard-shared/services/groups/groups.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'

@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-share-modal-tags',
  templateUrl: './share-modal-tags.component.html',
  styleUrls: ['./share-modal-tags.component.scss']
})
export class ShareModalTagsComponent implements OnInit {

  coreGames
  coreHavens
  coreGroups

  myGroups = new BehaviorSubject(null)
  myGroups$ = this.myGroups.asObservable()

  games = new BehaviorSubject(null)
  games$ = this.games.asObservable()

  havens = new BehaviorSubject(null)
  havens$ = this.havens.asObservable()

  groups = new BehaviorSubject(null)
  groups$ = this.groups.asObservable()

  coreUsers = new BehaviorSubject(null)
  coreUsers$ = this.coreUsers.asObservable()

  myEvents = new BehaviorSubject(null)
  myEvents$ = this.myEvents.asObservable()

  newFoundGameIndices = []
  newFoundHavenIndices = []
  newFoundPlayerIndices = []
  newFoundGroupIndices = []
  newFoundEventIndices = []

  newFoundGames = []
  newFoundHavens = []
  newFoundPlayers = []
  newFoundGroups = []
  newFoundEvents = []

  @Output() hasProblematicTags = new EventEmitter()
  @Output() goToFixTagsEmitter = new EventEmitter()

  @Output() selectedUserToTagEmitter = new EventEmitter()
  @Output() selectedGameToTagEmitter = new EventEmitter()
  @Output() selectedHavenToTagEmitter = new EventEmitter()
  @Output() selectedGroupToTagEmitter = new EventEmitter()
  @Output() selectedEventToTagEmitter = new EventEmitter()

  @Output() problematicGameTagsEmitter = new EventEmitter()
  @Output() problematicHavenTagsEmitter = new EventEmitter()
  @Output() problematicPlayerTagsEmitter = new EventEmitter()
  @Output() problematicGroupTagsEmitter = new EventEmitter()
  @Output() problematicEventTagsEmitter = new EventEmitter()

  constructor(
    private gamesService: GamesService,
    private backendApiHavensService: BackendAPIHavensService,
    private backendAPIUsersService: BackendAPIUsersService,
    private groupsService: GroupsService,
    private backendApiUsersService: BackendAPIUsersService,
    private backendApiContentService: BackendAPIContentService,
    private usersService: UsersService
  ) { }


  user
  async ngOnInit() {


    await this.groupsService.myGroups$.pipe(untilDestroyed(this)).subscribe(async myGroups => {
      if (myGroups) {
        this.myGroups = myGroups
        this.coreGroups = myGroups
      }
    })

    let coreHavensResult = await this.backendApiHavensService.listAllHavenCoreData('#', 19999)
    this.coreHavens = coreHavensResult.coreHavens

    await this.gamesService.coreGames$.pipe(untilDestroyed(this)).subscribe(async coreGames => {
      if (coreGames) {
        this.coreGames = coreGames
      }
    })

    await this.gamesService.coreGames$.pipe(untilDestroyed(this)).subscribe(async coreGames => {
      if (coreGames) {
        this.coreGames = coreGames
      }
    })


    this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })


    this.usersService.coreUsers$.pipe(untilDestroyed(this)).subscribe(async coreUsersResult => {
      this.coreUsers.next(coreUsersResult)
    })

    let fetchedEvents = await this.backendApiContentService.listCalendarEventsById(
      this.user.username,
      249,
    )

    this.myEvents.next(fetchedEvents.calendarItems)

  }


  selectUser(user) {
    let selectedUserToEmit = {
      userId: user[0].username,
      userTitle: user[0].title
    }

    this.selectedUserToTagEmitter.emit(selectedUserToEmit)
  }


  selectGame(game) {
    let selectedGameToEmit = {
      gameId: game[0].pk,
      gameTitle: game[0].title
    }

    this.selectedGameToTagEmitter.emit(selectedGameToEmit)

    // todo Need to use this captured game and deposit it into the form (up one level?)
    // this.form.get('gamePlayed').patchValue(game[0].title)

  }

  selectHaven(haven) {
    let selectedHavenToEmit = {
      havenId: haven[0].pk,
      havenTitle: haven[0].title
    }
    this.selectedHavenToTagEmitter.emit(selectedHavenToEmit)
  }


  selectGroup(group) {
    let selectedGroupToEmit = {
      groupId: group[0].pk,
      groupTitle: group[0].title
    }
    this.selectedGroupToTagEmitter.emit(selectedGroupToEmit)
  }


  selectEvent(event) {
    let selectedEventToEmit = {
      eventId: event[0].pk,
      eventTitle: event[0].title
    }
    this.selectedEventToTagEmitter.emit(selectedEventToEmit)
  }






  // ?OLDER THINGS, FUTURE FEATURES, MAYBE NEVER ******************************
  //  OLDER THINGS, FUTURE FEATURES, MAYBE NEVER ******************************


  checkForUnfoundItemIndices(newItems, baseItems) {
    var unfoundItemIndices = []
    for (var i = 0; i < newItems.length; i++) {
      if (!baseItems.includes(newItems[i])) {
        unfoundItemIndices.push(i)
      }
    }
    return unfoundItemIndices
  }

  setUnfoundItems(newItems, baseItems) {
    var unfoundItems = []
    for (var i = 0; i < newItems.length; i++) {
      if (!baseItems.includes(newItems[i])) {
        unfoundItems.push(newItems[i])
      }
    }
    return unfoundItems
  }

  // this.newFoundEventIndices.length > 0 || 
  checkForProblematicTags() {
    let result = this.newFoundPlayerIndices.length > 0 ||
      this.newFoundGameIndices.length > 0 ||
      this.newFoundHavenIndices.length > 0 ||
      this.newFoundGroupIndices.length > 0
    if (result) {
      this.hasProblematicTags.emit()
    }
    return result
  }

  goToFixTags() {
    this.goToFixTagsEmitter.emit()
  }

}
