<div>
    <div
        class="gh-image-upload-container gh-image-upload-container-mobile row"
        [hidden]="!isMobileScreen"
    >
        <div class="col-12">
            <div class="row">
                <div class="text-center col-12">
                    <div *ngIf="imageIsSelected">
                        <button
                            mat-stroked-button
                            id="crop-button"
                            class="gh-stroked-button gh-stroked-button-primary"
                            (click)="openCropper()"
                        >
                            <mat-icon class="gh-icon">crop</mat-icon>
                            Crop Image
                        </button>
                    </div>
                </div>

                <div class="col-12">
                    <div class="gh-file-upload-button-container">
                        <label
                            for="{{ inputId }}"
                            class="gh-file-upload-button"
                            [ngClass]="{ imageIsSelected: imageIsSelected }"
                        >
                            <mat-icon
                                class="gh-icon"
                                [ngClass]="{ imageIsSelected: imageIsSelected }"
                            >
                                {{ imageIsSelected ? 'edit' : 'backup' }}</mat-icon
                            >
                            {{ imageIsSelected ? 'Change Image' : 'Upload Image' }}
                        </label>
                        <input id="{{ inputId }}" type="file" (change)="onFileChanged($event)" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="img-container img-container-mobile">
                <ng-container *ngTemplateOutlet="images"></ng-container>

                <div id="delete-icon-container" *ngIf="imageIsSelected">
                    <mat-icon
                        id="delete-icon"
                        class="gh-icon gh-icon-clickable"
                        (click)="deleteSelectedImage()"
                        >close
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="isMobileScreen">
        <div class="gh-image-upload-container">
            <span class="inline-span">
                <div *ngIf="imageIsSelected">
                    <button
                        mat-stroked-button
                        id="crop-button"
                        class="gh-stroked-button gh-stroked-button-primary"
                        (click)="openCropper()"
                    >
                        <mat-icon class="gh-icon">crop</mat-icon>
                        Crop Image
                    </button>
                </div>
                <div class="gh-file-upload-button-container">
                    <label
                        for="{{ inputId }}"
                        class="gh-file-upload-button"
                        [ngClass]="{ imageIsSelected: imageIsSelected }"
                    >
                        <mat-icon class="gh-icon" [ngClass]="{ imageIsSelected: imageIsSelected }">
                            {{ imageIsSelected ? 'edit' : 'backup' }}</mat-icon
                        >
                        {{ imageIsSelected ? 'Change Image' : 'Upload Image' }}
                    </label>
                    <input id="{{ inputId }}" type="file" (change)="onFileChanged($event)" />
                </div>
            </span>

            <span class="inline-span ml-3">
                <div class="img-container">
                    <ng-container *ngTemplateOutlet="images"></ng-container>
                    <!-- Delete button (no container) -->
                    <svg
                        (click)="deleteSelectedImage()"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style="cursor: pointer"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <!-- Black circle background -->
                        <circle cx="12" cy="12" r="12" fill="red"></circle>

                        <!-- White "X" made from two intersecting lines -->
                        <line
                            x1="8"
                            y1="8"
                            x2="16"
                            y2="16"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                        <line
                            x1="16"
                            y1="8"
                            x2="8"
                            y2="16"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>
            </span>
        </div>
    </div>
</div>

<ng-template #images>
    <img
        [hidden]="fileIsHeic"
        [src]="selectedImage"
        alt=""
        [ngClass]="{ isProfileImg: isProfileImg }"
    />

    <img
        [hidden]="!fileIsHeic"
        [appHeicToJpeg]="heicPreview"
        alt=""
        [ngClass]="{ isProfileImg: isProfileImg }"
    />
</ng-template>
