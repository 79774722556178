<div class="align-items-center mt-1 mb-1 p-0 row">
    <!-- Filter Section -->
    <div class="align-items-center col-md-6">
        <ng-container *ngIf="isFilterEnabled()">
            <mat-form-field appearance="standard" class="w-100">
                <mat-label>{{ getFilterLabel() }}</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ex. John" />
            </mat-form-field>
        </ng-container>
    </div>

    <!-- Button Section -->
    <div class="align-items-center text-end col-md-6">
        <button mat-raised-button class="gh-button gh-button-primary" (click)="sendAnnouncement()">
            Send Convention Announcement
        </button>
    </div>
</div>

<!-- Content Section -->
<div class="mx-0 mb-3 px-0 data-group row">
    <div class="w-100 col-12">
        <div class="wrapper">
            <!-- Simple Table to Display finalUserData -->
            <div class="table-container">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    class="mat-elevation-z8 mat-table"
                    matSort
                >
                    <!-- Title Column -->
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                        <td mat-cell *matCellDef="let user">
                            <a
                                href="#"
                                class="gh-link"
                                (click)="openUserDetails(user); $event.preventDefault()"
                            >
                                {{ user.title }}
                            </a>
                        </td>
                    </ng-container>

                    <!-- User Level Column -->
                    <ng-container matColumnDef="userLevel">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Level</th>
                        <td mat-cell *matCellDef="let user">
                            {{ user.userLevel }}
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let user">
                            {{ user.email }}
                        </td>
                    </ng-container>

                    <!-- State Column -->
                    <!-- State Column -->
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                        <td mat-cell *matCellDef="let user">
                            {{ user.state }}
                        </td>
                    </ng-container>

                    <!-- Zip Column -->
                    <ng-container matColumnDef="zip">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip Code</th>
                        <td mat-cell *matCellDef="let user">
                            {{ user.zipCode }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
