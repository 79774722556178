import { Component, Input, OnInit, ViewChild } from '@angular/core'
import {
    FormControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { ONBOARDING_GAME_SURVEY_DATA } from '../../constants/mock-data'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { iCreateOrUpdateHavenInput } from '../../../../../../common/interfaces/HavenInterfaces'
import { BackendAPIHavensService } from 'src/app/backend-api-services/backend-api-havens.service'
import { adoptionOriginEnums } from 'src/app/shared/enums/adoption-enums'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { CoreUser } from './newUserInterfaces'
import { UsersService } from '../../dashboard-shared/services/users/users.service'
import { SnackbarService } from '../../dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatDialogRef } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html',
    styleUrls: ['./new-user.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
})
export class NewUserComponent implements OnInit {
    useTabGroup = false

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submit()
        }
    }

    mapShouldInit = false
    selectedTabIndex = 0 // NOTE: important to default this value!!!
    user: any
    maxAreasOfInterest = 1
    areasOfInterest = null
    gameSurveyData = ONBOARDING_GAME_SURVEY_DATA

    @ViewChild('stepper', { static: false }) stepper: any
    pageTitle = 'Welcome to Game Haven'
    hasCloseButton = false

    usernameAndAOIForm: UntypedFormGroup
    coreUsersToSearch: CoreUser[] = []

    // This will track if we've checked for availability of a username
    usernameAvailable = false
    usernameUnavailable = false
    checkedForAvailibilityOfUsername = false

    // If you want to store if user typed an email, but we already have a
    // custom validator that sets an error, so it's optional
    // usernameIsEmail = false;

    // Flag for responsiveness
    isMobileScreen = false

    // For storing a newly selected ZIP code
    zipCode: string | null

    newHavenInputData: any
    havenTitleIsAvailible = false

    profileImage: any
    bio: string

    // Provide your origin list
    currentStatusTypes = [
        adoptionOriginEnums.Friend,
        adoptionOriginEnums.Family,
        adoptionOriginEnums.Ad,
        adoptionOriginEnums.Store,
        adoptionOriginEnums.Event,
        adoptionOriginEnums.Other,
    ]

    viewIsLoaded = false

    constructor(
        private dialogRef: MatDialogRef<NewUserComponent>,
        private formBuilder: UntypedFormBuilder,
        private backendApiContentService: BackendAPIContentService,
        private backendApiUsersService: BackendAPIUsersService,
        private backendAPIHavensService: BackendAPIHavensService,
        private usersService: UsersService,
        private snackbarService: SnackbarService,
        private screenSizeService: ScreenSizeService,
    ) {}

    // Custom validator to disallow email
    noEmailValidator(control: AbstractControl): ValidationErrors | null {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (control.value && emailRegex.test(control.value)) {
            return { noEmailAllowed: true }
        }
        return null
    }

    ngOnInit(): void {
        // Subscribe to list of existing users
        this.usersService.coreUsers$
            .pipe(untilDestroyed(this))
            .subscribe((coreUsersResult: CoreUser[]) => {
                // Make titles lowercase for consistent searching
                this.coreUsersToSearch = this.makeTitlesLowercase(coreUsersResult)
            })

        // Check screen size for layout responsiveness
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        // Get current user
        this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })

        // Initialize form
        this.usernameAndAOIForm = this.formBuilder.group({
            zipCode: [this.user?.zipCode || '', [Validators.minLength(5), Validators.maxLength(5)]],
            uniqueUserName: new FormControl('', [
                Validators.required,
                Validators.maxLength(25),
                this.noEmailValidator, // <-- disallow email addresses
            ]),
            howTheyFoundOutAboutUs: '',
            activityStatus: [''], // If you want to store the “How they found out” here
        })
    }

    ngAfterViewChecked(): void {
        if (!this.viewIsLoaded && this.stepper) {
            this.viewIsLoaded = true
        }
    }

    /**
     * Utility function: lowercases `title` field in array of users
     */
    makeTitlesLowercase(coreUsers: CoreUser[]): CoreUser[] {
        return coreUsers.map((coreUser) => {
            if (coreUser.title && typeof coreUser.title === 'string') {
                return { ...coreUser, title: coreUser.title.toLowerCase() }
            }
            return coreUser
        })
    }

    selectZipCode(zipCode: string): void {
        this.zipCode = zipCode
    }

    close(): void {
        this.dialogRef.close()
    }

    setProfilePicture(event: any): void {
        this.profileImage = event
    }

    setBio(event: any): void {
        this.bio = event.bio
    }

    setLocationSelected(event: any): void {
        this.usernameAndAOIForm.get('zipCode')?.patchValue(event.primaryAOI.zipCode)
        this.usernameAndAOIForm.get('state')?.patchValue(event.primaryAOI.state)
    }

    catchHavenFormChange(event: any): void {
        this.newHavenInputData = event
    }

    catchIsValideHavenNameEmitter(event: boolean): void {
        this.havenTitleIsAvailible = event
    }

    /**
     * Submit the new user or new haven data.
     */
    async submit(): Promise<void> {
        // USER SUBMISSION ***************************************************************
        if (this.selectedTabIndex === 0) {
            // We only proceed if:
            // 1) The user has entered something for 'uniqueUserName'
            // 2) That username is available
            // 3) The form is valid (which checks no email usage, etc.)
            const userNameControl = this.usernameAndAOIForm.get('uniqueUserName')
            if (
                userNameControl &&
                userNameControl.valid &&
                this.usernameAvailable &&
                userNameControl.value
            ) {
                try {
                    const currentUser = await this.backendApiUsersService.getMyProfile()
                    // Populate the data
                    currentUser.title = userNameControl.value
                    currentUser.gsi2sk = userNameControl.value

                    currentUser.corePK = 'u'
                    currentUser.coreSK = '#' + userNameControl.value

                    currentUser.userIsHaven = false
                    currentUser.isNewUserFlag = false
                    currentUser.zipCode = this.zipCode

                    // Update the user with image if any
                    await this.backendApiUsersService.updateUser(
                        currentUser,
                        this.profileImage ? this.profileImage.rawImage : null,
                    )

                    // Send an email indicating how user found the service
                    const acquisitionInput = {
                        replyToEmail: this.user.email,
                        toEmail: 'support@mygamehaven.com',
                        messageText:
                            'Source: ' +
                            this.usernameAndAOIForm.get('howTheyFoundOutAboutUs')?.value,
                        subject: 'User Title: ' + userNameControl.value,
                    }
                    this.backendApiContentService.createSESRequest(acquisitionInput)

                    this.dialogRef.close()
                } catch (err) {
                    console.error(err)
                    this.snackbarService.openErrorSnackBar(
                        'Something went wrong. Please try again.',
                    )
                }
            } else {
                this.snackbarService.openErrorSnackBar(
                    'Please enter a valid (non-email) username, fewer than 25 characters.',
                )
            }
        }

        // HAVEN SUBMISSION ***************************************************
        if (this.selectedTabIndex === 1) {
            if (this.havenTitleIsAvailible) {
                try {
                    const currentUser = await this.backendApiUsersService.getMyProfile()

                    currentUser.title = this.newHavenInputData.havenTitle
                    currentUser.gsi2sk = this.newHavenInputData.havenTitle

                    currentUser.corePK = 'h'
                    currentUser.coreSK = '#' + this.newHavenInputData.havenTitle

                    currentUser.userIsHaven = true
                    currentUser.isNewUserFlag = false
                    currentUser.defaultHaven = currentUser.username
                    currentUser.address = this.newHavenInputData.address
                    currentUser.state = this.newHavenInputData.state
                    // For now, explicitly setting 55906 or use the user-provided value
                    currentUser.zipCode = 55906
                    currentUser.coordinates = this.newHavenInputData.coordinates

                    const updateResult = await this.backendApiUsersService.updateUser(
                        currentUser,
                        this.profileImage ? this.profileImage.rawImage : null,
                    )

                    // Only create the Haven after the user has been updated successfully
                    if (updateResult) {
                        await this.createHaven(currentUser.username)
                    }
                    this.snackbarService.openSuccessSnackBar('Info saved. Thank you!')
                    this.dialogRef.close()
                } catch (err) {
                    console.error(err)
                    this.snackbarService.openErrorSnackBar('Error creating haven')
                }
            } else {
                this.snackbarService.openErrorSnackBar('Haven Title is NOT available')
            }
        }
    }

    /**
     * Helper to create a Haven entry
     */
    async createHaven(havenId: string): Promise<void> {
        const haven: iCreateOrUpdateHavenInput = {
            addedByUID: this.user.username,
            title: this.newHavenInputData.havenTitle,
            description: this.newHavenInputData.description,
            address: this.newHavenInputData.address,
            state: this.newHavenInputData.state,
            zipCode: this.newHavenInputData.zipCode,
            coordinates: this.newHavenInputData.coordinates,
            isOfficial: true,
            isPublic: true,
            timeZone: this.newHavenInputData.timeZone,
            havenType: this.newHavenInputData.havenType,
            activityStatus: this.newHavenInputData.activityStatus,
            dateEstablished: this.newHavenInputData.dateEstablished,
            userWhoUpdated: this.user.username,
            havenWebsite: this.newHavenInputData.havenWebsite,
            havenAdmin: this.user.title,
            id: havenId,
            hasDemoLibrary: this.newHavenInputData.hasDemoLibrary,
            hasLendingLibrary: this.newHavenInputData.hasLendingLibrary,
            hasNormallyForSale: this.newHavenInputData.hasNormallyForSale,
        }

        const files =
            this.newHavenInputData.mainImageFiles && this.newHavenInputData.mainImageFiles !== null
                ? [this.newHavenInputData.mainImageFiles]
                : null

        await this.backendAPIHavensService.CreateHaven(haven, files)
    }

    /**
     * Fired whenever the tab changes (user/haven tabs)
     */
    tabChanged(event: any): void {
        this.selectedTabIndex = event.index
        this.mapShouldInit = event.index === 1
    }

    /**
     * Example cancellation or close
     */
    cancelTesting(): void {
        this.dialogRef.close()
    }

    /**
     * Compare function for mat-select
     */
    compareFunction(o1: any, o2: any): boolean {
        return o1 === o2
    }

    /**
     * Search a nested object for a matching 'title' field
     */
    searchObject(obj: any, searchString: string): boolean {
        if (typeof obj !== 'object' || obj === null) {
            return false
        }
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                const value = obj[key]
                if (typeof value === 'object' && value !== null) {
                    if (this.searchObject(value, searchString)) {
                        return true
                    }
                } else if (key === 'title' && value === searchString) {
                    return true
                }
            }
        }
        return false
    }

    /**
     * Check if username is taken and set availability flags.
     */
    async getUserByTitle(): Promise<void> {
        // Reset flags each time the user changes input
        this.usernameAvailable = false
        this.usernameUnavailable = false

        const userNameControl = this.usernameAndAOIForm.get('uniqueUserName')

        // If there's valid input (also ensures noEmailValidator hasn't triggered an error)
        if (userNameControl && userNameControl.valid) {
            this.checkedForAvailibilityOfUsername = true

            const searchText = userNameControl.value.toLowerCase()
            const isUsernameTaken = this.searchObject(this.coreUsersToSearch, searchText)

            this.usernameAvailable = !isUsernameTaken
            this.usernameUnavailable = isUsernameTaken
        } else {
            // The control is invalid (could be due to email or exceeding length).
            // In that case, do not mark anything about availability.
            this.checkedForAvailibilityOfUsername = false
        }
    }

    /**
     * Fired when "How did you find out about us?" changes
     */
    howTheyFoundOutAboutUsChanged(event: any): void {
        this.usernameAndAOIForm.get('howTheyFoundOutAboutUs')?.patchValue(event.value)
    }
}
