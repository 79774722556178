import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { ImageCropperComponent } from '../../custom-inputs/image-cropper/image-cropper.component'


import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-create-link[_sourceOfInput]',
    templateUrl: './create-link.component.html',
    styleUrls: ['./create-link.component.scss'],
})
export class CreateLinkComponent implements OnInit {
    @Input() _sourceOfInput: string

    isDialog = true
    @Input() set _isDialog(isDialog: boolean) {
        if (isDialog) {
            this.isDialog = isDialog
        }
    }

    inputData
    @Input() set _inputData(inputData) {
        this.inputData = inputData
    }

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.close()
        }
    }

    selectedImage

    linkForm: UntypedFormGroup
    newLink
    newLinkTitle
    newLinkDescription
    newLinkURL

    @Output() closeEmitter = new EventEmitter()
    @Output() imageEmitter = new EventEmitter()
    @Output() linkInfoChangeEmitter = new EventEmitter()

    isEditable = true

    currentLink = '../../../../../../assets/images/icons/webLinkTemp.png'

    uploadImageDialog$: Subscription = null
    subscriptions: Subscription[] = [this.uploadImageDialog$]

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<CreateLinkComponent>,
        private dialog: MatDialog,
        private screenSizeService: ScreenSizeService,
        private snackbarService: SnackbarService,

        private snackbar: MatSnackBar,
    ) { }

    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.linkForm = this.formBuilder.group({
            title: ['', Validators.required],
            description: '',
            link: ['', Validators.required],
        })
    }

    uploadImage() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Upload Image',
                component: ImageCropperComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
        })
        this.uploadImageDialog$ = dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.currentLink = data
            }
        })
    }

    close() {
        if (this.linkForm.valid) {
            this.snackbarService.openSuccessSnackBar('New Link Created')

            if (this.isDialog) {
                this.newLinkTitle = this.linkForm.get('title').value
                this.newLinkDescription = this.linkForm.get('description').value
                this.newLinkURL = this.linkForm.get('link').value

                this.closeEmitter.emit({
                    title: this.linkForm.get('title').value,
                    description: this.linkForm.get('description').value,
                    link: this.linkForm.get('link').value,
                    rawImage: this.selectedImage,
                })

                // todo Shay figure out where dialog is whatever
                this.dialogRef.close()
            }
        }
    }

    sendData() {
        this.linkInfoChangeEmitter.emit({
            title: this.linkForm.get('title').value,
            description: this.linkForm.get('description').value,
            link: this.linkForm.get('link').value,
        })
    }

    setSelectedImage(event) {
        this.selectedImage = event
        this.imageEmitter.emit(this.selectedImage)
    }

}
