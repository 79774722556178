import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import {
    UntypedFormBuilder,
    FormControl,
    UntypedFormGroup,
    MinLengthValidator,
    Validators,
} from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import Fuse from 'fuse.js'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import {
    GH_PRO_INFO_MESSAGE_BULLETS_HEADER,
    GH_PRO_INFO_MESSAGE_BULLETS,
} from 'src/app/dashboard/dashboard-shared/constants/info-message-constants'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { GeoService } from 'src/app/shared/services/geo.service'
import { ResetPasswordComponent } from './components/reset-password/reset-password.component'
import { SoundEffectsService } from 'src/app/dashboard/dashboard-shared/services/audio/sound-effects.service'
import { ColorSchemeService } from 'src/app/shared/services/color-scheme.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-manage-account',
    templateUrl: './manage-account.component.html',
    styleUrls: ['./manage-account.component.scss'],
})
export class ManageAccountComponent implements OnInit {
    maxAreasOfInterest = 1
    areasOfInterest = {
        primaryAOI: null,
        additionalAOI1: null,
        additionalAOI2: null,
        additionalAOI3: null,
    }

    options
    filteredOptions: Observable<string[]>

    fuseOptions = {
        // move to constants
        threshold: 0.15, // the lower the number the better match needed, 0-1
        keys: [
            // "zip",
            'city',
            // "state_id"
        ],
    }
    fuse: any
    fuseResults: any[] = []
    fuseResultString = ''
    // fuseOptions = this.fuseOptions

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submit()
        }
    }

    // parentLeftActionButtonClicked = false
    // @Input() set _parentLeftActionButtonClicked(parentLeftActionButtonClicked: boolean) {
    //     if (parentLeftActionButtonClicked) {
    //         this.openResetPassword()
    //     }
    // }

    @Output() onCloseOrSubmissionEmitter = new EventEmitter()
    @Output() blurContentEmitter = new EventEmitter()

    @ViewChild('privacyButtonToggleGroup', { static: false }) privacyButtonToggleGroup

    user

    upgradeAccountInput = {
        message: 'Would be pretty cool if you did this...',
        subMessage: 'For real, try it out...',
        bulletsHeader: GH_PRO_INFO_MESSAGE_BULLETS_HEADER,
        bullets: GH_PRO_INFO_MESSAGE_BULLETS,
        image: null,
    }

    privacySettings = []
    communicationSettings = []

    panelOpenState = false
    showDefaultValuesErrorMessage = false

    isEditingEmail = false
    isEditingPassword = false
    isEditingZipCode = false

    submitButtonClicked = false

    passwordLastChanged: string

    constructor(
        private backendAPIUsersService: BackendAPIUsersService,
        private snackbar: MatSnackBar,
        private authService: AuthService,
        private screenSizeService: ScreenSizeService,
        private snackbarService: SnackbarService,

        private formBuilder: UntypedFormBuilder,
        private geoService: GeoService,
        private dialog: MatDialog,
        private soundEffectsService: SoundEffectsService,
        private colorSchemeService: ColorSchemeService,
    ) {}

    accountForm: UntypedFormGroup
    checkedGenderOptionKey

    inititalSelectedGender

    private _filter(value: string) {
        // const filterValue = value.toLowerCase()
        // if (this.options) {
        //   return this.options.filter(option => option.toLowerCase().includes(filterValue))
        // }

        this.fuse = new Fuse(this.options, this.fuseOptions)
        this.fuseResults = this.fuse.search(value)
        return this.fuseResults
    }

    isMobileScreen = false

    zipToEdit

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.accountForm = this.formBuilder.group({
            zipCode: '',
            uniqueUserName: '',
            dob: '',
            state: '',
            country: '',
            county: '',
            city: '',
            currentPassword: [''],
            newPassword: [''],
            confirmNewPassword: [''],
            gender: [this.inititalSelectedGender],
            lat: '',
            lng: '',
            messagingPreference: 'All',
            contentPreference: 'None',
        })

        // Subscribe to changes in zipCode
        this.accountForm
            .get('zipCode')
            .valueChanges.pipe(untilDestroyed(this))
            .subscribe((value: string) => {
                // Trim value to avoid whitespace issues
                const trimmedVal = value ? value.trim() : ''

                // If user clears the field, hide the confirm box
                if (!trimmedVal) {
                    this.isEditingZipCode = false
                    return
                }

                // If the zip code is exactly 5 characters (assuming valid US ZIP)
                // then show the confirm box
                if (trimmedVal.length === 5) {
                    this.isEditingZipCode = true
                } else {
                    // If it's not 5 characters, no confirm
                    this.isEditingZipCode = false
                }
            })

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user) {
                this.user = user
                if (user.zipCode && user.zipCode !== '') {
                    this.zipToEdit = user.zipCode
                }

                this.passwordLastChanged = '6-14-2019'
                this.privacySettings = user.privacySettings
                this.communicationSettings = user.communicationSettings
                this.inititalSelectedGender = this.user.gender
                this.checkedGenderOptionKey = this.inititalSelectedGender
                    ? this.inititalSelectedGender.key
                    : null
            }
        })
    }

    justDoThisStuffOnce = true
    ngAfterViewChecked() {
        if (this.justDoThisStuffOnce) {
            this.accountForm.get('dob').patchValue(this.user.dob)
            this.accountForm.get('gender').patchValue(this.user.gender)
            this.accountForm.get('messagingPreference').patchValue(this.user.messagingPreference)
            this.accountForm.get('contentPreference').patchValue(this.user.contentPreference)
            this.justDoThisStuffOnce = false
        }
    }

    selectZipCode(zipCode) {
        this.accountForm.get('zipCode').patchValue(zipCode)
    }

    genderOptions = ['Not yet selected', 'Female', 'Male', 'Prefer not to answer']
    setSelectedGender(event) {
        this.checkedGenderOptionKey = event.value
        if (event.value == 'Custom') {
            this.accountForm.get('gender').patchValue({ key: event.value, value: null })
        } else {
            this.accountForm.get('gender').patchValue({ key: event.value, value: event.value })
        }
    }

    // upgradeAccount() {
    //   ! if uncommenting this, use new dialog wrapper
    //   this.dialog.open(UpgradeAccountComponent, {
    //     width: "40%",
    // panelClass: this.isMobileScreen ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS] : DESKTOP_MODAL_PANEL_CLASS,
    //   })
    // }

    getButtonGroupMarginTop(index) {
        if (index > 0) {
            return '-1rem'
        }
    }

    onPrivacyToggleGroupChanged(privacySettingKey, selectionKey) {
        // TODO: need to adjust this method so that if a more open provacy setting is selected, then lesser open privacy settings are automatically selected as well
        for (let privacySetting of this.privacySettings) {
            if (privacySetting.key == privacySettingKey) {
                for (let selection of privacySetting.selections) {
                    if (selection.key == selectionKey) {
                        selection.selected = !selection.selected
                    }
                }
            }
        }
    }

    onCommunicationToggleGroupChanged(communicationSettingKey, selectionKey) {
        for (let communicationSetting of this.communicationSettings) {
            if (communicationSetting.key == communicationSettingKey) {
                for (let selection of communicationSetting.selections) {
                    if (selection.key == selectionKey) {
                        selection.selected = !selection.selected
                    }
                }
            }
        }
    }

    // This will not be called in onboarding because there parent submit button
    // todo: how close are we to be able to submit Manage Account? see this comment
    submit() {
        if (this.accountForm.valid && !this.showDefaultValuesErrorMessage) {
            this.submitButtonClicked = true
            let updateUser = this.user

            // Check if zip code is empty or null
            const currentZip = this.accountForm.get('zipCode').value
            updateUser.zipCode = currentZip && currentZip.trim() !== '' ? currentZip : '0'

            updateUser.gender = this.accountForm.get('gender').value
            updateUser.dob = this.accountForm.get('dob').value
            updateUser.messagingPreference = this.accountForm.get('messagingPreference').value
            updateUser.contentPreference = this.accountForm.get('contentPreference').value
            updateUser.title = this.user.title
            updateUser.gsi2pk = 'USER'
            updateUser.gsi2sk = this.user.title

            this.backendAPIUsersService.updateUser(updateUser, null).then((result) => {
                this.snackbarService.openSuccessSnackBar('Account options updated successfully!')
                this.onCloseOrSubmissionEmitter.emit(this.accountForm)
            })
            this.dialog.closeAll()
        }
    }

    // updateUser.privacySetting = JSON.stringify(this.privacySettings)
    // updateUser.communicationSetting = this.communicationSettings
    // updateUser.messagingPreference = JSON.stringify(this.accountForm.get('messagingPreference').value)

    toggleIsEditingZipCode() {
        if (
            this.accountForm.get('zipCode').value.length == 5 ||
            this.accountForm.get('zipCode').value.length == 0
        ) {
            this.isEditingZipCode = !this.isEditingZipCode
        }
    }

    openResetPassword() {
        this.blurContentEmitter.emit(true)
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Reset Password',
                component: ResetPasswordComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,

                //??? inputData needed ??? ~~~ current password needs to be sent!
                // inputData: {
                //   img: this.selectedImage,
                //   isProfileImg: this.isProfileImg
                // }
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.blurContentEmitter.emit(false)
                if (data) {
                    //  TODO: do stuff here
                }
            })
    }

    setAreasOfInterest(event) {
        this.areasOfInterest = event

        this.accountForm.get('zipCode').setValue(event.primaryAOI.zipCode)
        this.accountForm.get('city').setValue(event.primaryAOI.city)
        this.accountForm.get('country').setValue(event.primaryAOI.country)
        this.accountForm.get('county').setValue(event.primaryAOI.county)
        this.accountForm.get('lat').setValue(event.primaryAOI.lat)
        this.accountForm.get('lng').setValue(event.primaryAOI.lng)
        this.accountForm.get('state').setValue(event.primaryAOI.state)

        // TODO: need to send this to database as soon as parent component "next" button is clicked!!!
        // TODO: also need to account for submit button (doesnt make sense in onboarding scenario...)

        this.onCloseOrSubmissionEmitter.emit(this.accountForm)
    }

    toggleSound(event) {
        this.soundEffectsService.updateSoundIsEnabled(event.checked)
    }

    toggleDarkMode(event) {
        alert('still wonky')
        // this.colorSchemeService.update(event.checked ? 'dark' : 'light')
    }
}
