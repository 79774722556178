<div class="parent-container">
    <div class="row">
        <form [formGroup]="editorForm">

            <div class="col-12">
                <mat-form-field class="gh-form-field">
                    <input matInput type="text" placeholder="* Title" formControlName="title">
                </mat-form-field>
            </div>
            <div class="col-12 mb-5">
                <app-gh-file-uploader (selectedImageChanged)="setSelectedImage($event)" #fileUploader [_isProfileImg]="false" [_sourceOfInput]="'app-create-link'">
                </app-gh-file-uploader>
            </div>
            <div class="col-12">
                <mat-form-field class="gh-form-field">
                    <input matInput type="text" placeholder="* Intro" formControlName="intro">
                </mat-form-field>
            </div>

            <div class="col-12 mb-5">
                <editor apiKey="3gji6f46ozf9p0mnr5tjx6ci1vt5457ku6so2ux0chbvsw38"
                [init]="{ plugins: 'lists link image table code help wordcount'}"
                outputFormat="html"
                (onSelectionChange)="handleEvent2($event)"
                [(ngModel)]="tinyText"
                formControlName="editor">
                </editor>
            </div>

        </form>
    </div>
</div>