import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { uploadImageFile } from 'src/app/backend-api-services/s3-file-uploader'
import { ImageMultiSizeService } from 'src/app/dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import {
    iCreateLinkItemsInput,
    iImageOrVideoGalleryInputItem,
} from '../../../../../../../../../../common/interfaces/ContentInterfaces'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-share-modal[_sourceOfInput]',
    templateUrl: './share-modal.component.html',
    styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {
    @ViewChild('shareReviewModalVC', { static: false }) shareReviewModalVC
    @ViewChild('shareArticleModalVC', { static: false }) shareArticleModalVC

    @Input() _sourceOfInput: string
    @Input() set _inputData(inputData) {
        // Default selection is 'image'
        // If needed, handle inputData to set initial selection
        this.selectedType = 'image'
    }

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submitClicked = true
            this.submit()
        }
    }

    user: any
    selectedType: string = 'image' // Default is 'image'
    submitClicked = false

    // Form groups
    imageForm: UntypedFormGroup
    videoForm: UntypedFormGroup

    // Image Data
    selectedImage: File
    imageForPreviewReview = ''
    imageInfo: [iImageOrVideoGalleryInputItem] = [
        {
            imgPath: '',
            title: '',
            gameTitle: null,
            subtitle: '',
            published: new Date().toString(),
            likes: null,
            tags: undefined,
            contentByGameByLikesPK: undefined,
            contentByGroupByLikesPK: undefined,
            contentByEventByLikesPK: undefined,
            contentByHavenByLikesPK: undefined,
            contentByUserByLikesPK: undefined,
            byLikesSK: undefined,
            pk: undefined,
            sk: undefined,
        },
    ]

    // Video Data
    selectedVideoType = 'OTHER'
    videoInfo: [iImageOrVideoGalleryInputItem] = [
        {
            videoPath: '',
            title: '',
            gameTitle: null,
            subtitle: '',
            published: new Date().toString(),
            likes: null,
            tags: undefined,
            contentByGameByLikesPK: undefined,
            contentByGroupByLikesPK: undefined,
            contentByEventByLikesPK: undefined,
            contentByHavenByLikesPK: undefined,
            contentByUserByLikesPK: undefined,
            byLikesSK: undefined,
            pk: undefined,
            sk: undefined,
        },
    ]

    // Link Data
    linkImageForReview
    linkTitlePreview = ''
    linkDescriptionPreview = ''
    linkLinkPreview = ''
    linkInfo: [iCreateLinkItemsInput] = [
        {
            userLevelWhoAdded: undefined,
            addedByUID: '',
            title: '',
            subtitle: '',
            link: '',
            paths: null,
        },
    ]

    // Review / Article Data
    articleData: any = {}
    reviewData: any
    reviewDataShapedForPreview: any
    reviewPreviewImage: string

    // Tagging
    problematicPlayerTags = []
    problematicGameTags = []
    problematicHavenTags = []
    problematicGroupTags = []
    problematicEventTags = []
    havenId
    groupId
    eventId

    isMobileScreen = false

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<ShareModalComponent>,
        private imageMultiSizeService: ImageMultiSizeService,
        private backendAPIUsersService: BackendAPIUsersService,
        private backendAPIContentService: BackendAPIContentService,
        private screenSizeService: ScreenSizeService,
        private timingHelperService: TimingHelperService,
    ) {}

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.imageForm = this.formBuilder.group({
            imageCaption: [''],
            imageTitle: [''],
        })

        this.videoForm = this.formBuilder.group({
            videoCaption: [''],
            videoTitle: [''],
        })

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })
    }

    onTypeChange(newType: string) {
        this.selectedType = newType
        // Reset any data if needed
    }

    onImageSelected(event) {
        this.selectedImage = event.rawImage
        this.imageForPreviewReview = event.selectedImage
        this.imageInfo[0].imgPath = this.imageForPreviewReview
    }

    onVideoSelected(event) {
        this.videoInfo[0].videoPath = event
    }

    selectVideoSubject(event) {
        this.selectedVideoType = event.value
    }

    onSecondLinkInfoChange(event) {
        this.linkTitlePreview = event.title
        this.linkDescriptionPreview = event.description
        this.linkLinkPreview = event.link
    }

    onImageSelection(event) {
        this.linkImageForReview = event
    }

    setArticleImage(event) {
        this.articleData.mainImageFiles = [event.selectedImage]
    }

    articleValueChanged(event) {
        if (event.title) this.articleData.title = event.title
        if (event.editor) this.articleData.editor = event.editor
        if (event.intro) this.articleData.intro = event.intro
        this.articleData.addedByUID = this.user.username
    }

    onReviewFormChange(event) {
        this.reviewData = {
            addedByUID: this.user.username,
            big8: [
                event.chanceRating,
                event.competitionRating,
                event.complexityRating,
                event.depthRating,
                event.durationRating,
                event.interactionRating,
                event.playersRating,
                event.thematicRating,
            ],
            editor: event.editor,
            gameToReview: event.gameToReview,
            title: event.title,
            intro: event.intro,
        }

        this.reviewDataShapedForPreview = {
            gameToReview: this.shareReviewModalVC?.shareReviewVC?.selectedGame?.title || null,
            havenToReview: this.shareReviewModalVC?.shareReviewVC?.selectedHaven?.title || null,
            title: event.title,
            rating: +event.rating,
            reviewAuthor: this.user.title,
            reviewImage: this.reviewPreviewImage,
            textOnlyVersionOfReview: event.textOnlyVersionOfReview,
        }
    }

    onReviewImageChange(event) {
        this.reviewData.reviewImage = event
        this.reviewPreviewImage = event.selectedImage
    }

    // Tag handlers
    setUserTag(event) {
        /* handle tagging user if needed */
    }
    setGameTag(event) {
        if (this.selectedType === 'image') {
            this.imageInfo[0].tags = event.gameId
            this.imageInfo[0].gameTitle = event.gameTitle
        } else if (this.selectedType === 'video') {
            this.videoInfo[0].tags = event.gameId
            this.videoInfo[0].gameTitle = event.gameTitle
        }
    }
    setHavenTag(event) {
        this.havenId = event.havenId
    }
    setGroupTag(event) {
        this.groupId = event.groupId
    }
    setEventTag(event) {
        this.eventId = event.eventId
    }

    setHasProblematicTags() {
        // If needed, handle adding a "Fix Tags" step or logic
    }

    onFixTags() {
        // If needed, show fix tags UI
    }

    async submit() {
        switch (this.selectedType) {
            case 'image':
                await this.submitImage()
                break
            case 'video':
                await this.submitVideo()
                break
            case 'article':
                this.shareArticleModalVC.submitArticle()
                break
            case 'review':
                this.shareReviewModalVC.submit()
                break
            case 'link':
                await this.submitLink()
                break
            // add advancedLogPlay or others if needed
        }

        // Refresh user data after submit
        this.timingHelperService.delay(2000).then(() => {
            this.backendAPIUsersService.initCurrentUser()
        })

        this.close()
    }

    async submitImage() {
        if (!this.selectedImage) {
            alert('No image selected.')
            return
        }
        const extension = this.selectedImage.name.split('.').pop()
        const s3ObjectUrl = await uploadImageFile(this.selectedImage, extension)
        const correctedUrl = s3ObjectUrl.replace(
            'https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/',
            'https://gh-img.s3.amazonaws.com/',
        )
        let paths = this.imageMultiSizeService.createArrayForAllSizes(
            correctedUrl.replace('.jpg', '').replace('.jpeg', ''),
        )

        let createItemsInput = {
            addedByUID: this.user.username,
            createdByTitle: this.user.title,
            createdByUID: this.user.username,
            gameId: this.imageInfo[0].tags,
            gameTitle: this.imageInfo[0].gameTitle,
            paths: [paths],
            subtitle: this.imageForm.get('imageCaption').value,
            title: this.imageForm.get('imageTitle').value,
            userLevelWhoAdded: this.user.userLevel,
            havenId: this.havenId,
            groupId: this.groupId,
            eventId: this.eventId,
        }
        this.backendAPIContentService.createImageItems(createItemsInput)
    }

    async submitVideo() {
        if (!this.videoInfo[0].videoPath) {
            alert('No video data.')
            return
        }

        let createVideoItemInput = {
            videoType: this.selectedVideoType,
            userLevel: this.user.userLevel,
            addedByUID: this.user.username,
            gameId: this.videoInfo[0].tags,
            gameTitle: this.videoInfo[0].gameTitle,
            title: this.videoForm.get('videoTitle').value,
            subtitle: this.videoForm.get('videoCaption').value,
            url: this.videoInfo[0].videoPath,
            havenId: this.havenId,
            groupId: this.groupId,
            eventId: this.eventId,
        }
        this.backendAPIContentService.createVideoItem(createVideoItemInput)
    }

    async submitLink() {
        let paths
        if (this.linkImageForReview && this.linkImageForReview.rawImage) {
            const extension = this.linkImageForReview.rawImage.name.split('.').pop()
            const s3ObjectUrl = await uploadImageFile(this.linkImageForReview.rawImage, extension)
            const correctedUrl = s3ObjectUrl.replace(
                'https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/',
                'https://gh-img.s3.amazonaws.com/',
            )
            paths = [
                this.imageMultiSizeService.createArrayForAllSizes(correctedUrl.replace('.jpg', '')),
            ]
        } else {
            // Default placeholder image if none uploaded
            paths = [
                [
                    'https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_S.jpg',
                    'https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_M.jpg',
                    'https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_L.jpg',
                    'https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_XL.jpg',
                ],
            ]
        }

        if (this.linkTitlePreview && this.linkLinkPreview) {
            this.linkInfo = [
                {
                    userLevelWhoAdded: this.user.userLevel,
                    paths: paths,
                    addedByUID: this.user.username,
                    title: this.linkTitlePreview,
                    subtitle: this.linkDescriptionPreview,
                    link: this.linkLinkPreview,
                },
            ]
            this.backendAPIContentService.createLinkItems(this.linkInfo[0])
        } else {
            alert('Please provide a title and link.')
        }
    }

    close() {
        this.dialogRef.close()
    }
}
