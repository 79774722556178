import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    Input,
    HostListener,
} from '@angular/core'
import { CsProfileGamesGamesILoveComponent } from './cs-components/games/cs-profile-games-games-i-love/cs-profile-games-games-i-love.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    DISCOVER_GAMES_SUGGESTED_NOT_LOGGED_IN,
    DISCOVER_GAMES_TRENDING_NOT_LOGGED_IN,
} from 'src/app/dashboard/constants/mock-data'
import { RADAR_CHART_PROFILE_FRIEND_GAME_LABELS } from 'src/app/dashboard/constants/radar-chart-constants'
import {
    RadarChartTypeEnums,
    RadarChartOverlayTypeEnums,
} from 'src/app/dashboard/enums/radar-chart-enums'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { BannerLocationEnums } from '../../../enums/banner-location-enums'
import { BannerSizeEnums } from '../../../enums/banner-size-enums'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { BannerAdService } from '../../../services/ads/banner-ad.service'
import { ProfileService } from '../../../services/profile/profile.service'
import { GameDetailPreviewModalComponent } from '../../games/game-detail-preview-modal/game-detail-preview-modal.component'
import { CsProfileGamesLookingToPlayComponent } from './cs-components/games/cs-profile-games-looking-to-play/cs-profile-games-looking-to-play.component'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { EditProfileGameCollectionsComponent } from './components/edit-profile-game-collections/edit-profile-game-collections.component'
import { UserGameCollectionTypeEnums } from '../../../../../../../../common/enums/CommonEnums'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { MdService } from '../../../services/master-detail/master-detail.service'
import { BackendApiHavenTrackingService } from 'src/app/backend-api-services/backend-api-haven-tracking.service'
import { ComposeMessageComponent } from 'src/app/dashboard/components/sidebar/components/messaging/components/compose-message/compose-message.component'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { TimingHelperService } from '../../../services/helpers/timing-helper.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { UsersService } from '../../../services/users/users.service'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    @ViewChild('miniRadarChart', { static: true }) miniRadarChart: any

    bannerAdInputDataSpecific = {
        bannerSizeEnum: BannerSizeEnums.banner120x240,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: [
            {
                link: '//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=davidrazidlo-20&marketplace=amazon&region=US&placement=B084Z8L6KZ&asins=B084Z8L6KZ&linkId=73f096a89d123bfe420da6b63d2bada2&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF',
            },
        ],
        isIFrame: true,
    }

    defaultMainImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'

    gamesILoveLoggedIn = 'Games I Love:'
    gamesILovedNotLoggedIn = 'Games I (might) Love'
    gamesIWantToPlayLoggedIn = 'Looking to Play:'
    gamesIWantToPlayNotLoggedIn = 'I (might) want to play:'

    userIsAdmin = false

    gamesILove: any[] = []
    lookingToPlay: any[] = []
    myCollection: any[] = []

    isCurrentUsersProfile: boolean | null = null
    isLoading = new BehaviorSubject(true)
    editButtonShows = true
    radarChartType = RadarChartTypeEnums.profile
    gamesContentComponents = [
        CsProfileGamesLookingToPlayComponent,
        CsProfileGamesGamesILoveComponent,
    ]
    isMobileScreen = false
    carouselContent: any[] = []
    myUserGameQualities: any

    loggedInUser: any

    selectedEntity = new BehaviorSubject<any>(null)
    selectedEntity$ = this.selectedEntity.asObservable()

    selectedUserIsFriend: boolean | null = null

    radarData: any
    radarOverlayType = RadarChartOverlayTypeEnums.myAttributes

    isAuthenticated = new BehaviorSubject(false)

    myFriends = new BehaviorSubject<any[] | null>(null)
    myFriends$ = this.myFriends.asObservable()

    isViewingFriend = false
    inputData: any

    @Input() set _inputData(inputData: any) {
        this.inputData = inputData
    }

    @Output() onEditProfile = new EventEmitter()

    isTabFocused = false
    isScrolling = false
    currentRoute: string | null = null

    currentUser: any
    userIsActive = false

    friendRequestData = new BehaviorSubject({
        friendReqeustOutToSelectedUser: null,
        selectedUserIsReqeustingIFriendThem: null,
    })
    friendRequestData$ = this.friendRequestData.asObservable()

    myHavensResult: any

    constructor(
        private backendAPIUsersService: BackendAPIUsersService,
        private profileService: ProfileService,
        private screenSizeService: ScreenSizeService,
        private bannerAdService: BannerAdService,
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService,
        private backendApiGamesService: BackendAPIGamesService,
        private backendApiGameTrackingService: BackendApiGameTrackingService,
        private backendApiHavenTrackingService: BackendApiHavenTrackingService,
        private backendApiContentService: BackendAPIContentService,
        private backendApiMessageService: BackendAPIMessageService,
        private changeDetectorRef: ChangeDetectorRef,
        private mdService: MdService,
        private usersService: UsersService,
        private timingHelperService: TimingHelperService,
    ) {
        this.currentRoute = this.router.url || ''

        document.addEventListener('visibilitychange', () => {
            this.isTabFocused = !document.hidden
        })

        window.addEventListener('scroll', () => {
            this.isScrolling = true
            setTimeout(() => {
                this.isScrolling = false
            }, 1000)
        })
    }

    async ngOnInit() {
        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                    this.selectedEntity.next(currentUser)
                }
            })

        this.usersService.myFriends$.pipe(untilDestroyed(this)).subscribe((myFriends) => {
            this.myFriends.next(Array.isArray(myFriends) ? myFriends : [])
        })

        if (this.inputData) {
            // If inputData is available
            const userToPassIn = this.inputData.user ? this.inputData.user : this.inputData.friend
            if (userToPassIn) {
                this.initLoggedInUser(userToPassIn)
            } else {
                this.setValuesForNonSignedInUser()
            }
            this.isAuthenticated.next(true)
        } else {
            // opened normally as the logged-in user
            this.authService.authStatusChanged
                .pipe(untilDestroyed(this))
                .subscribe(async (authenticated) => {
                    this.isAuthenticated.next(!!authenticated)
                    if (authenticated) {
                        this.backendAPIUsersService.currentUser$
                            .pipe(untilDestroyed(this))
                            .subscribe(async (currentUser) => {
                                if (currentUser) {
                                    await this.initLoggedInUser(currentUser)
                                }
                            })
                    } else {
                        // user is not signed in
                        this.setValuesForNonSignedInUser()
                    }
                })
            this.authService.checkAuth()
        }

        function getRandomInt(max) {
            return Math.floor(Math.random() * max)
        }

        this.bannerAdInputDataSpecific.specificAds = [
            {
                link: this.bannerAdService.amazonBannerAds
                    ? this.bannerAdService.amazonBannerAds[getRandomInt(4)]?.l
                    : this.bannerAdInputDataSpecific.specificAds[0].link,
            },
        ]

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = !!isMobileScreen
            })
    }

    setValuesForNonSignedInUser() {
        this.gamesILove = DISCOVER_GAMES_SUGGESTED_NOT_LOGGED_IN || []
        this.lookingToPlay = DISCOVER_GAMES_TRENDING_NOT_LOGGED_IN || []
        this.selectedEntity.next({
            profileImages: [''],
        })
    }

    async initLoggedInUser(currentUser: any) {
        this.loggedInUser = currentUser || null
        this.selectedEntity.next(currentUser || {})

        this.isCurrentUsersProfile = this.profileService.getIsCurrentUsersProfile()
        if (this.isCurrentUsersProfile && !this.inputData) {
            // looking at MY profile
            await this.initAuthenticatedSelectedEntity(currentUser)
        } else if (this.isCurrentUsersProfile && this.inputData) {
            await this.initAuthenticatedSelectedEntity(this.inputData.user || currentUser)
        } else if (!this.isCurrentUsersProfile && this.inputData) {
            await this.initAuthenticatedSelectedEntity(currentUser)
        } else {
            // looking at a FRIENDS profile
            this.isViewingFriend = true
            this.mdService.selectedItem$
                .pipe(untilDestroyed(this))
                .subscribe(async (selectedFriend: any) => {
                    if (selectedFriend && selectedFriend.username) {
                        let selectedUserFriendRequests =
                            await this.backendApiMessageService.listMyFriendRequests(
                                selectedFriend.username,
                                20,
                            )

                        let indRequests = selectedUserFriendRequests?.requests || []

                        this.friendRequestData.next({
                            selectedUserIsReqeustingIFriendThem: indRequests.some(
                                (r) =>
                                    r.sentToUID === (this.currentUser?.username || '') &&
                                    !!r.sentToUID,
                            ),
                            friendReqeustOutToSelectedUser: indRequests.some(
                                (r) =>
                                    r.createdBy === (this.currentUser?.username || '') &&
                                    !!r.createdBy,
                            ),
                        })

                        let userToSearchWith = selectedFriend.username
                        let arrayToSearch = this.myFriends.value || []
                        let simpleArrayOfSK = Array.isArray(arrayToSearch)
                            ? arrayToSearch.map((object) => object.sk)
                            : []
                        let simpleErArrayOfSK = simpleArrayOfSK.map((str) =>
                            typeof str === 'string' ? str.replace('#friendOf#', '') : '',
                        )

                        if (simpleErArrayOfSK && userToSearchWith) {
                            let isFriend = simpleErArrayOfSK.includes(userToSearchWith)
                            this.selectedUserIsFriend = isFriend
                        }

                        await this.initAuthenticatedSelectedEntity(selectedFriend)
                    }
                })
        }
        this.isLoading.next(false)
        this.changeDetectorRef.detectChanges()
    }

    viewFullRadarChart() {
        if (this.miniRadarChart && typeof this.miniRadarChart.openDialog === 'function') {
            this.miniRadarChart.openDialog()
        }
    }

    async logEmittedResults(event: any) {
        if (event === 'resetData') {
            await this.timingHelperService.delay(2000)
            if (this.currentUser) {
                await this.initAuthenticatedSelectedEntity(this.currentUser)
            }
        }
    }

    async initMyHavens(userId: string) {
        if (!userId) return
        let myHavensResult = await this.backendApiHavenTrackingService.listMyHavens(
            userId,
            49,
            null,
        )

        if (myHavensResult && Array.isArray(myHavensResult.havens)) {
            this.myHavensResult = myHavensResult.havens
            for (let haven of this.myHavensResult) {
                if (haven?.id) {
                    let mainImageFilesResult = await this.backendApiGamesService.GetMainImage(
                        haven.id,
                    )
                    if (mainImageFilesResult?.mainImageFiles) {
                        haven.mainImageFiles = mainImageFilesResult.mainImageFiles
                    }
                }
            }
        } else {
            this.myHavensResult = []
        }
    }

    async initGamesILove(userId: string) {
        if (!userId) return
        let gilResult = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
            userId,
            '#GIL#',
            50,
            null,
        )

        let tempGIL = gilResult?.games || []
        for (let game of tempGIL) {
            if (game?.id) {
                let mainImageFilesResult = await this.backendApiGamesService.GetMainImage(game.id)
                if (mainImageFilesResult?.mainImageFiles) {
                    game.mainImageFiles = mainImageFilesResult.mainImageFiles
                }
            }
        }

        this.gamesILove = tempGIL
    }

    async initLookingToPlay(userId: string) {
        if (!userId) return
        let ltpResult = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
            userId,
            '#LTP#',
            50,
            null,
        )

        let tempLTP = ltpResult?.games || []
        for (let game of tempLTP) {
            if (game?.id) {
                let mainImageFilesResult = await this.backendApiGamesService.GetMainImage(game.id)
                if (mainImageFilesResult?.mainImageFiles) {
                    game.mainImageFiles = mainImageFilesResult.mainImageFiles
                }
            }
        }

        this.lookingToPlay = tempLTP
    }

    async initAuthenticatedSelectedEntity(entity: any) {
        if (!entity?.username) {
            this.isLoading.next(false)
            this.changeDetectorRef.detectChanges()
            return
        }

        this.selectedEntity.next(entity)

        const fullDetails = await this.backendAPIUsersService.getProfileById(
            this.selectedEntity.value.username,
        )
        this.selectedEntity.value.fullDetails = fullDetails || {}

        this.myUserGameQualities = await this.backendAPIUsersService.getUserGameQualitiesById(
            this.selectedEntity.value.username,
        )

        let userGameQualitiesString = this.myUserGameQualities?.userGameQualities || '[]'
        let bob: number[] = []
        try {
            bob = JSON.parse(userGameQualitiesString)
        } catch {
            bob = [0, 0, 0, 0, 0, 0, 0, 0]
        }

        // NOTE: Fix here!
        await Promise.all([
            this.initGamesILove(this.selectedEntity.value.username),
            this.initLookingToPlay(this.selectedEntity.value.username),
            this.initMyHavens(this.selectedEntity.value.username),
        ])

        if (this.isCurrentUsersProfile) {
            this.radarData = {
                labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                attributes: bob,
            }
        } else {
            const overlayGameQualities = await this.backendAPIUsersService.getUserGameQualitiesById(
                this.currentUser?.username || '',
            )
            let overlayUserGameQualitiesString = overlayGameQualities?.userGameQualities || '[]'
            let carl: number[] = []
            try {
                carl = JSON.parse(overlayUserGameQualitiesString)
            } catch {
                carl = [0, 0, 0, 0, 0, 0, 0, 0]
            }

            this.radarData = {
                labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                attributes: bob,
                overlayAttributes: carl,
            }
        }

        this.isLoading.next(false)
        this.changeDetectorRef.detectChanges()
    }

    openDetailModal(item: any) {
        if (!item) return
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: item.title || 'Game Detail',
                component: GameDetailPreviewModalComponent,
                inputData: item,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
            },
        })
    }

    getBadgeTooltip(obtained: boolean) {
        return obtained
            ? 'You earned this by scoring 20,000 exp'
            : 'To earn this badge, you earn 1403 more exp'
    }

    getCellsToShow() {
        return this.carouselContent.length < 3 ? this.carouselContent.length : 3
    }

    contactButtonClicked() {
        let userObject = {
            title: this.selectedEntity.value?.title || '',
            username: this.selectedEntity.value?.username || '',
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Compose Message',
                component: ComposeMessageComponent,
                inputData: { user: userObject, message: undefined, coreUsers: [userObject] },
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Send Message',
                allowParentClose: true,
                hasCloseButton: true,
            },
            autoFocus: false,
        })
    }

    editGamesILove() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Choose Games I Love',
                component: EditProfileGameCollectionsComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    isMultiSelect: true,
                    userGameCollectionType: UserGameCollectionTypeEnums.gamesILove,
                    collection: this.gamesILove || [],
                },
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data: any[]) => {
                if (Array.isArray(data)) {
                    let gamesToAddToGameTrackingTable = data.filter(
                        (game) => !this.gamesILove.some((g) => g.id === game.pk),
                    )

                    if (gamesToAddToGameTrackingTable.length > 0) {
                        await this.backendApiGameTrackingService.createMultipleGameTrackingAddToGamesILove(
                            gamesToAddToGameTrackingTable,
                            this.loggedInUser?.username || '',
                            this.loggedInUser?.title || '',
                            this.loggedInUser?.mainImageFiles && this.loggedInUser.mainImageFiles[0]
                                ? this.loggedInUser.mainImageFiles[0]
                                : null,
                            this.loggedInUser?.state || '',
                            this.loggedInUser?.zipCode || '0',
                        )
                    }

                    let gamesToRemoveFromGameTrackingTable = this.gamesILove.filter(
                        (game) => !data.some((g) => g.pk === game.id),
                    )

                    if (gamesToRemoveFromGameTrackingTable.length > 0) {
                        await this.backendApiGameTrackingService.deleteMultipleGameTrackingGamesILove(
                            gamesToRemoveFromGameTrackingTable,
                            this.loggedInUser?.username || '',
                        )
                    }

                    // Refresh the data after changes
                    if (this.selectedEntity.value?.username) {
                        this.timingHelperService.delay(1000).then(async () => {
                            await this.initGamesILove(this.selectedEntity.value.username)
                            this.changeDetectorRef.detectChanges()
                        })
                    } else {
                        this.timingHelperService.delay(1000).then(async () => {
                            await this.initGamesILove(this.loggedInUser?.username || '')
                            this.changeDetectorRef.detectChanges()
                        })
                    }
                }
            })
    }

    editLookingToPlay() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Choose Games you would Love to Play',
                component: EditProfileGameCollectionsComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    isMultiSelect: true,
                    userGameCollectionType: UserGameCollectionTypeEnums.lookingToPlay,
                    collection: this.lookingToPlay || [],
                },
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data: any[]) => {
                if (Array.isArray(data)) {
                    let gamesToAddToGameTrackingTable = data.filter(
                        (game) => !this.lookingToPlay.some((g) => g.id === game.pk),
                    )

                    if (gamesToAddToGameTrackingTable.length > 0) {
                        await this.backendApiGameTrackingService.createMultipleGameTrackingAddToLookingToPlay(
                            gamesToAddToGameTrackingTable,
                            this.loggedInUser?.username || '',
                            this.loggedInUser?.state || '',
                            this.loggedInUser?.zipCode || '0',
                            this.loggedInUser?.title || '',
                            this.loggedInUser?.mainImageFiles && this.loggedInUser.mainImageFiles[0]
                                ? this.loggedInUser.mainImageFiles[0]
                                : null,
                        )
                    }

                    let gamesToRemoveFromGameTrackingTable = this.lookingToPlay.filter(
                        (game) => !data.some((g) => g.pk === game.id),
                    )
                    if (gamesToRemoveFromGameTrackingTable.length > 0) {
                        await this.backendApiGameTrackingService.deleteMultipleGameTrackingLookingToPlay(
                            gamesToRemoveFromGameTrackingTable,
                            this.loggedInUser?.username || '',
                        )
                    }

                    // Refresh the data after changes
                    if (this.selectedEntity.value?.username) {
                        this.timingHelperService.delay(1000).then(async () => {
                            await this.initLookingToPlay(this.selectedEntity.value.username)
                            this.changeDetectorRef.detectChanges()
                        })
                    } else {
                        this.timingHelperService.delay(1000).then(async () => {
                            await this.initLookingToPlay(this.loggedInUser?.username || '')
                            this.changeDetectorRef.detectChanges()
                        })
                    }
                }
            })
    }

    async onGameDetailSelected(event: any) {
        if (!event?.id) return
        let gameDetails = await this.backendApiGamesService.GetGame(event.id)
        if (!gameDetails) return

        let inputData = {
            limitDataFecthing: true,
            game: gameDetails,
            origin: 'modal',
        }

        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: gameDetails.title || 'Game Detail',
                component: MdGameDetailComponent,
                hasSubmitButton: false,
                hasCloseButton: true,
                hasCancelButton: false,
                inputData: inputData,
                allowParentClose: true,
            },
            maxWidth: '90%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }
}
