<div class="mt-2 parent-container">
    <mat-card-title class="gh-sm-title">Incoming Friend Requests</mat-card-title>
    <div class="mt-2 mb-4 px-2">
        <app-friend-requests-table
            [_dataSource]="incomingRequests"
            [_displayedColumns]="displayedColumnsIncomingFriendRequests"
            [_isIncoming]="true"
            [_user]="user"
            [_hasDeleteButtons]="hasDeleteButtons"
            [_hasConfirmationButtons]="true"
            (requestsUpdated)="onRequestsUpdated()"
        >
        </app-friend-requests-table>
    </div>

    <mat-card-title class="gh-sm-title">Outgoing Friend Requests</mat-card-title>
    <div class="mt-2 px-2">
        <app-friend-requests-table
            [_dataSource]="outgoingRequests"
            [_displayedColumns]="displayedColumnsOutgoingFriendRequests"
            [_isIncoming]="false"
            [_user]="user"
            [_hasDeleteButtons]="hasDeleteButtons"
            [_hasConfirmationButtons]="false"
            (requestsUpdated)="onRequestsUpdated()"
        >
        </app-friend-requests-table>
    </div>
</div>
