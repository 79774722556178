<div *ngIf="isMobileScreenIsKnown">
    <!-- MOBILE VIEW -->
    <div
        class="mobile-view"
        *ngIf="isMobileScreen; else desktopView"
        [ngClass]="{ isViewingSelf: isCurrentUsersProfile }"
    >
        <!-- Container for a two-column layout (left: avatar+name, right: bio) -->
        <div class="mobile-card-container">
            <!-- LEFT COLUMN: Avatar & Name -->

            <div class="left-column">
                <div class="profile-header">
                    <div
                        class="avatar"
                        (click)="
                            isCurrentUsersProfile
                                ? editProfile()
                                : viewProfileImage(selectedEntityImgFullSized)
                        "
                    >
                        <img [src]="selectedEntityImg" alt="Profile Image" />
                    </div>

                    <div class="profile-details">
                        <h4 class="profile-title">
                            {{ isAuthenticated ? selectedEntity.title : 'You?' }}
                        </h4>
                        <div class="profile-subtitle" *ngIf="!isAuthenticated">
                            Join Today For Free!
                        </div>
                    </div>
                </div>
            </div>

            <!-- RIGHT COLUMN: Bio -->
            <div class="right-column">
                <div class="bio-section" *ngIf="selectedEntityBio && selectedEntityBio.length">
                    <div *ngIf="isAuthenticated">
                        <!-- If bio is longer than 55 chars, show truncated + click to open Info modal -->
                        <div
                            class="bio-preview"
                            *ngIf="selectedEntityBio.length > 150"
                            (click)="openBioModal()"
                        >
                            {{ selectedEntityBio | slice : 0 : 150 }}
                            <span class="bio-ellipsis">...</span>
                        </div>

                        <!-- If bio is short -->
                        <div class="bio-full" *ngIf="selectedEntityBio.length <= 150">
                            {{ selectedEntityBio }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ACTION BUTTONS (mobile) -->
        <div *ngIf="!isCurrentUsersProfile" class="mobile-action-buttons">
            <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
        </div>
    </div>

    <!-- DESKTOP VIEW -->
    <ng-template #desktopView>
        <mat-card id="avatar-card" class="mat-elevation-z6">
            <div class="avatar-container row">
                <!-- Avatar -->
                <div
                    *ngIf="isCurrentUsersProfile"
                    style="z-index: 1000"
                    class="col-3 edit-profile-button"
                    (click)="editProfile()"
                >
                    <img id="profile-img" [src]="selectedEntityImg" />
                </div>

                <div
                    *ngIf="!isCurrentUsersProfile"
                    style="z-index: 1000"
                    class="col-3 edit-profile-button"
                    (click)="viewProfileImage(selectedEntityImgFullSized)"
                >
                    <img id="profile-img" [src]="selectedEntityImg" />
                </div>

                <!-- Title + Subtitle -->
                <div class="col-3 title-container">
                    <div class="title-container">
                        <mat-card-title id="title" class="gh-lg-title">
                            {{ isAuthenticated ? selectedEntity?.title : 'You?' }}
                        </mat-card-title>
                        <mat-card-subtitle
                            id="subtitle"
                            class="gh-md-subtitle"
                            *ngIf="isAuthenticated && joinDate"
                        >
                            Joined: {{ joinDate | date : 'shortDate' }}
                        </mat-card-subtitle>
                    </div>
                </div>

                <!-- Bio (desktop) -->
                <div class="col-6">
                    <div class="bio-container" *ngIf="isAuthenticated && selectedEntityBio">
                        <!-- If bio is very long, show truncated + click to open Info modal -->
                        <div
                            *ngIf="selectedEntityBio.length > 150"
                            class="bio-preview"
                            (click)="openBioModal()"
                        >
                            {{ selectedEntityBio | slice : 0 : 150 }}
                            <span class="click-more">...</span>
                        </div>

                        <!-- If bio is short -->
                        <div *ngIf="selectedEntityBio.length <= 150" class="bio-full">
                            {{ selectedEntityBio }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- DESKTOP ACTION BUTTONS -->
            <div class="row">
                <div class="col-12">
                    <div class="edit-or-contact-button-container" *ngIf="isAuthenticated; else cta">
                        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
                    </div>
                    <ng-template #cta>
                        <div class="sign-up-button-container">
                            <button
                                mat-raised-button
                                class="gh-button gh-button-primary"
                                (click)="signUp()"
                            >
                                Sign Up!
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </mat-card>
    </ng-template>

    <!-- ACTION BUTTONS TEMPLATE -->
    <ng-template #actionButtons>
        <span class="inline-span">
            <div *ngIf="!isCurrentUsersProfile && !isGroup">
                <!-- Friend/Unfriend/Requests -->
                <button
                    *ngIf="
                        !isFriend &&
                        !friendRequestData.value.friendReqeustOutToSelectedUser &&
                        !friendRequestData.value.selectedUserIsReqeustingIFriendThem
                    "
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                    (click)="createFriendRequest()"
                >
                    Send Friend Request
                </button>

                <button
                    *ngIf="isFriend"
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                    (click)="unFriend()"
                >
                    Un-Friend
                </button>

                <button
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                    (click)="cancelFriendRequest()"
                    *ngIf="friendRequestData.value.friendReqeustOutToSelectedUser"
                >
                    Undo Friend Request
                </button>

                <button
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                    (click)="acceptFriendRequest()"
                    *ngIf="friendRequestData.value.selectedUserIsReqeustingIFriendThem"
                >
                    Accept Friend Request
                </button>

                <button
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                    (click)="declineFriendRequest()"
                    *ngIf="friendRequestData.value.selectedUserIsReqeustingIFriendThem"
                >
                    Decline Friend Request
                </button>
            </div>
        </span>

        <!-- GROUP ACTIONS -->
        <span
            *ngIf="isGroup"
            class="inline-span ml-3"
            [ngStyle]="{ marginTop: isMobileScreen ? '1rem' : 0 }"
        >
            <button
                *ngIf="!isInMyGroups && !isProcessing"
                mat-raised-button
                class="m-1 with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                (click)="joinGroup()"
            >
                Join Group
            </button>

            <button
                *ngIf="!isInMyGroups && isProcessing"
                mat-raised-button
                class="m-1 with-colored-border gh-button gh-stroked-button gh-stroked-button-secondary"
                disabled
            >
                Processing...
            </button>

            <button
                *ngIf="isInMyGroups && !isProcessing"
                mat-raised-button
                class="m-1 with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                (click)="leaveGroup()"
            >
                Leave Group
            </button>

            <button
                *ngIf="isInMyGroups && isProcessing"
                mat-raised-button
                class="m-1 with-colored-border gh-button gh-stroked-button gh-stroked-button-secondary"
                disabled
            >
                Processing...
            </button>

            <button
                *ngIf="userIsGroupAdmin || isGHAdmin"
                mat-raised-button
                class="m-1 gh-button gh-button-warning"
                (click)="deleteGroup()"
            >
                Delete Group
            </button>

            <button
                *ngIf="userIsGroupAdmin || isGHAdmin"
                mat-raised-button
                class="m-1 gh-button gh-button-edit"
                (click)="editGroup()"
            >
                Edit Group
            </button>
        </span>
    </ng-template>
</div>
