<div class="gh-dialog" [ngClass]="{ blurContent: blurContent, ghDialogMobile: isMobileScreen }">
    <div class="gh-dialog-header-container">
        <mat-icon class="gh-dialog-header-top-left-icon gh-icon" mat-button *ngIf="topLeftIcon" [ngStyle]="{ color: topLeftIconColor }">{{ topLeftIcon }}
        </mat-icon>
        <mat-toolbar class="gh-dialog-header-toolbar" [ngClass]="{ dialogHasTopLeftIcon: topLeftIcon }" *ngIf="hasToolbar" hasToolbar>
            <div class="gh-dialog-header-toolbar-flex-container">
                <span class="gh-dialog-header-toolbar-title" matTooltip="{{ title && title.length > truncationValue ? title : '' }}" matTooltipPosition="above">{{ truncate(title, truncationValue) }}</span
                >
            </div>
        </mat-toolbar>
        <mat-icon
            *ngIf="hasCloseButton"
            class="gh-dialog-header-close-button gh-icon gh-icon-clickable"
            mat-button
            (click)="close()"
            >close
        </mat-icon>
    </div>

    <mat-card-content
        class="gh-dialog-content-container mt-0" 
        [ngClass]="{
            noActionButtons: !(hasSubmitButton || hasCancelButton),
            isAdmin: isAdminModal
        }"
    >
        <ng-template #viewContainer></ng-template>
    </mat-card-content>

    <div
        *ngIf="
            !hideActionButtons &&
            (hasSubmitButton || hasCancelButton || hasDeleteButton || hasLeftActionButton)
        "
    >
        <mat-divider></mat-divider>

        <div class="mx-0 mt-2 row">
            <div class="text-center col-12" *ngIf="isMobileScreen && hasInfoSnippet">
                <mat-card-title class="gh-sm-title">{{ infoSnippetText }}</mat-card-title>
            </div>
        </div>

        <div mat-dialog-actions class="gh-dialog-action-button-container">
            <button
                mat-raised-button
                id="left-action-button"
                class="gh-button gh-button-secondary"
                *ngIf="hasLeftActionButton"
                (click)="onLeftActionButtonClicked()"
            >
                {{ leftActionButtonText }}
            </button>

            <span *ngIf="!isMobileScreen && hasInfoSnippet" class="ml-3">
                <mat-card-title class="gh-sm-title pt-2">{{ infoSnippetText }}</mat-card-title>
            </span>

                <button mat-raised-button id="delete-button" class="gh-button gh-button-warning" *ngIf="hasDeleteButton" (click)="onDeleteButtonClicked()">
                {{ deleteButtonText }}
            </button>
                <button mat-raised-button id="cancel-button" class="gh-button gh-button-cancel" *ngIf="hasCancelButton" (click)="close()">
                {{ cancelButtonText }}
            </button>
                <button mat-raised-button id="submit-button" [ngClass]="{
                    ghButtonSuccess: isDeletion === false,
                    ghButtonWarning: isDeletion === true
                }" *ngIf="hasSubmitButton" (click)="submit()">
                {{ submitButtonText }}
            </button>

                <!-- <app-gh-button #submitButton id="submit-button" [ngClass]="{ ghButtonSuccess: isDeletion === false, ghButtonWarning: isDeletion === true }" *ngIf="hasSubmitButton" [_buttonColor]="GhButtonColor.primary" [_buttonText]="submitButtonText" (_onClick)="submit()"></app-gh-button> -->
            </div>
    </div>
</div>