<div class="parent-container">
    <div class="type-selection-container">
        <!-- Button group for larger screens -->
        <mat-button-toggle-group
            class="desktop-type-selector"
            [value]="selectedType"
            (change)="onTypeChange($event.value)"
            appearance="legacy"
        >
            <mat-button-toggle value="image">Image</mat-button-toggle>
            <mat-button-toggle value="video">Video</mat-button-toggle>
            <mat-button-toggle value="article">Article</mat-button-toggle>
            <mat-button-toggle value="review" *ngIf="!user?.userIsHaven">Review</mat-button-toggle>
            <mat-button-toggle value="link">Link</mat-button-toggle>
        </mat-button-toggle-group>

        <!-- Dropdown for smaller screens -->
        <mat-form-field class="mobile-type-selector" appearance="fill">
            <mat-label>Select Content Type</mat-label>
            <mat-select [value]="selectedType" (selectionChange)="onTypeChange($event.value)">
                <mat-option value="image">Image</mat-option>
                <mat-option value="video">Video</mat-option>
                <mat-option value="article">Article</mat-option>
                <mat-option value="review" *ngIf="!user?.userIsHaven">Review</mat-option>
                <mat-option value="link">Link</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Content area -->
    <div class="content-container" style="margin-top: 2rem">
        <ng-container [ngSwitch]="selectedType">
            <!-- IMAGE SECTION -->
            <div *ngSwitchCase="'image'">
                <app-share-modal-image
                    (selectedImageEvent)="onImageSelected($event)"
                    [_sourceOfInput]="'app-share-modal'"
                >
                </app-share-modal-image>

                <mat-form-field appearance="fill" class="gh-form-field">
                    <mat-label>Title</mat-label>
                    <input formControlName="imageTitle" matInput type="text" />
                </mat-form-field>
                <mat-form-field appearance="fill" class="gh-form-field">
                    <mat-label>Caption</mat-label>
                    <input formControlName="imageCaption" matInput type="text" />
                </mat-form-field>

                <!-- Tags if needed -->
                <div class="pl-4">
                    <ng-container *ngTemplateOutlet="tags"></ng-container>
                </div>
            </div>

            <!-- VIDEO SECTION -->
            <div *ngSwitchCase="'video'">
                <app-share-modal-video
                    (selectedVideoEvent)="onVideoSelected($event)"
                ></app-share-modal-video>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="fill" class="gh-form-field">
                            <mat-label>Title</mat-label>
                            <input formControlName="videoTitle" matInput type="text" />
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="fill" class="gh-form-field">
                            <mat-label>Caption</mat-label>
                            <input formControlName="videoCaption" matInput type="text" />
                        </mat-form-field>
                    </div>
                </div>

                <div
                    class="mt-4 mr-4 mb-4 ml-4 data-group data-group-no-min-height radio-button-container row"
                >
                    <mat-radio-group (change)="selectVideoSubject($event)">
                        <mat-radio-button value="HTP" class="ml-5">How to Play</mat-radio-button>
                        <mat-radio-button value="PT" class="ml-5">Play Through</mat-radio-button>
                        <mat-radio-button value="OTHER" [checked]="true" class="ml-5"
                            >Other</mat-radio-button
                        >
                    </mat-radio-group>
                </div>

                <div class="pl-4">
                    <ng-container *ngTemplateOutlet="tags"></ng-container>
                </div>
            </div>

            <!-- ARTICLE SECTION -->
            <div *ngSwitchCase="'article'">
                <app-share-modal-article
                    #shareArticleModalVC
                    (imageEmitter)="setArticleImage($event)"
                    (onArticleFormChangeEmitter)="articleValueChanged($event)"
                >
                </app-share-modal-article>
            </div>

            <!-- REVIEW SECTION -->
            <div *ngSwitchCase="'review'">
                <app-share-modal-review
                    #shareReviewModalVC
                    (onImageChangeEmitter)="onReviewImageChange($event)"
                    (onReviewFormChangeEmitter)="onReviewFormChange($event)"
                >
                </app-share-modal-review>
            </div>

            <!-- LINK SECTION -->
            <div *ngSwitchCase="'link'">
                <app-share-modal-link
                    (linkInfoChangeSecondEmitter)="onSecondLinkInfoChange($event)"
                    (imageEmitter)="onImageSelection($event)"
                    [_parentSubmitClicked]="submitClicked"
                    [_sourceOfInput]="'app-share-modal[_sourceOfInput]'"
                >
                </app-share-modal-link>
            </div>

            <!-- DEFAULT (If no selection) -->
            <div *ngSwitchDefault>
                <p>Select a type from the buttons above.</p>
            </div>
        </ng-container>

        <!-- Action buttons -->
        <div class="action-button-container" style="margin-top: 2rem">
            <button
                mat-stroked-button
                (click)="submit()"
                class="gh-stroked-button gh-stroked-button-primary"
            >
                Submit
            </button>
            <button mat-stroked-button (click)="close()" class="gh-stroked-button">Cancel</button>
        </div>
    </div>
</div>

<!-- Tags template -->
<ng-template #tags>
    <app-share-modal-tags
        (hasProblematicTags)="setHasProblematicTags()"
        (selectedUserToTagEmitter)="setUserTag($event)"
        (selectedGameToTagEmitter)="setGameTag($event)"
        (selectedHavenToTagEmitter)="setHavenTag($event)"
        (selectedGroupToTagEmitter)="setGroupTag($event)"
        (selectedEventToTagEmitter)="setEventTag($event)"
        (goToFixTagsEmitter)="onFixTags()"
        (problematicPlayerTagsEmitter)="problematicPlayerTags = $event"
        (problematicHavenTagsEmitter)="problematicHavenTags = $event"
        (problematicGameTagsEmitter)="problematicGameTags = $event"
        (problematicGroupTagsEmitter)="problematicGroupTags = $event"
    >
    </app-share-modal-tags>
</ng-template>
