import { EventEmitter, Input, Output } from '@angular/core'
import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-share-modal-link[_sourceOfInput]',
  templateUrl: './share-modal-link.component.html',
  styleUrls: ['./share-modal-link.component.scss']
})
export class ShareModalLinkComponent implements OnInit {

  @Input() _sourceOfInput: string

  submitClicked = false

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submitClicked = true
      this.submit()
    }
  }

  @Output() linkInfoChangeSecondEmitter = new EventEmitter()
  @Output() imageEmitter = new EventEmitter()
  @Output() closeEmitter = new EventEmitter()

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

  }

  submit() {
    this.closeEmitter.emit()
  }

  onLinkSubmission(event){
    this.closeEmitter.emit(event)
  }

  onImageSelected(event){
    this.imageEmitter.emit(event)
  }

  onlinkInfoChange(event){
    this.linkInfoChangeSecondEmitter.emit( event )
  }
  
}
