import { Component, Input, OnInit } from '@angular/core'
import { untilDestroyed } from '@ngneat/until-destroy'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MatDialog } from '@angular/material/dialog'
import { SidebarService } from '../../../services/sidebar/sidebar.service'
import { TimingHelperService } from '../../../services/helpers/timing-helper.service'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'

@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent implements OnInit {
    message
    @Input() set _message(message) {
        this.message = message
    }

    hasActionButton = false
    @Input() set _hasActionButton(hasActionButton: boolean) {
        this.hasActionButton = hasActionButton
    }

    isLP = false
    @Input() set _isLP(isLP: boolean) {
        this.isLP = isLP
    }
    actionButtonText = false
    @Input() set _actionButtonText(actionButtonText: boolean) {
        this.actionButtonText = actionButtonText
    }

    actionComponent = false
    @Input() set _actionComponent(actionComponent: boolean) {
        this.actionComponent = actionComponent
    }

    actionComponentTitle
    @Input() set _actionComponentTitle(actionComponentTitle: string) {
        this.actionComponentTitle = actionComponentTitle
    }

    actionComponentInputData
    @Input() set _actionComponentInputData(actionComponentInputData) {
        this.actionComponentInputData = actionComponentInputData
    }

    actionComponentSubmitText = 'Submit'
    @Input() set _actionComponentSubmitText(actionComponentSubmitText) {
        this.actionComponentSubmitText = actionComponentSubmitText
    }

    // New inputs
    hasRoutingLink = false
    @Input() set _hasRoutingLink(hasRoutingLink: boolean) {
        this.hasRoutingLink = hasRoutingLink
    }

    routingDestination: string
    @Input() set _routingDestination(routingDestination: string) {
        this.routingDestination = routingDestination
    }

    routeString
    @Input() set _routeString(routeString: string) {
        this.routeString = routeString
    }
    showMobileMenuIcon
    constructor(
        private sidebarService: SidebarService,
        private timingHelperService: TimingHelperService,
        private router: Router,
        private route: ActivatedRoute,

        private screenSizeService: ScreenSizeService,
        private dialog: MatDialog,
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.showMobileMenuIcon = !this.router.url.includes('/public/')
            }
        })
    }

    isMobileScreen = false
    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    openLP() {
        alert('openLP')
    }

    openActionComponent() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: this.actionComponentTitle,
                component: this.actionComponent,
                hasSubmitButton: true,
                submitButtonText: this.actionComponentSubmitText,
                hasCancelButton: true,
                hasCloseButton: true,
                allowParentClose: true,
                inputData: this.actionComponentInputData,
            },
            autoFocus: false,
        })
    }

    routeTo(path) {
        if (path == 'Games Tab') {
            this.sidebarService.updateSelectedItem('GAMES')
            this.router.navigate(['./../../games/games-dashboard'], { relativeTo: this.route })
            this.timingHelperService.delay(1000).then(() => {
                this.sidebarService.updateSelectedItem('GAMES')
                this.router.navigate(['../../../games/games-dashboard'], { relativeTo: this.route })
            })
        } else if (path == 'Havens Tab') {
            this.sidebarService.updateSelectedItem('HAVENS')
            this.router.navigate(['./../../havens/havens-dashboard'], {
                relativeTo: this.route,
            })
            this.timingHelperService.delay(1000).then(() => {
                this.sidebarService.updateSelectedItem('GAMES')
                this.router.navigate(['../../../havens/havens-dashboard'], {
                    relativeTo: this.route,
                })
            })
        } else if (path == 'Friends Tab') {
            this.sidebarService.updateSelectedItem('FRIENDS')
            this.router.navigate(['./../../friends/friends-dashboard'], {
                relativeTo: this.route,
            })
            this.timingHelperService.delay(1000).then(() => {
                this.sidebarService.updateSelectedItem('FRIENDS')
                this.router.navigate(['../../../friends/friends-dashboard'], {
                    relativeTo: this.route,
                })
            })
        }
    }
}
