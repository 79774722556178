import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { iImageOrVideoGalleryInputItem } from '../../../../../../../../../../common/interfaces/ContentInterfaces'
import { IMAGES_SK_TO_REMOVE } from '../../../../../../../../../../common/constants/contentConstants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { TaVideosContentService } from '../ta-videos/services/ta-videos-content.service'
import { ShareModalComponent } from 'src/app/dashboard/components/sidebar/components/user-update/components/share-modal/share-modal.component'
import { CREATE_AND_SHARE_MODAL_TITLE } from 'src/app/dashboard/constants/modal-titles'
import { CreateAndShareType } from 'src/app/dashboard/dashboard-shared/enums/create-and-share-type-enums'

@Component({
    selector: 'app-ta-images',
    templateUrl: './ta-images.component.html',
    styleUrls: ['./ta-images.component.scss'],
})
export class TaImagesComponent implements OnInit {
    @ViewChild('imageOrVideoGalleryGrid', { static: false }) imageOrVideoGalleryGrid

    items = new BehaviorSubject(null)
    items$ = this.items.asObservable()
    type = 'image'

    data
    @Input() set _data(data) {
        this.data = data
    }

    inputData
    @Input() set _inputData(inputData) {
        this.inputData = inputData
    }
    constructor(
        private backendApiContentService: BackendAPIContentService,
        private taVideosContentService: TaVideosContentService,
    ) {}

    gameId
    noDataMessage
    noDataActionComponent = ShareModalComponent
    noDataActionComponentTitle = CREATE_AND_SHARE_MODAL_TITLE
    actionComponentInputData = { initialSelection: CreateAndShareType.image }

    fetchedGameImageToPass
    async ngOnInit() {
        this.gameId = this.taVideosContentService.getGameId()
        this.noDataMessage =
            'No data... Have any pics of ' + this.taVideosContentService.getGameTitle() + ' ?'
        this.fetchBulkImagesByGameId(this.gameId)
    }

    async fetchBulkImagesByGameId(gameId) {
        let fetchedGameImages = await this.backendApiContentService.listImagesByGameID(
            this.gameId,
            30,
        )
        this.fetchedGameImageToPass = fetchedGameImages.images
        this.reshapeItems(this.fetchedGameImageToPass)
    }

    reshapeItems(items) {
        let reshapedItems = []
        for (let item of items) {
            let reshapedItem: iImageOrVideoGalleryInputItem = {
                imgPath: item.paths[0],
                title: item.title,
                gameTitle: item.gameTitle,
                subtitle: item.subtitle,
                published: item.createdAt, //! There may be a problem with AWS Datetime
                likes: +item.byLikesSK.replace(IMAGES_SK_TO_REMOVE, ''),
                addedByUID: item.addedByUID,
                id: item.id,
                gameTagged: item.byLikesPK,
                byLikesSK: item.byLikesSK,
                pk: item.pk,
                sk: item.sk,
                contentByGameByLikesPK: item.contentByGameByLikesPK,
                contentByGroupByLikesPK: item.contentByGroupByLikesPK,
                contentByEventByLikesPK: item.contentByEventByLikesPK,
                contentByHavenByLikesPK: item.contentByHavenByLikesPK,
                contentByUserByLikesPK: item.contentByUserByLikesPK,
            }
            reshapedItems.push(reshapedItem)
        }
        this.items.next(reshapedItems)
    }
}
