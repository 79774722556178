import {
    Component,
    OnInit,
    ViewChild,
    ViewContainerRef,
    Input,
    Output,
    EventEmitter,
    ComponentFactoryResolver,
    AfterContentChecked,
} from '@angular/core'
import { VerticalTabsService } from '../../services/vertical-tabs/vertical-tabs.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatSidenav } from '@angular/material/sidenav'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-vertical-tabs[_sourceOfInput]',
    templateUrl: './vertical-tabs.component.html',
    styleUrls: ['./vertical-tabs.component.scss'],
})
export class VerticalTabsComponent implements OnInit, AfterContentChecked {
    @Input() set _inputData(inputData: any) {
        this.inputData = inputData || null
        this.viewContainerSet = false

        if (this.viewContainer) {
            this.viewContainer.clear()
        }
    }

    @Input() _sourceOfInput: string | undefined

    isMobileScreen = true
    @ViewChild('sidenav') sidenav: MatSidenav | undefined

    component: any
    animateOutUp = false
    animatInUp = false

    @ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer:
        | ViewContainerRef
        | undefined

    type: any
    @Input() set _type(type: any) {
        this.type = type || null
    }

    selectedMobileContent: any

    tabs: any[] = []
    @Input() set _tabs(tabs: any[]) {
        if (Array.isArray(tabs) && tabs.length > 0) {
            this.tabs = tabs
            // By default, select the first tab
            for (let i = 0; i < this.tabs.length; i++) {
                this.tabs[i].isSelected = i === 0
            }
            this.selectedMobileContent = this.tabs[0]
        } else {
            this.tabs = []
            this.selectedMobileContent = null
        }
    }

    contentComponent: any
    @Input() set _contentComponent(contentComponent: any) {
        this.contentComponent = contentComponent || null
        if (this.viewContainer) {
            this.viewContainer.clear()
        }
        this.viewContainerSet = false
    }

    data: any
    @Input() set _data(data: any) {
        this.data = data || null
    }

    @Output() tabChanged = new EventEmitter<number>()
    @Output() shouldRefreshContentComponentEmitter = new EventEmitter<void>()

    selectedTabIndex = 0
    reason = ''

    // Flags to track if the component has been set
    viewContainerSet = false

    inputData: any

    constructor(
        private resolver: ComponentFactoryResolver,
        private verticalTabsService: VerticalTabsService,
        private screenSizeService: ScreenSizeService,
    ) {}

    ngOnInit() {
        // Listen for screen size changes
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = !!isMobileScreen
            })

        // Ensure at least one tab is selected by default
        let initalTabSelected = this.tabs.some((t) => t.isSelected)
        if (!initalTabSelected && this.tabs.length > 0) {
            this.selectTab(0)
        }

        // Listen for refresh requests from verticalTabsService
        this.verticalTabsService.shouldRefreshContentComponent$
            .pipe(untilDestroyed(this))
            .subscribe((shouldRefreshContentComponent: boolean) => {
                if (shouldRefreshContentComponent) {
                    this.shouldRefreshContentComponentEmitter.emit()
                }
            })
    }

    ngAfterContentChecked() {
        // After content is checked, try to set up the dynamic component if not done yet
        if (!this.viewContainerSet && this.viewContainer) {
            this.viewContainerSet = true

            // Only create component if contentComponent is provided
            if (this.contentComponent) {
                const factory = this.resolver.resolveComponentFactory(this.contentComponent)
                this.component = this.viewContainer.createComponent(factory)

                if (this.component) {
                    // Safely assign instance properties if they exist
                    if (this.type !== undefined) {
                        this.component.instance._type = this.type
                    }

                    // Provide a default maxHeight if needed
                    if ('_maxHeight' in this.component.instance) {
                        this.component.instance._maxHeight = '10rem'
                    }

                    // Pass inputData if available and if the component expects it
                    if (this.inputData !== undefined && '_inputData' in this.component.instance) {
                        this.component.instance._inputData = this.inputData
                    }
                }
            }
        }
    }

    close(reason: string) {
        this.reason = reason
        if (this.sidenav) {
            this.sidenav.close()
        }
    }

    selectTab(index: number) {
        if (
            !Array.isArray(this.tabs) ||
            this.tabs.length === 0 ||
            index < 0 ||
            index >= this.tabs.length
        ) {
            console.warn('selectTab: Invalid index or no tabs available')
            return
        }

        this.selectedTabIndex = index
        this.tabs[index].isSelected = true

        for (let i = 0; i < this.tabs.length; i++) {
            if (i !== index) {
                this.tabs[i].isSelected = false
            }
        }

        // Trigger animation (if any)
        this.animateOutUp = true

        // Emit tab changed event after a short delay
        this.sleep(50).then(() => {
            // Before emitting tabChanged, ensure any dependent data (like userId) is set
            // If you get GraphQL error about null userId, ensure that the parent component or logic
            // that handles tabChanged has the necessary data. This component just emits the index.
            this.tabChanged.emit(index)
        })
    }

    // If you still encounter "coerced Null value" GraphQL errors, make sure that the parent component
    // handling tabChanged has all the required inputs before making the GraphQL call.
    // This component itself doesn't call GraphQL; it just emits the index.

    async sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    getInnerHTML(index: number) {
        if (!Array.isArray(this.tabs) || index < 0 || index >= this.tabs.length) {
            console.warn('getInnerHTML: Invalid index or no tabs available')
            return ''
        }
        return this.tabs[index].content
    }

    onMobileContentSelectionChange(selectedContent: any) {
        if (!Array.isArray(this.tabs)) return
        const foundIndex = this.tabs.findIndex((tab) => tab.title === selectedContent.value.title)
        if (foundIndex !== -1) {
            // Emit tabChanged with found index
            this.tabChanged.emit(foundIndex)
        }
    }
}
