import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Subscription } from 'rxjs'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ImageCropperComponent } from 'src/app/dashboard/dashboard-shared/components/custom-inputs/image-cropper/image-cropper.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-share-modal-image[_sourceOfInput]',
    templateUrl: './share-modal-image.component.html',
    styleUrls: ['./share-modal-image.component.scss'],
})
export class ShareModalImageComponent implements OnInit {
    @Input() _sourceOfInput: string

    @Output() selectedImageEvent = new EventEmitter<string>()
    @Output() closeEmitter = new EventEmitter()

    selectedImage

    imageCropperDialog$: Subscription = null
    subscriptions: Subscription[] = [this.imageCropperDialog$]

    constructor(private dialog: MatDialog, private screenSizeService: ScreenSizeService) { }

    isMobileScreen = false

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    uploadNewImage() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Crop Image',
                component: ImageCropperComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
        })

        this.imageCropperDialog$ = dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.selectedImage = data
            }
        })
    }

    //! SelectedImage is known here, need to pass it down into share-modal-componant
    setSelectedImage(event) {
        // alert ('well it works here')
        this.selectedImage = event
        this.selectedImageEvent.emit(event)
    }
}
