import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { USA_ZIP_CODES } from 'src/assets/jsons/uszips'

@Component({
    selector: 'app-gh-simple-zipcode-selector',
    templateUrl: './gh-simple-zipcode-selector.component.html',
    styleUrls: ['./gh-simple-zipcode-selector.component.scss'],
})
export class GhSimpleZipcodeSelectorComponent implements OnInit {
    accumulatingInput = ''
    minCharacters = 2
    // Assuming USA_ZIP_CODES is number[] and we need strings
    usaZipCodes = USA_ZIP_CODES
    narrowedDownList: string[] | null = null

    isEditing = false
    zipToEdit: string

    @Input() set _zipToEdit(zipToEdit: string) {
        if (zipToEdit && zipToEdit !== '' && zipToEdit !== null) {
            this.zipToEdit = zipToEdit
            this.isEditing = true
        }
    }

    @Output() onZipSelectedEmitter = new EventEmitter<string>()

    form: UntypedFormGroup

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            firstCharacters: this.isEditing ? this.zipToEdit : '',
            zipCodeSelection: this.isEditing ? this.zipToEdit : '',
        })

        if (this.isEditing) {
            this.accumulatingInput = this.zipToEdit
            if (this.accumulatingInput.length >= this.minCharacters) {
                this.narrowedDownList = this.usaZipCodes
                    .map((zip) => zip.toString()) // Convert numbers to strings
                    .filter((zipString) => zipString.startsWith(this.accumulatingInput))
            } else {
                this.narrowedDownList = null
            }
        }
    }

    onInputChange(event: Event) {
        const input = event.target as HTMLInputElement
        const value = input ? input.value.trim() : ''

        this.accumulatingInput = value

        if (this.accumulatingInput.length >= this.minCharacters) {
            this.narrowedDownList = this.usaZipCodes
                .map((zip) => zip.toString()) // Convert to string
                .filter((zipString) => zipString.startsWith(this.accumulatingInput))
        } else {
            this.narrowedDownList = null
        }
    }

    compareFunction(o1: any, o2: any) {
        return o1 === o2
    }

    selectZipcode(event: { value: string }) {
        this.onZipSelectedEmitter.emit(event.value)
    }
}
