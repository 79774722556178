import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ReviewTypeEnums } from 'src/app/dashboard/dashboard-shared/enums/review-types-enums'

@Component({
  selector: 'app-share-modal-review',
  templateUrl: './share-modal-review.component.html',
  styleUrls: ['./share-modal-review.component.scss']
})
export class ShareModalReviewComponent implements OnInit {

  @ViewChild('shareReviewVC', { static: false }) shareReviewVC

  shouldTrigger = false
  hasRadioButton = true

  inputData = {
    reviewType: ReviewTypeEnums.game,
    hasTypeOptions: true
  }

  @Output() onReviewFormChangeEmitter = new EventEmitter()
  @Output() onImageChangeEmitter = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  submit() {
    this.shareReviewVC.submitReview()
  }

  onReviewFormChange(event) {
    this.onReviewFormChangeEmitter.emit(event)
  }

  onImageChange(event) {
    this.onImageChangeEmitter.emit(event)
  }

}
