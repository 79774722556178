import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from './shared/shared.module'
import { ClickTrackService } from './shared/services/click-track.service'
import { AuthService } from './shared/services/auth/auth.service'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { MatBadgeModule } from '@angular/material/badge'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { ArticleFullDetailComponent } from './shared/components/refactor-move-these/components/article-full-detail/article-full-detail.component'
import { FullCalendarModule } from '@fullcalendar/angular'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatChipsModule } from '@angular/material/chips'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
    declarations: [AppComponent, PageNotFoundComponent, ArticleFullDetailComponent],
    imports: [
        FullCalendarModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatIconModule,
        MatBadgeModule,
        MatDialogModule,
        MatSelectModule,
        MatButtonModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatDividerModule,
        MatInputModule,
        HammerModule,

        // ServiceWorkerModule.register('ngsw-worker.js', {
        //   enabled: environment.production,
        //   // Register the ServiceWorker as soon as the app is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // })
    ],
    providers: [
        AuthService,
        // AuthGuardService,
        // ThemeService,

        {
            provide: 'WINDOW',
            useFactory: getWindowRef,
        },
        {
            provide: 'DOCUMENT',
            useFactory: getDocumentRef,
        },
        ClickTrackService,
        {
            provide: 'LOCALSTORAGE',
            useFactory: getLocalStorage,
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: AuthInterceptor,
        //     multi: true
        // },

        // {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig}
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function getLocalStorage() {
    return typeof window !== 'undefined' ? window.localStorage : null
}

export function getWindowRef() {
    return typeof window !== 'undefined' ? window : null
}

export function getDocumentRef() {
    return typeof document !== 'undefined' ? document : null
}
