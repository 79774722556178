import { Component, OnInit, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { ComposeMessageComponent } from './components/compose-message/compose-message.component'
import { MessageDetailsComponent } from './components/message-details/message-details.component'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MessageTableType } from 'src/app/dashboard/dashboard-shared/enums/message-type-enums'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { MatSnackBar } from '@angular/material/snack-bar'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-messaging',
    templateUrl: './messaging.component.html',
    styleUrls: ['./messaging.component.scss'],
})
export class MessagingComponent implements OnInit {
    coreUsers = new BehaviorSubject(null)
    coreUsers$ = this.coreUsers.asObservable()

    user
    type = 'inbox'

    inboxMessageTableType = MessageTableType.inbox
    sentMessageTableType = MessageTableType.sent
    archivedMessageTableType = MessageTableType.archived

    recievedMessagesDataSource = new MatTableDataSource<any>()
    sentMessagesDataSource = new MatTableDataSource<any>()
    archivedMessagesDataSource = new MatTableDataSource<any>()
    friendRequestsDataSource = new MatTableDataSource<any>()
    dataSource5 = new MatTableDataSource<any>() //! is this the same as above?
    incomingInvitesDataSource = new MatTableDataSource<any>()
    outgoingInvitesDataSource = new MatTableDataSource<any>()

    options = [
        {
            name: 'Inbox',
            value: 'inbox',
        },
        {
            name: 'Sent',
            value: 'sent',
        },
        {
            name: 'Archived',
            value: 'archived',
        },
        // {
        //     name: 'Friend Requests',
        //     value: 'requests'
        // },
        // {
        //     name: 'Event Invites',
        //     value: 'invites'
        // }
    ]

    // MESSAGING
    displayedColumnsReceived = [
        'avatar',
        'sentFromTitle',
        'message',
        'createdAt',
        'delete',
        'archive',
    ]
    displayedColumnsSent = ['avatar', 'sentToTitle', 'message', 'createdAt', 'delete', 'archive']
    displayedColumnsArchived = [
        'avatar',
        'sentToTitle',
        'sentFromTitle',
        'message',
        'createdAt',
        'delete',
    ]

    // FRIEND REQUESTS
    displayedColumnsIncomingFriendRequests = [
        'avatar',
        'sentFromTitle',
        'createdAt',
        'delete',
        'confirm',
    ]
    displayedColumnsOutgoingFriendRequests = [
        'avatar',
        'sentToTitle',
        'sentFromTitle',
        'createdAt',
        'delete',
    ]

    // INVITES
    displayedColumnsIncomingEventInvites = [
        'avatar',
        'from',
        'date',
        'title',
        'haven',
        'organizer',
        'delete',
        'confirm',
    ]
    displayedColumnsOutgoingEventInvites = [
        'avatar',
        'to',
        'date',
        'title',
        'haven',
        'organizer',
        'delete',
    ]

    hasDeleteButtons = true // todo need to bring it the conditional based on wether this is the current user
    hasArchiveButtons = true // todo need to bring it the conditional based on wether this is the current user
    hasConfirmationButtons = false

    sendMessageMessage
    sendToUserId

    constructor(
        private backendApiMessageService: BackendAPIMessageService,
        private backendAPIUsersService: BackendAPIUsersService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,

        private screenSizeService: ScreenSizeService,
        private usersService: UsersService,
    ) {}

    isMobileScreen = false

    async ngOnInit(): Promise<void> {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe(async (user) => {
                this.user = user

                this.usersService.coreUsers$
                    .pipe(untilDestroyed(this))
                    .subscribe(async (coreUsersResult) => {
                        this.coreUsers.next(coreUsersResult)
                    })
            })

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })
        this.fetchRecievedMessages()
        this.fetchMyFriendRequests()

        let bob = 0
        setInterval(() => {
            bob++
            if (bob < 50) this.fetchRecievedMessages()
        }, 10000)
    }

    async fetchRecievedMessages() {
        let recievedMessagesRes = await this.backendApiMessageService.listMyReceivedMessages(
            this.user.username,
            20,
        )

        this.recievedMessagesDataSource = recievedMessagesRes.messages
    }

    async fetchMyFriendRequests() {
        let friendRequestsRes = await this.backendApiMessageService.listMyFriendRequests(
            this.user.username,
            20,
        )
        this.friendRequestsDataSource = friendRequestsRes.messages
    }

    async fetchSentMessages() {
        let sentMessagesRes = await this.backendApiMessageService.listMySentMessages(
            this.user.username,
            20,
        )
        this.sentMessagesDataSource = sentMessagesRes.messages
    }

    async fetchArchivedMessages() {
        let achivedMessagesRes = await this.backendApiMessageService.listMyArchivedMessages(
            this.user.username,
            20,
        )
        this.archivedMessagesDataSource = achivedMessagesRes.messages
    }

    async fetchRequests() {
        //! why are these both the same?
        let requestsRes = await this.backendApiMessageService.listMyFriendRequests(
            this.user.username,
            20,
        )
        this.friendRequestsDataSource = requestsRes.requests.filter(
            (item) => item.sentFromTitle != this.user.title,
        )
        this.dataSource5 = requestsRes.requests.filter(
            (item) => item.sentFromTitle == this.user.title,
        )
    }

    async fetchInvites() {
        let invitesRes = await this.backendApiMessageService.listMyInvites(this.user.username, 20)
        this.incomingInvitesDataSource = invitesRes.invites.filter(
            (item) => item.sentFromTitle != this.user.title,
        )
        this.outgoingInvitesDataSource = invitesRes.invites.filter(
            (item) => item.sentFromTitle == this.user.title,
        )
    }

    seeMessageDetails(item) {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Message Details',
                component: MessageDetailsComponent,
                inputData: item,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
                hasLeftActionButton: true,
                leftActionButtonText: 'Delete Message',
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data) {
                    this.composeMessage(data.currentUser, data.messageToReplyTo)
                }
            })
    }

    composeMessage(user, message?) {
        let userObject = {
            title: this.user.title,
            username: this.user.username,
        }

        // todo needs to take in more data when the Reply button is used

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Compose Message',
                component: ComposeMessageComponent,

                inputData: { user: userObject, message: message, coreUsers: this.coreUsers.value },

                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Send Message',
                allowParentClose: true,
                hasCloseButton: true,
            },
            autoFocus: false, // prevents modal from auto opening the user selection dropdown!!!

            // NEED TO OPEN SNACKBAR HERE
        })
        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((result: any) => {
                // Log the entire result for debugging purposes

                if (result && result.sentToTitles && result.sentToTitles.length > 0) {
                    // Construct a comma-separated list of recipient titles
                    const recipientNames = result.sentToTitles.join(', ')

                    // Log each recipient individually (optional)
                    result.sentToTitles.forEach((title: string) => {
                        console.log(`Message sent to ${title}`)
                    })

                    // Construct the snackbar message
                    const snackbarMessage = `Message sent to ${recipientNames}`

                    // Open the snackbar with the constructed message
                    this.snackBar.open(snackbarMessage, 'Close', {
                        duration: 5000, // Duration in milliseconds (e.g., 5 seconds)
                        horizontalPosition: 'center', // Optional: Positioning
                        verticalPosition: 'bottom', // Optional: Positioning
                        panelClass: ['snackbar-success'], // Optional: Custom CSS class for styling
                    })
                } else if (result && result.successMessage) {
                    // Handle cases where sentToTitles might not be available
                    console.log(result.successMessage)

                    // Optionally, display the generic success message
                    this.snackBar.open(result.successMessage, 'Close', {
                        duration: 3000,
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        panelClass: ['snackbar-success'],
                    })
                } else {
                    // Handle the case where the dialog was closed without sending messages
                    // Optionally, inform the user that no messages were sent
                    this.snackBar.open('No messages were sent.', 'Close', {
                        duration: 3000,
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        panelClass: ['snackbar-info'],
                    })
                }
            })
    }

    setType(type) {
        this.type = type.value
        if (this.type == 'inbox') {
            this.fetchRecievedMessages()
            this.hasConfirmationButtons = false
        }
        if (this.type == 'sent') {
            this.fetchSentMessages()
            this.hasConfirmationButtons = false
        }
        if (this.type == 'archived') {
            this.fetchArchivedMessages()
            this.hasConfirmationButtons = false
        }
        if (this.type == 'requests') {
            this.fetchRequests()
            this.hasConfirmationButtons = true
        }
        if (this.type == 'invites') {
            this.fetchInvites()
            this.hasConfirmationButtons = true
        }
    }
}
