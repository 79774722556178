<div class="parent-container no-wrapper-buttons" *ngIf="conventionDetails$ | async">
    <!-- Top Section: 3 Columns -->
    <div class="mx-0 mb-3 row">
        <!-- Left Column -->
        <div class="col-md-5">
            <!-- Title Section -->
            <mat-card-title class="gh-xsm-title">Title:</mat-card-title>
            <mat-card-subtitle class="subtitle-pull-up">
                {{ conventionDetails.value.title }}
            </mat-card-subtitle>

            <!-- Schedule Section -->
            <mat-card-title class="gh-xsm-title mt-2">Schedule:</mat-card-title>
            <ng-container *ngFor="let day of conventionDetails.value.extendedProps.days">
                <mat-card-subtitle class="subtitle-pull-up">
                    {{ day.date | date : 'EEE MMM d' }}, {{ formatTime(day.startTime) }} to {{ formatTime(day.endTime) }}
                </mat-card-subtitle>
            </ng-container>

            <!-- Description Section -->
            <mat-card-title class="gh-xsm-title mt-2">Description:</mat-card-title>
            <mat-card-subtitle>
                {{ conventionDetails.value.extendedProps.description }}
            </mat-card-subtitle>
        </div>

        <!-- Middle Column -->
        <div class="col-md-4">
            <!-- Organizer Section -->
            <mat-card-title class="gh-xsm-title">Organizer:</mat-card-title>
            <mat-card-subtitle class="subtitle-pull-up">
                {{ conventionDetails.value.extendedProps.eventOrganizer }}
            </mat-card-subtitle>

            <!-- Location Section -->
            <mat-card-title class="gh-xsm-title mt-2">Location:</mat-card-title>
            <mat-card-subtitle class="subtitle-pull-up">
                {{ conventionDetails.value.extendedProps.venue }}
            </mat-card-subtitle>
            <mat-card-subtitle class="subtitle-pull-up">
                {{ conventionDetails.value.extendedProps.address }}
            </mat-card-subtitle>
            <mat-card-subtitle class="subtitle-pull-up">
                {{ conventionDetails.value.extendedProps.city }}, {{ conventionDetails.value.state }}
            </mat-card-subtitle>

            <!-- Website Section -->
            <div class="mt-3 website-container">
                <mat-card-title class="gh-xsm-title">Website:</mat-card-title>
                <mat-card-subtitle class="subtitle-pull-up">
                    <a [href]="conventionDetails.value.extendedProps.website" target="_blank" rel="noopener noreferrer" class="website-link">
                        <mat-icon>language</mat-icon>
                    </a>
                </mat-card-subtitle>
            </div>
        </div>

        <!-- Right Column -->
        <div *ngIf="pathToMainImage" class="col-md-3">
            <mat-card class="image-card">
                <img mat-card-image [src]="pathToMainImage" alt="Convention Image" class="convention-image" />
            </mat-card>
        </div>
    </div>

    <!-- Mat Tab Group -->
    <mat-tab-group>
        <!-- Scheduled Plays Tab -->
        <mat-tab label="Scheduled Plays">
            <ng-template matTabContent>
                <!-- Include the scheduled plays component -->
                <app-scheduled-plays [inputData]="inputData" [user]="user" [isGHAdmin]="isGHAdmin"></app-scheduled-plays>
            </ng-template>
        </mat-tab>

        <!-- Shared Library Tab -->
        <mat-tab label="Game Library">
            <ng-template matTabContent class="convention-detail-tab-container">
                <!-- Include the shared library component -->
                <app-shared-library [inputData]="inputData" [user]="user" [isGHAdmin]="isGHAdmin"></app-shared-library>
            </ng-template>
        </mat-tab>

        <!-- Game Interest Tab -->
        <mat-tab label="Looking to Play">
            <ng-template matTabContent>
                <!-- Include the game interest component -->
                <app-game-interest [inputData]="inputData" [user]="user" [isGHAdmin]="isGHAdmin"></app-game-interest>
            </ng-template>
        </mat-tab>

        <!-- Game Interest Tab -->
        <mat-tab *ngIf="currentUserIsAdmin" label="Manage Convention">
            <ng-template matTabContent>
                <!-- Include the game interest component -->
                <app-manage-convention [inputData]="inputData" [user]="user" [isGHAdmin]="isGHAdmin"></app-manage-convention>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>