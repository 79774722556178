<!-- matTooltip="Click to view detail and/or submit your opinion"  -->

<div
    class="parent-container"
    matTooltipPosition="above"
    (click)="openDialog()"
    [hidden]="!attributesAreKnown"
>
    <!-- <mat-card-subtitle class="gh-lg-subtitle" style="text-align: center;">Personality</mat-card-subtitle> -->
    <!-- <div class="percentage" *ngIf="showMatchPercentage">{{matchPercentage | roundToPercent}}</div> -->

    <div>
        <!-- todo This should be full sized but NO scrollbar asdflkjdfsalkj -->
        <canvas
            style="width: 100%"
            baseChart
            style="pointer-events: none !important"
            [datasets]="radarChartData"
            [labels]="radarChartLabels"
            [type]="chartType"
            [options]="radarChartOptions"
        >
        </canvas>
        <mat-icon
            *ngIf="isCurrentUsersProfile"
            (click)="openDialog()"
            id="edit-icon"
            class="gh-icon gh-icon-clickable gh-icon-edit"
            >edit</mat-icon
        >
    </div>

    <!-- <mat-icon *ngIf="editButtonShows" id="edit-icon" class="gh-icon gh-icon-clickable gh-icon-edit">edit</mat-icon> -->
</div>

<div [hidden]="attributesAreKnown">
    <mat-icon
        (click)="openDialog()"
        *ngIf="editButtonShows"
        id="edit-icon"
        class="gh-icon gh-icon-clickable gh-icon-edit"
        >edit</mat-icon
    >
    <div class="parent-container unknown-attributes" (click)="openUnknownAttributesDialog()">
        <!-- <img src="../../../../../../../assets/images/coolStuff/playerRadarMock.jpg" style="width: auto; height: 100%;"> -->
        <app-no-data [_message]="'Not enough data... YET!'"></app-no-data>
    </div>
</div>
