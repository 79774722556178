import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ChartConfiguration, ChartType } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import { MatDialog } from '@angular/material/dialog'
import { GhDialogWrapperComponent } from '../../../gh-dialog-wrapper/gh-dialog-wrapper.component'
import { GhGameRadarChartComponent } from '../gh-game-radar-chart/gh-game-radar-chart.component'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import {
    RadarChartTypeEnums,
    RadarChartOverlayTypeEnums,
} from 'src/app/dashboard/enums/radar-chart-enums'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gh-game-radar-chart-mini',
    templateUrl: './gh-game-radar-chart-mini.component.html',
    styleUrls: ['./gh-game-radar-chart-mini.component.scss'],
})
export class GhGameRadarChartMiniComponent implements OnInit {
    // ------------------------------------------------------------------------
    // Template references (for the canvas, etc.)
    // ------------------------------------------------------------------------
    @ViewChild(BaseChartDirective) chart: BaseChartDirective

    // ------------------------------------------------------------------------
    // Inputs
    // ------------------------------------------------------------------------
    @Input() set _miniRadarData(miniRadarData: any) {
        /*
      The parent sets or updates this data from outside.
      We transform it into local variables to drive the chart.
    */
        this.numberOfVotes = miniRadarData?.numberOfVotes
        if (
            miniRadarData &&
            miniRadarData.attributes != null &&
            miniRadarData.attributes.length > 0
        ) {
            this.attributesAreKnown = true
            this.clearArrays()

            // Store the miniRadarData in a local property
            this.miniRadarData = miniRadarData

            // Assign attributes & overlays
            if (this.miniRadarData.attributes) {
                this.attributes = this.miniRadarData.attributes
            }
            if (this.miniRadarData.overlayAttributes) {
                // If it's a string: parse it
                if (typeof this.miniRadarData.overlayAttributes === 'string') {
                    this.miniRadarData.overlayAttributes = JSON.parse(
                        this.miniRadarData.overlayAttributes,
                    )
                }
                // Now we can safely do this
                for (const overlayAttr of this.miniRadarData.overlayAttributes) {
                    this.overlayAttributes.push(overlayAttr)
                    this.overlayAttributesX10.push(overlayAttr * 10)
                }
            }

            // Radar chart labels & data
            this.radarChartLabels = miniRadarData.labels || []
            this.radarChartData = [
                {
                    data: this.attributes,
                    backgroundColor: 'rgba(17, 100, 102, 0.6)', // gh-color-primary
                    borderColor: 'rgba(17, 100, 102, 1)',
                    pointBorderColor: 'rgba(17, 100, 102, 1)',
                    pointBackgroundColor: 'rgba(255, 255, 255, 1)',
                    borderWidth: 1,
                },
                {
                    data: this.overlayAttributes,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    borderColor: 'rgba(0, 0, 0, 1)',
                    pointBorderColor: 'rgba(0, 0, 0, 1)',
                    pointBackgroundColor: 'rgba(0, 0, 0, 1)',
                    borderWidth: 1,
                },
            ]
        } else {
            // Not enough data
            this.attributesAreKnown = false
            this.clearArrays()
        }
    }

    @Input() set _type(type: RadarChartTypeEnums) {
        this.type = type
    }

    @Input() set _selectedItem(selectedItem: any) {
        this.selectedItem = selectedItem
    }

    @Input() set _showMatchPercentage(show: boolean) {
        this.showMatchPercentage = show
    }

    @Input() set _isCurrentUsersProfile(isCurrentUsersProfile: boolean) {
        this.isCurrentUsersProfile = isCurrentUsersProfile
    }

    @Input() set _overlayType(overlayType: RadarChartOverlayTypeEnums) {
        this.overlayType = overlayType
    }

    @Input() set _editButtonShows(editButtonShows: boolean) {
        this.editButtonShows = editButtonShows
    }

    @Input() set _uniqueAttribute(uniqueAttribute: string) {
        this.uniqueAttribute = uniqueAttribute
    }

    // ------------------------------------------------------------------------
    // Outputs
    // ------------------------------------------------------------------------
    @Output() radarChartResultEmitter = new EventEmitter<any>()

    // ------------------------------------------------------------------------
    // Public properties / state
    // ------------------------------------------------------------------------
    matchPercentage: number
    chartType: ChartType = 'radar'
    radarChartData: any
    radarChartLabels: string[] = []
    radarChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                min: 0,
                max: 10,
                beginAtZero: true,
                angleLines: {
                    color: 'rgba(245, 121, 58, 0.6)',
                },
                pointLabels: {
                    font: {
                        size: 12,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    }

    miniRadarData = {
        attributes: [5, 5, 5, 5, 5, 5, 5, 5],
        labels: [
            'Complexity',
            'Depth',
            'Duration',
            'Interaction',
            'Competition',
            'Players',
            'Chance',
            'Theme',
        ],
        overlayAttributes: [],
        numberOfVotes: 0,
    }

    // Local usage
    type: RadarChartTypeEnums
    selectedItem: any
    showMatchPercentage = false
    isCurrentUsersProfile = true
    overlayType: RadarChartOverlayTypeEnums
    editButtonShows = true
    uniqueAttribute = 'id'

    // Data arrays for chart
    attributes: number[] = []
    attributesX10: number[] = []
    overlayAttributes: number[] = []
    overlayAttributesX10: number[] = []
    attributesAreKnown = false
    numberOfVotes: number

    // For checking if user has rated
    userHasRated = false
    previousUserBig8Votes: any
    filteredArray: any[] = []

    // Logged in user
    user: any
    isMobileScreen = false

    // ------------------------------------------------------------------------
    // Lifecycle
    // ------------------------------------------------------------------------
    constructor(
        private dialog: MatDialog,
        private backendAPIUsersService: BackendAPIUsersService,
        private screenSizeService: ScreenSizeService,
        private backendApiGameTrackingService: BackendApiGameTrackingService,
    ) {}

    ngOnInit() {
        // Watch screen size
        this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
            this.isMobileScreen = isMobile
        })

        // Watch current user
        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
            // Whenever we get the user, we can (re)fetch user votes if needed
            this.fetchUsersBig8Votes()
        })
    }

    // ------------------------------------------------------------------------
    // Methods: Opening dialogs
    // ------------------------------------------------------------------------
    openDialog() {
        const inputData = {
            radarChartType: this.type,
            attributes: this.attributes,
            overlayAttributes: this.overlayAttributes,
            labels: this.miniRadarData.labels,
            numberOfVotes: this.miniRadarData.numberOfVotes,
            isCurrentUsersProfile: this.isCurrentUsersProfile,
            overlayType: this.overlayType,
            selectedItem: this.selectedItem,
            uniqueAttribute: this.uniqueAttribute,
            isEditing: false, // Just viewing in this scenario
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            width: '95%',
            height: '95%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: `${this.selectedItem?.title ?? 'Item'}'s Attributes`,
                component: GhGameRadarChartComponent,
                inputData: inputData,
                hasSubmitButton: this.isCurrentUsersProfile,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            // If the child signals to refresh
            if (result === 'resetData') {
                // Re-fetch everything
                this.refreshMiniRadarData()
            }
            // Optionally emit an event to an even higher parent, if needed
            this.radarChartResultEmitter.emit(result)
        })
    }

    castVoteForBig8() {
        const inputData = {
            isEditing: false,
            isCurrentUsersProfile: this.isCurrentUsersProfile,
            radarChartType: this.type,
            attributes: [5, 5, 5, 5, 5, 5, 5, 5], // default
            selectedItem: this.selectedItem,
            overlayAttributes: undefined,
            labels: this.miniRadarData.labels,
            overlayType: this.overlayType,
            uniqueAttribute: this.uniqueAttribute,
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            width: '95%',
            height: '95%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: `${this.selectedItem?.title ?? 'Item'} Attributes`,
                component: GhGameRadarChartComponent,
                inputData: inputData,
                hasSubmitButton: this.isCurrentUsersProfile,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'resetData') {
                // re-fetch data
                this.refreshMiniRadarData()
            }
            this.radarChartResultEmitter.emit(result)
        })
    }

    editVoteForBig8() {
        const inputData = {
            isEditing: true,
            radarChartType: this.type,
            // The user's previous vote is stored in `this.filteredArray[0].big8Vote`,
            // which is already an array of 8 numbers. Or you might need to parse it.
            attributes: this.filteredArray[0].big8Vote,
            isCurrentUsersProfile: this.isCurrentUsersProfile,
            selectedItem: this.selectedItem,
            overlayAttributes: undefined,
            labels: this.miniRadarData.labels,
            overlayType: this.overlayType,
            uniqueAttribute: this.uniqueAttribute,
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            width: '95%',
            height: '95%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: `${this.selectedItem?.title ?? 'Item'} Attributes`,
                component: GhGameRadarChartComponent,
                inputData: inputData,
                hasSubmitButton: this.isCurrentUsersProfile,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'resetData') {
                this.refreshMiniRadarData()
            }
            this.radarChartResultEmitter.emit(result)
        })
    }

    openUnknownAttributesDialog() {
        alert("We don't have enough data for this user yet")
    }

    // ------------------------------------------------------------------------
    // Methods: Re-fetch and refresh data
    // ------------------------------------------------------------------------
    async fetchUsersBig8Votes() {
        if (!this.user?.username || !this.selectedItem?.id) {
            return
        }
        try {
            this.previousUserBig8Votes =
                await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
                    this.user.username,
                    '#big8Vote#',
                    490,
                )

            const target = this.selectedItem.id
            const array = this.previousUserBig8Votes?.games || []
            this.filteredArray = array.filter((object) => object.id === target)
            this.userHasRated = this.filteredArray.length === 1
        } catch (error) {
            console.log('Error fetching user Big8 votes:', error)
        }
    }

    /**
     * Called when the modal (child) emits 'resetData' on close.
     * Re-fetch updated data for this specific item or any needed info.
     */
    async refreshMiniRadarData() {
        // If you have an API call that gets the updated game info:
        // For example (pseudo-code):
        // const updatedGame = await this.backendApiGamesService.getGameById(this.selectedItem.id);
        // Then you'd get updatedGame.big8, updatedGame.big8Votes, etc.

        // But if your logic is to rely on the parent's `_miniRadarData` from a service or some store,
        // re-fetch that now. Then set `_miniRadarData = updatedValue`.
        // For example:
        // const updatedMiniRadarData = await someService.fetchMiniRadarDataForGame(this.selectedItem.id);
        // this._miniRadarData = updatedMiniRadarData;

        // Also re-check if user has voted
        await this.fetchUsersBig8Votes()

        // Optionally, if you have a direct way to re-fetch the entire item from DB, do it and update:
        // ...
        // Then the @Input() setter will handle re-rendering the chart.
        // Or you can set this.miniRadarData / radarChartData directly if you prefer.

        // For demonstration only, you might do something like:
        // if (updatedGame.big8) {
        //   this._miniRadarData = {
        //     attributes: JSON.parse(updatedGame.big8),
        //     labels: this.miniRadarData.labels, // keep the same or updated as needed
        //     overlayAttributes: [], // or updated
        //     numberOfVotes: updatedGame.big8Votes,
        //   };
        // }
    }

    // ------------------------------------------------------------------------
    // Helper
    // ------------------------------------------------------------------------
    clearArrays() {
        this.attributes = []
        this.attributesX10 = []
        this.overlayAttributes = []
        this.overlayAttributesX10 = []
    }
}
