<div class="align-items-center mt-1 mb-1 p-0 row">
    <!-- Dropdown and Button Section -->
    <div class="align-items-center mt-0 mb-0 col-md-4">
        <mat-form-field appearance="fill" class="mt-3 p-0 w-100">
            <mat-select [(value)]="selectedTabIndex" (selectionChange)="onDropdownChange($event)">
                <mat-option *ngFor="let option of dropdownOptions; let idx = index" [value]="idx">
                    {{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="text-end col-md-4">
        <button
            mat-raised-button
            color="primary"
            class="create-event-btn"
            (click)="onButtonClick()"
        >
            Schedule a Game
        </button>
    </div>
    <div class="align-items-center col-md-4">
        <ng-container *ngIf="isFilterEnabled()">
            <mat-form-field appearance="standard" class="w-100">
                <mat-label>{{ getFilterLabel() }}</mat-label>
                <input
                    matInput
                    (keyup)="applyFilter($event.target.value)"
                    placeholder="Ex. Game 1"
                />
            </mat-form-field>
        </ng-container>
    </div>
</div>

<!-- Content Section -->
<div class="mx-0 mb-3 px-0 data-group row">
    <div class="w-100 col-12">
        <div class="wrapper">
            <!-- SECTION 1: Day Events -->
            <section *ngIf="dropdownOptions[selectedTabIndex]?.type === 'day'">
                <ng-container *ngIf="dropdownOptions[selectedTabIndex].data as day">
                    <div class="mobile-events-content">
                        <ng-container *ngIf="day.games.filteredData.length > 0; else noDayEvents">
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let game of day.games.filteredData">
                                    <mat-expansion-panel-header>
                                        <!-- Large screens: four columns (Game, Title, Players, Date) -->
                                        <mat-panel-title
                                            class="d-md-flex align-items-center w-100 d-none"
                                            style="display: flex"
                                        >
                                            <span style="flex: 1">
                                                {{ truncate(game.name, 30) }}
                                            </span>
                                            <span style="flex: 1; text-align: center">
                                                {{ game.eventTitle }}
                                            </span>
                                            <span style="flex: 1; text-align: center">
                                                {{ +game.currentPlayers || 0 }} /
                                                {{ game.maxPlayers }}
                                            </span>
                                            <span style="flex: 1; text-align: right">
                                                {{ game.startTime | date : 'EEE, MMM d, h:mma' }}
                                            </span>
                                        </mat-panel-title>

                                        <!-- Small screens: truncated game name, date/time only -->
                                        <mat-panel-title class="d-block d-md-none">
                                            {{ truncate(game.name, 30) }}
                                        </mat-panel-title>

                                        <mat-panel-description
                                            class="d-block d-flex justify-content-end d-md-none"
                                        >
                                            <span>{{ game.startTime | date : 'EEE, h:mma' }}</span>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div>
                                        <!-- Title only visible on small screens -->
                                        <p class="d-block d-md-none">
                                            <strong>Title:</strong>
                                            {{ game.eventTitle }}
                                        </p>
                                        <!-- Players count only visible on small screens -->
                                        <p class="d-block d-md-none">
                                            <strong>Players:</strong>
                                            {{ +game.currentPlayers || 0 }} / {{ game.maxPlayers }}
                                        </p>

                                        <!-- Organizer -->
                                        <p>
                                            <strong>Organizer:</strong>
                                            {{ game.createdByTitle }}
                                        </p>
                                        <!-- Date -->
                                        <p>
                                            <strong>Date:</strong>
                                            {{ formatDate(game.startTime) }}
                                        </p>
                                        <!-- Notes -->
                                        <p *ngIf="game.notes">
                                            <strong>Notes:</strong>
                                            {{ game.notes }}
                                        </p>
                                        <!-- Duration -->
                                        <p *ngIf="game.duration">
                                            <strong>Duration:</strong>
                                            {{ game.duration }} mins
                                        </p>
                                        <!-- Players Attending -->
                                        <p *ngIf="game.playerList?.length > 0">
                                            <strong>Players Attending:</strong>
                                            {{ game.playerList.join(', ') }}
                                        </p>
                                        <!-- Location -->
                                        <p *ngIf="game.location">
                                            <strong>Location:</strong>
                                            {{ game.location }}
                                        </p>
                                        <!-- Cost -->
                                        <p *ngIf="game.cost">
                                            <strong>Cost: $</strong>
                                            {{ game.cost }}
                                        </p>

                                        <!-- here -->
                                        <!-- here -->
                                        <!-- here -->
                                        <!-- here -->
                                        <!-- here -->
                                        <!-- here -->
                                        <!-- here -->
                                        <button
                                            mat-raised-button
                                            class="gh-button gh-button-primary"
                                            (click)="handleAttendClick(game)"
                                            [disabled]="isAttendDisabled(game)"
                                            [ngClass]="{
                                                'attend-button':
                                                    !isUserAttending(game) &&
                                                    !isUserOnWaitlist(game),
                                                'leave-button':
                                                    isUserAttending(game) || isUserOnWaitlist(game)
                                            }"
                                        >
                                            {{ getButtonLabel(game) }}
                                        </button>

                                        <!-- Edit button visible if user is admin or the event organizer -->
                                        <button
                                            mat-raised-button
                                            class="ml-3 gh-button gh-button-edit"
                                            *ngIf="isGHAdmin || game.isUserOrganizer"
                                            (click)="onEditButtonClick(game)"
                                        >
                                            Edit
                                        </button>
                                        <!-- DELETE button opens confirm dialog -->
                                        <button
                                            mat-raised-button
                                            class="ml-3 gh-button gh-button-warning"
                                            *ngIf="isGHAdmin || game.isUserOrganizer"
                                            (click)="openDeleteDialog(game)"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </ng-container>
                        <ng-template #noDayEvents>
                            <div class="no-events-message">
                                <p>No events scheduled for this day.</p>
                            </div>
                        </ng-template>
                    </div>
                </ng-container>
            </section>

            <!-- SECTION 2: My Events -->
            <section *ngIf="dropdownOptions[selectedTabIndex]?.type === 'myEvents'">
                <div class="mobile-my-events-content">
                    <ng-container
                        *ngIf="myEventsDataSource.filteredData.length > 0; else noMyEvents"
                    >
                        <mat-accordion>
                            <mat-expansion-panel
                                *ngFor="let game of myEventsDataSource.filteredData"
                            >
                                <mat-expansion-panel-header>
                                    <!-- Large screens: four columns (Game, Title, Players, Date) -->
                                    <mat-panel-title
                                        class="d-md-flex align-items-center w-100 d-none"
                                        style="display: flex"
                                    >
                                        <span style="flex: 1">
                                            {{ truncate(game.name, 30) }}
                                        </span>
                                        <span style="flex: 1; text-align: center">
                                            {{ game.eventTitle }}
                                        </span>
                                        <span style="flex: 1; text-align: center">
                                            {{ +game.currentPlayers || 0 }} / {{ game.maxPlayers }}
                                        </span>
                                        <span style="flex: 1; text-align: right">
                                            {{ game.startTime | date : 'EEE, MMM d, h:mma' }}
                                        </span>
                                    </mat-panel-title>

                                    <!-- Small screens: truncated game name, date/time only -->
                                    <mat-panel-title class="d-block d-md-none">
                                        {{ truncate(game.name, 30) }}
                                    </mat-panel-title>

                                    <mat-panel-description
                                        class="d-block d-flex justify-content-end d-md-none"
                                    >
                                        <span>{{ formatDate(game.startTime) }}</span>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div>
                                    <!-- Title only visible on small screens -->
                                    <p class="d-block d-md-none">
                                        <strong>Title:</strong>
                                        {{ game.eventTitle }}
                                    </p>
                                    <!-- Players count only visible on small screens -->
                                    <p class="d-block d-md-none">
                                        <strong>Players:</strong>
                                        {{ +game.currentPlayers || 0 }} / {{ game.maxPlayers }}
                                    </p>

                                    <!-- Organizer -->
                                    <p>
                                        <strong>Organizer:</strong>
                                        {{ game.createdByTitle }}
                                    </p>
                                    <!-- Date -->
                                    <p>
                                        <strong>Date:</strong>
                                        {{ formatDate(game.startTime) }}
                                    </p>
                                    <!-- Notes -->
                                    <p *ngIf="game.notes">
                                        <strong>Notes:</strong>
                                        {{ game.notes }}
                                    </p>
                                    <!-- Duration -->
                                    <p *ngIf="game.duration">
                                        <strong>Duration:</strong>
                                        {{ game.duration }} mins
                                    </p>
                                    <!-- Players Attending -->
                                    <p *ngIf="game.playerList?.length > 0">
                                        <strong>Players Attending:</strong>
                                        {{ game.playerList.join(', ') }}
                                    </p>
                                    <!-- Location -->
                                    <p *ngIf="game.location">
                                        <strong>Location:</strong>
                                        {{ game.location }}
                                    </p>
                                    <!-- Cost -->
                                    <p *ngIf="game.cost">
                                        <strong>Cost: $</strong>
                                        {{ game.cost }}
                                    </p>

                                    <button
                                        mat-raised-button
                                        class="gh-button gh-button-primary"
                                        (click)="handleAttendClick(game)"
                                        [disabled]="isAttendDisabled(game)"
                                        [ngClass]="{
                                            'attend-button':
                                                !isUserAttending(game) && !isUserOnWaitlist(game),
                                            'leave-button':
                                                isUserAttending(game) || isUserOnWaitlist(game)
                                        }"
                                    >
                                        {{ getButtonLabel(game) }}
                                    </button>

                                    <!-- Edit button visible if user is admin or the event organizer -->
                                    <button
                                        mat-raised-button
                                        class="ml-3 gh-button gh-button-edit"
                                        *ngIf="isGHAdmin || game.isUserOrganizer"
                                        (click)="onEditButtonClick(game)"
                                    >
                                        Edit
                                    </button>
                                    <!-- DELETE button opens confirm dialog -->
                                    <button
                                        mat-raised-button
                                        class="ml-3 gh-button gh-button-warning"
                                        *ngIf="isGHAdmin || game.isUserOrganizer"
                                        (click)="openDeleteDialog(game)"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                    <ng-template #noMyEvents>
                        <div class="no-events-message">
                            <p>You are not currently attending any events.</p>
                            <p>
                                You can join an event from the schedule or
                                <a href="#" (click)="navigateToCreateEvent($event)"
                                    >create a new event</a
                                >.
                            </p>
                        </div>
                    </ng-template>
                </div>
            </section>

            <!-- SECTION 3: All Events -->
            <section *ngIf="dropdownOptions[selectedTabIndex]?.type === 'allEvents'">
                <div class="mobile-all-events-content" style="width: 98%; margin: auto">
                    <ng-container
                        *ngIf="allEventsDataSource.filteredData.length > 0; else noAllEvents"
                    >
                        <mat-accordion>
                            <mat-expansion-panel
                                *ngFor="let game of allEventsDataSource.filteredData"
                            >
                                <mat-expansion-panel-header>
                                    <!-- Large screens: four columns (Game, Title, Players, Date) -->
                                    <mat-panel-title
                                        class="d-md-flex align-items-center w-100 d-none"
                                        style="display: flex"
                                    >
                                        <span style="flex: 1">
                                            {{ truncate(game.name, 30) }}
                                        </span>
                                        <span style="flex: 1; text-align: center">
                                            {{ game.eventTitle }}
                                        </span>
                                        <span style="flex: 1; text-align: center">
                                            {{ +game.currentPlayers || 0 }} / {{ game.maxPlayers }}
                                        </span>
                                        <span style="flex: 1; text-align: right">
                                            {{ formatDate(game.startTime) }}
                                        </span>
                                    </mat-panel-title>

                                    <!-- Small screens: truncated game name, date/time only -->
                                    <mat-panel-title class="d-block d-md-none">
                                        {{ truncate(game.name, 30) }}
                                    </mat-panel-title>

                                    <mat-panel-description
                                        class="d-block d-flex justify-content-end d-md-none"
                                    >
                                        <span>{{ game.startTime | date : 'h:mma' }}</span>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div>
                                    <!-- Title only visible on small screens -->
                                    <p class="d-block d-md-none">
                                        <strong>Title:</strong>
                                        {{ game.eventTitle }}
                                    </p>
                                    <!-- Players count only visible on small screens -->
                                    <p class="d-block d-md-none">
                                        <strong>Players:</strong>
                                        {{ +game.currentPlayers || 0 }} / {{ game.maxPlayers }}
                                    </p>
                                    <!-- Organizer -->
                                    <p>
                                        <strong>Organizer:</strong>
                                        {{ game.createdByTitle }}
                                    </p>
                                    <!-- Date -->
                                    <p>
                                        <strong>Date:</strong>
                                        {{ formatDate(game.startTime) }}
                                    </p>
                                    <!-- Notes -->
                                    <p *ngIf="game.notes">
                                        <strong>Notes:</strong>
                                        {{ game.notes }}
                                    </p>
                                    <!-- Duration -->
                                    <p *ngIf="game.duration">
                                        <strong>Duration:</strong>
                                        {{ game.duration }} mins
                                    </p>
                                    <!-- Players Attending -->
                                    <p *ngIf="game.playerList?.length > 0">
                                        <strong>Players Attending:</strong>
                                        {{ game.playerList.join(', ') }}
                                    </p>
                                    <!-- Location -->
                                    <p *ngIf="game.location">
                                        <strong>Location:</strong>
                                        {{ game.location }}
                                    </p>
                                    <!-- Cost -->
                                    <p *ngIf="game.cost">
                                        <strong>Cost: $</strong>
                                        {{ game.cost }}
                                    </p>

                                    <button
                                        mat-raised-button
                                        class="gh-button gh-button-primary"
                                        (click)="handleAttendClick(game)"
                                        [disabled]="isAttendDisabled(game)"
                                        [ngClass]="{
                                            'attend-button':
                                                !isUserAttending(game) && !isUserOnWaitlist(game),
                                            'leave-button':
                                                isUserAttending(game) || isUserOnWaitlist(game)
                                        }"
                                    >
                                        {{ getButtonLabel(game) }}
                                    </button>

                                    <!-- Edit button visible if user is admin or the event organizer -->
                                    <button
                                        mat-raised-button
                                        class="ml-3 gh-button gh-button-edit"
                                        *ngIf="isGHAdmin || game.isUserOrganizer"
                                        (click)="onEditButtonClick(game)"
                                    >
                                        Edit
                                    </button>
                                    <!-- DELETE button opens confirm dialog -->
                                    <button
                                        mat-raised-button
                                        class="ml-3 gh-button gh-button-warning"
                                        *ngIf="isGHAdmin || game.isUserOrganizer"
                                        (click)="openDeleteDialog(game)"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                    <ng-template #noAllEvents>
                        <div class="no-events-message">
                            <p>No events are currently scheduled.</p>
                        </div>
                    </ng-template>
                </div>
            </section>
        </div>
    </div>

    <!-- Notes Dialog Template -->
    <ng-template #notesDialog let-data>
        <h1 mat-dialog-title>Notes</h1>
        <div mat-dialog-content>
            {{ data }}
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close>Close</button>
        </div>
    </ng-template>

    <!-- Password Dialog Template -->
    <ng-template #passwordDialog>
        <h1 mat-dialog-title>Enter Password</h1>
        <div mat-dialog-content>
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Password</mat-label>
                <input matInput [(ngModel)]="passwordInput" type="password" />
            </mat-form-field>
            <div *ngIf="passwordError" class="error-message">
                Incorrect password. Please try again.
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button (click)="closePasswordDialog()">Cancel</button>
            <button mat-button (click)="submitPassword()">Submit</button>
        </div>
    </ng-template>

    <!-- Delete Dialog Template -->
    <ng-template #deleteDialog>
        <h1 mat-dialog-title>Confirm Deletion</h1>
        <div mat-dialog-content>Are you sure you want to delete this scheduled play?</div>
        <div mat-dialog-actions>
            <button mat-button (click)="closeDeleteDialog()" tabindex="0">Cancel</button>
            <button mat-button color="warn" (click)="confirmDelete()" tabindex="0">Delete</button>
        </div>
    </ng-template>
</div>
