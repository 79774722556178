<!-- TODO: Will need to make autocomplete-chiplists objects eventually -->
<div class="row tags-container data-group data-group-no-min-height data-group-has-header">

    <div class="col-12">
        <div class="gh-data-group-header mb-2">
            <span class="inline-span">
                <mat-card-title class="gh-sm-title">Tags:</mat-card-title>
            </span>

            <span class="inline-span inline-span-align-right" *ngIf="checkForProblematicTags()">
                <span class="inline-span">
                    <mat-card-subtitle class="unfound-items-message">Some tags are not recognized...
                    </mat-card-subtitle>
                </span>
                <span class="inline-span" matTooltip="Fix it!" matTooltipPosition="above">
                    <mat-icon class="gh-icon gh-icon-clickable gh-warning-icon" (click)="goToFixTags()">
                        build</mat-icon>
                </span>
            </span>

            <mat-divider></mat-divider>

        </div>
    </div>

    <div class="col-12 tag-inputs-outer-container">
        <div class="tag-inputs">

            <div class="row">
                <div class="col-12">
                    <app-autocomplete-chiplist *ngIf="coreGames" [_items]="coreGames" [_label]="'Game Title'"
                        [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                        [_singleInput]="true" [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                        (selectionChanged)="selectGame($event)">
                    </app-autocomplete-chiplist>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-autocomplete-chiplist *ngIf="coreHavens" [_items]="coreHavens" [_label]="'Haven Title'"
                        [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                        [_singleInput]="true" [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                        (selectionChanged)="selectHaven($event)">
                    </app-autocomplete-chiplist>
                </div>
            </div>


            <div class="row">
                <div class="col-12">
                    <app-autocomplete-chiplist *ngIf="coreGroups" [_items]="coreGroups" [_label]="'Group Title'"
                        [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                        [_singleInput]="true" [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                        (selectionChanged)="selectGroup($event)">
                    </app-autocomplete-chiplist>
                </div>
            </div>


            <div class="row">
                <div class="col-12">

                    <app-autocomplete-chiplist *ngIf="myEvents" [_items]="myEvents.value" [_label]="'Event Title'"
                        [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                        [_singleInput]="true" [_sourceOfInput]="'app-share-modal-advanced-log-play3'"
                        (selectionChanged)="selectEvent($event)">
                    </app-autocomplete-chiplist>
                </div>
            </div>

            <!-- ! TAG USERS HERE - do not user, this does NOT work with data modeling-->
            <!-- <div class="col-12 col-md-3">
                <app-autocomplete-chiplist *ngIf="(coreUsers$ | async)" [_items]="coreUsers.value"
                    [_label]="'Tag Another User'" [_allowDuplicates]="false" [_allowCustomInput]="false"
                    [_itemsAreObjects]="true" [_singleInput]="true" [_sourceOfInput]="'app-messaging'"
                    (selectionChanged)="selectUser($event)">
                </app-autocomplete-chiplist>
            </div> -->


        </div>
    </div>

</div>