import { Component, OnInit, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-friends-and-followers',
    templateUrl: './friends-and-followers.component.html',
    styleUrls: ['./friends-and-followers.component.scss'],
})
export class FriendsAndFollowersComponent implements OnInit {
    coreUsers = new BehaviorSubject(null)
    coreUsers$ = this.coreUsers.asObservable()

    user: any
    type = 'requests'

    incomingRequests: any[] = []
    outgoingRequests: any[] = []

    displayedColumnsIncomingFriendRequests = [
        'avatar',
        'sentFromTitle',
        'createdAt',
        'delete',
        'confirm',
    ]
    displayedColumnsOutgoingFriendRequests = [
        'avatar',
        'sentToTitle',
        'sentFromTitle',
        'createdAt',
        'delete',
    ]

    hasDeleteButtons = true // Based on if this is the current user's view
    hasArchiveButtons = true
    hasConfirmationButtons = false

    isMobileScreen = false

    constructor(
        private backendApiMessageService: BackendAPIMessageService,
        private backendAPIUsersService: BackendAPIUsersService,
        private dialog: MatDialog,
        private screenSizeService: ScreenSizeService,
        private usersService: UsersService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe(async (user) => {
                this.user = user

                this.usersService.coreUsers$
                    .pipe(untilDestroyed(this))
                    .subscribe(async (coreUsersResult) => {
                        this.coreUsers.next(coreUsersResult)
                    })
            })

        // Also subscribe again in case user changes
        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })

        // Fetch initial friend requests
        await this.fetchRequests()

        // Optional delayed re-fetch for demonstration
        this.executeWithDelay()
    }

    // Called when child emits 'requestsUpdated' after an accept/decline/cancel
    async onRequestsUpdated() {
        await this.fetchRequests()
    }

    executeWithDelay() {
        setTimeout(() => {
            this.fetchRequests()
        }, 4000) // e.g. 4 seconds
    }

    async fetchRequests() {
        if (!this.user?.username) {
            return
        }

        const requestsRes = await this.backendApiMessageService.listMyFriendRequests(
            this.user.username,
            20,
        )

        // Filter the raw requests into two arrays
        this.incomingRequests = requestsRes.requests.filter(
            (item) => item.createdBy !== this.user.username,
        )

        this.outgoingRequests = requestsRes.requests.filter(
            (item) => item.createdBy === this.user.username,
        )
    }
}
