import * as dayjs from 'dayjs'
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    SimpleChanges,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    DESKTOP_MODAL_PANEL_CLASS,
    DIALOG_FULL_SCREEN,
    MOBILE_MODAL_PANEL_CLASS,
} from 'src/app/app.constants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { FindEventsViewEventDetailsComponent } from 'src/app/dashboard/dashboard-shared/components/events/find-events-view-event-details/find-events-view-event-details.component'
import { ProfileAboutMeFriendsFriendDetailModalComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile-about-me/components/profile-about-me-friends/components/profile-about-me-friends-friend-detail-modal/profile-about-me-friends-friend-detail-modal.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { ViewConventionDetailsComponent } from '../view-convention-details/view-convention-details.component'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-the-feed',
    templateUrl: './the-feed.component.html',
    styleUrls: ['./the-feed.component.scss'],
})
export class TheFeedComponent implements OnInit {
    @ViewChild('notificationList') notificationListElement: ElementRef

    notifications: any[] = []
    isMobileScreen = false
    user: any
    buttons: boolean
    submitActionDataText: string

    @Input() set _inputData(inputData: any[]) {
        if (Array.isArray(inputData)) {
            // Sort by CreatedAt descending

            inputData.sort((a, b) => {
                const dateA = dayjs(a.createdAt)
                const dateB = dayjs(b.createdAt)
                return dateB.valueOf() - dateA.valueOf()
            })
        }
        this.notifications = inputData
        this.notifyCountChange()
    }

    @Output() notificationsCountChanged = new EventEmitter<number>()

    constructor(
        private backendApiContentService: BackendAPIContentService,
        private backendApiGamesService: BackendAPIGamesService,
        private screenSizeService: ScreenSizeService,
        private backendAPIUsersService: BackendAPIUsersService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user) {
                this.user = user
            }
        })

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    ngAfterViewInit() {
        this.scrollToTop()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['notifications'] && !changes['notifications'].firstChange) {
            setTimeout(() => this.scrollToTop(), 0)
        }
    }

    scrollToTop() {
        if (this.notificationListElement) {
            this.notificationListElement.nativeElement.scrollTop = 0
        }
    }

    seeNotificationDetails(notification: any) {
        // Implement if needed
    }

    async seeEventDetails(element: any) {
        this.buttons = !this.user?.userIsHaven
        element.ulid = element.eventId

        let submitButtonText = element.isInMyEvent
            ? 'Remove From My Schedule'
            : 'Add To My Schedule'
        if (!element.isInMyEvent) {
            element.isInMyEvent = false
        }

        let attendees = await this.backendApiContentService.listContentItemsByPkSk(
            element.eventId,
            'e',
            100,
        )

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: element.eventTitle,
                component: FindEventsViewEventDetailsComponent,
                hasCancelButton: false,
                hasCloseButton: true,
                hasSubmitButton: false,
                submitButtonText: submitButtonText,
                inputData: {
                    attendees: attendees,
                    eventDetails: element,
                    isInMyEvent: element.isInMyEvent,
                    shouldHaveInviteButton: true,
                },
                isEditing: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (data) {
                    if (data == 'new event' || data == 'add') {
                        element.havenId = this.user.username
                        element.prefix = 'e-new-' + this.user.username
                        await this.backendApiContentService.createCalendarItems(
                            element,
                            element.extendedProps?.mainImageFiles,
                        )
                    } else {
                        let input = {
                            pk: element.pk,
                            sk: 'e-new-' + this.user.username,
                        }
                        await this.backendApiContentService.deleteContentItemByPkSk(input)
                    }
                }

                // Optionally, refresh calendar events
                await this.backendApiContentService.listCalendarEventsById(this.user.username, 249)
            })
    }

    async seeUserDetails(item: any) {
        let userForDetails = await this.backendAPIUsersService.getProfileById(item.addedByUID)
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileAboutMeFriendsFriendDetailModalComponent,
                inputData: { user: userForDetails },
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
            },
        })
    }

    async seeGameDetails(loggedPlayDetails: any) {
        let gameDetails = await this.backendApiGamesService.GetGame(loggedPlayDetails.gameId)

        let inputData = {
            limitDataFecthing: true,
            game: gameDetails,
            origin: 'modal',
        }

        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: gameDetails.title,
                component: MdGameDetailComponent,
                hasSubmitButton: false,
                hasCloseButton: true,
                hasCancelButton: false,
                inputData: inputData,
                allowParentClose: true,
            },
            maxWidth: '90%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    clearAllNotifications(numberToClear: number) {
        let count = 0
        let notificationsToRemove: number[] = []

        for (let i = this.notifications.length - 1; i >= 0; i--) {
            if (count >= numberToClear) break
            let notification = this.notifications[i]
            let input = {
                pk: notification.pk,
                sk: notification.sk,
            }
            this.backendApiContentService.deleteContentItemByPkSk(input)
            notificationsToRemove.push(i)
            count++
        }

        for (let i of notificationsToRemove) {
            this.notifications.splice(i, 1)
        }

        this.notifyCountChange()
    }

    async openConventionDetails(notification: any) {
        let conventionData = await this.backendApiContentService.getContentItemByPkSk(
            notification.conventionId,
            'e',
        )

        let seeDetailsInput = {
            convention: conventionData,
            currentUser: this.user,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            backdropClass: 'gh-dialog-backdrop',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : [DESKTOP_MODAL_PANEL_CLASS, DIALOG_FULL_SCREEN],
            disableClose: true,
            data: {
                title: notification.conventionTitle + ' Details',
                convention: notification,
                component: ViewConventionDetailsComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                inputData: seeDetailsInput,
                allowParentClose: true,
                isFullScreen: true,
            },
            height: '70vh',
            width: '99%',
            autoFocus: false,
        })
    }

    private notifyCountChange() {
        this.notificationsCountChanged.emit(this.notifications.length)
    }
    dismissNotification(notification: any) {
        // Remove from UI
        const index = this.notifications.indexOf(notification)
        if (index > -1) {
            this.notifications.splice(index, 1)
            this.notifyCountChange()
        }

        // Call backend service to remove from database
        const input = {
            pk: notification.pk,
            sk: notification.sk,
        }
        this.backendApiContentService.deleteContentItemByPkSk(input).catch((error) => {
            console.error('Error deleting notification:', error)
            // Optional: handle error, maybe re-add the notification back to UI if needed
        })
    }
}
