<div class="mx-0 row">
    <!-- Existing single selection column -->
    <div class="col-6">
        <app-autocomplete-chiplist
            *ngIf="coreUsers$ | async"
            [_items]="coreUsers.value"
            [_label]="'To:'"
            [_selectedItems]="selectedUser"
            [_allowDuplicates]="false"
            [_allowCustomInput]="false"
            [_itemsAreObjects]="true"
            [_singleInput]="true"
            [_sourceOfInput]="'app-messaging'"
            (selectionChanged)="selectUser($event)"
            [_useFuzzySearch]="true"
            [_singleInput]="false"
        >
        </app-autocomplete-chiplist>
    </div>

    <!-- Multi-select friends dropdown -->
    <div class="col-6">
        <mat-form-field style="width: 100%">
            <mat-label>Select multiple friends</mat-label>
            <mat-select
                multiple
                [(ngModel)]="selectedUsersFromMultiselect"
                (selectionChange)="onMultiSelectChange($event)"
            >
                <!-- Header area for Select All / Deselect All -->
                <div mat-select-header>
                    <button mat-stroked-button (click)="toggleSelectAll()">
                        {{ allUsersSelected ? 'Deselect All' : 'Select All' }}
                    </button>
                </div>

                <mat-option *ngFor="let friend of myFriends$ | async" [value]="friend">
                    {{ friend.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="mx-0 mt-4 row">
    <div class="w-100 col-12">
        <div class="gh-textarea-container">
            <textarea
                [(ngModel)]="sendMessageMessage"
                matInput
                class="m-2 gh-bordered-textarea"
                autocomplete="off"
                cols="30"
                rows="5"
            ></textarea>
        </div>
    </div>
</div>

<div *ngIf="previousMessageExists" class="mx-0 mt-4 row">
    <div class="w-100 col-12">
        <div class="gh-textarea-container">
            <h5>Previous Message: {{ previousMessage }}</h5>
        </div>
    </div>
</div>
