import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendApiS3Service } from 'src/app/backend-api-services/backend-api-s3.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { uploadImageFile } from 'src/app/backend-api-services/s3-file-uploader'
import { iArticleData } from 'src/app/dashboard/dashboard-shared/interfaces/reviews-articles/IReviewAndArticleData'
import { ImageMultiSizeService } from 'src/app/dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { REMOVE_IMG_TAG_FROM_HTML_REGEX } from '../../../../../../../../../../../../common/constants/regex'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-share-modal-article',
  templateUrl: './share-modal-article.component.html',
  styleUrls: ['./share-modal-article.component.scss']
})
export class ShareModalArticleComponent implements OnInit {

  @Input() set _shouldTrigger(shouldTrigger: boolean) {
    this.submitArticle()
  }

  @Output() imageEmitter = new EventEmitter()
  @Output() onArticleFormChangeEmitter = new EventEmitter()

  config

  user
  editorForm: UntypedFormGroup
  editorContent: string
  newArticle: string = ''

  videoExistsInPost: boolean
  imageExistsInPost: boolean
  hasPottyWords: boolean

  selectedImage

  constructor(
    private formBuilder: UntypedFormBuilder,
    private backendAPIUsersService: BackendAPIUsersService,
    private backendApiContentService: BackendAPIContentService,
    private backendAPIS3Service: BackendApiS3Service,
    private imageMultiSizeService: ImageMultiSizeService,
    private snackbarService: SnackbarService,

    private snackbar: MatSnackBar
  ) { }


  async ngOnInit() {
    this.editorForm = this.formBuilder.group({
      title: null,
      editor: null,
      intro: null
    })

    this.onChanges()

    await this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })
  }

  tinyText
  handleEvent2($event) { // nothing needed from event
  }

  maxLength(e) {
    if (e.editor.getLength() > 200000) {
      e.editor.deleteText(199999, e.editor.getLength())
      alert('Max Length Reached')
    }
  }

  onChanges(): void { // todo we will need to unsubscribe or use the NgNeat thingy
    this.editorForm.valueChanges.subscribe(value => {
      this.onArticleFormChangeEmitter.emit(value)
    })
  }


  onSubmitText() {
    this.newArticle = this.editorForm.get('editor').value
  }

  setSelectedImage(event) {
    this.selectedImage = event
    this.imageEmitter.emit(this.selectedImage)
  }

  async submitArticle() {
    let reviewHTML = this.tinyText

    if (reviewHTML != null && reviewHTML != '') {

      let textOnlyVersionOfArticle = this.tinyText.replace(REMOVE_IMG_TAG_FROM_HTML_REGEX, "")

      let s3ResultAfter
      if (this.selectedImage) {
        const extension = this.selectedImage.rawImage.name.split('.').pop()
        const s3ObjectUrl = await uploadImageFile(this.selectedImage.rawImage, extension)
        const correctedUrl = s3ObjectUrl.replace('https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/', 'https://gh-img.s3.amazonaws.com/')

        let s3result = await this.imageMultiSizeService.createArrayForAllSizes(correctedUrl.replace('.jpg', ''))
        s3ResultAfter = s3result

      }

      let article: iArticleData = {
        addedByUID: this.user.username,
        userLevelWhoAdded: this.user.userLevel,
        title: this.editorForm.get('title').value,
        editor: this.tinyText,
        textOnlyVersionOfArticle: textOnlyVersionOfArticle,
        mainImageFiles: s3ResultAfter,
        intro: this.editorForm.get('intro').value,

        // ! need to add game tagged information here
        gameTagged: 'Game Tagged'
      }

      let createArticleResult = await this.backendApiContentService.createArticleItem(article)
      this.snackbarService.openSuccessSnackBar('Ariticle Created! Proccessing may take few moments.')

    } else {
      console.log('No Review Data')
    }
  }

}






// Removed for now
// if (reviewHTML.includes('www.youtube.com/embed')) {
//   this.videoExistsInPost = true
// } else {
//   this.videoExistsInPost = false
// }
// if (reviewHTML.includes('src="data:image')) {
//   this.imageExistsInPost = true
// } else {
//   this.imageExistsInPost = false
// }
// let pottyWords = ['shit', 'damn', 'fuck', 'ballsack', 'spanglidank']
// this.hasPottyWords = false
// for (let word of pottyWords) {
//   if (reviewHTML.includes(word)) {
//     this.hasPottyWords = true
//   }
// }
// let thingsFoundInHTML = [this.videoExistsInPost, this.imageExistsInPost, this.hasPottyWords]
// console.log('Things found (Vid, Img, Potty Words', thingsFoundInHTML)