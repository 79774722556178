import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-share-modal-video',
  templateUrl: './share-modal-video.component.html',
  styleUrls: ['./share-modal-video.component.scss']
})
export class ShareModalVideoComponent implements OnInit {

  editorForm: UntypedFormGroup
  editorContent: string
  newArticle: string = ''
  urlCaptureForm: UntypedFormGroup
  selectedVideo

  @Output() selectedVideoEvent = new EventEmitter<string>()

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.editorForm = this.formBuilder.group({
      title: null,
      editor: null,
      rating: null
    })

    this.urlCaptureForm = this.formBuilder.group({
      rawURL: ''
    })
  }


  reconstructedURL
  safeReconstructedURL
  urlToDisplay
  processedUrlToDisplay
  async processUrl(rawURL: string) {
    this.urlToDisplay = rawURL
    this.processedUrlToDisplay = this.extractVideoID(rawURL)
    this.reconstructedURL = 'https://www.youtube.com/embed/' + this.processedUrlToDisplay
    this.safeReconstructedURL = this.getSafeUrl(this.reconstructedURL)

    // this.editorForm.get('editor').patchValue(this.safeReconstructedURL)
    this.video = this.safeReconstructedURL.changingThisBreaksApplicationSecurity

    this.handleEvent('via processing ')
  }


  extractVideoID(youtubeUrl) {
    const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = youtubeUrl.match(regex);

    if (match && match[1]) {
      return match[1]; // This is the video ID
    }
    return null; // URL did not match a standard YouTube format
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  video

  handleEvent(event) {
    this.selectedVideoEvent.emit(this.video)
  }

  maxLength(e) {
    if (e.editor.getLength() > 200000) {
      e.editor.deleteText(199999, e.editor.getLength())
      alert('Max Length Reached')
    }
  }

}
