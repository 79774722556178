<div class="card-container">
    <mat-card
        *ngFor="let game of trendingData?.games; let i = index"
        class="game-card"
        (click)="openGameDetails(game)"
    >
        <div class="image-container">
            <img
                *ngIf="game.mainImageFiles[0]"
                [src]="game.mainImageFiles[0]"
                alt="Game image"
                class="game-image"
            />
            <div class="popularity-badge" [ngStyle]="{ 'background-color': getBadgeColor(i + 1) }">
                #{{ i + 1 }}
            </div>
        </div>
        <mat-card-content
            style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
            "
        >
            <mat-card-title style="font-size: 0.8rem !important">{{ game.title }}</mat-card-title>
            <mat-card-subtitle style="font-size: 0.8rem !important">{{
                game.subtitle
            }}</mat-card-subtitle>
        </mat-card-content>
    </mat-card>
</div>
