import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ulid } from 'ulid'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { AddOrEditGameComponent } from 'src/app/dashboard/dashboard-shared/components/games/add-or-edit-game/add-or-edit-game.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-create-scheduled-play',
    templateUrl: './create-scheduled-play.component.html',
    styleUrls: ['./create-scheduled-play.component.scss'],
})
export class CreateScheduledPlayComponent implements OnInit {
    form: FormGroup
    isEditing = false
    selectedGame: any = null
    availableTimes: string[] = []
    gameSelected: boolean = false
    coreGamesToSearch: any
    coreGames = new BehaviorSubject<any[]>(null)
    coreGames$ = this.coreGames.asObservable()
    possibleExpansions: any[] = []
    currentUser: any
    conventionDays: any[] = []
    isMobileScreen

    // If passed in from the parent through data, extract the hasAbilityToManage flag.
    get hasAbilityToManage(): boolean {
        return this.data?.inputData?.hasAbilityToManage === true
    }

    constructor(
        public dialogRef: MatDialogRef<CreateScheduledPlayComponent>,
        private backendAPIUsersService: BackendAPIUsersService,
        private gamesService: GamesService,
        private backendAPIContentService: BackendAPIContentService,
        private fb: FormBuilder,
        private dialog: MatDialog,

        private backendApiContentService: BackendAPIContentService,
        private snackbarService: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        // Check if we’re in edit mode
        this.isEditing = this.data.inputData?.isEditing || false
    }

    async ngOnInit() {
        // Listen for the current user
        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                }
            })

        // Listen for core games
        await this.gamesService.coreGames$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGames) => {
                if (coreGames) {
                    // Filter out expansions
                    coreGames = coreGames.filter((obj) => !obj.isExpansion)
                    this.coreGames.next(coreGames)
                    this.coreGamesToSearch = coreGames
                }
            })

        // Initialize the form, including the 'location' field
        this.form = this.fb.group({
            title: [null, Validators.required],
            gameTitle: [null, Validators.required],
            selectedDay: [null, Validators.required],
            selectedDuration: [null, Validators.required],
            selectedTime: [null, Validators.required],
            maxPlayers: [4, [Validators.required, Validators.min(1)]],
            waitlistCapacity: [0, [Validators.min(0)]],
            cost: [0, Validators.required],
            isPasswordProtected: [false],
            password: [''],
            notes: [''],
            location: [
                this.hasAbilityToManage ? '' : 'Not set',
                this.hasAbilityToManage ? Validators.required : [],
            ],
        })

        // Toggle password validators on checkbox
        this.form.get('isPasswordProtected')?.valueChanges.subscribe((isProtected) => {
            if (isProtected) {
                this.form.get('password')?.setValidators([Validators.required])
            } else {
                this.form.get('password')?.clearValidators()
            }
            this.form.get('password')?.updateValueAndValidity()
        })

        // Grab convention days, if any
        if (
            this.data.inputData &&
            this.data.inputData.parentInputData &&
            this.data.inputData.parentInputData.convention &&
            this.data.inputData.parentInputData.convention.extendedProps &&
            this.data.inputData.parentInputData.convention.extendedProps.days
        ) {
            this.conventionDays = this.data.inputData.parentInputData.convention.extendedProps.days
        } else {
            this.conventionDays = []
        }

        // If we have at least one convention day, default to the first
        if (this.conventionDays.length > 0) {
            this.form.get('selectedDay')?.setValue(this.conventionDays[0])
            this.generateTimeOptions(
                this.form.get('selectedDay')?.value,
                this.form.get('selectedDuration')?.value,
            )
        }

        // Listen for day or duration changes
        this.form.get('selectedDay')?.valueChanges.subscribe((day) => this.onDayChange(day))
        this.form
            .get('selectedDuration')
            ?.valueChanges.subscribe((duration) => this.onDurationChange(duration))

        // If editing, populate form with existing data
        if (this.isEditing) {
            const scheduledPlay = this.data.inputData.scheduledPlay
            console.log('Editing existing scheduledPlay:', scheduledPlay)

            // Match the scheduledPlay's game to one from our list
            if (this.coreGames.value) {
                this.selectedGame = this.coreGames.value.find(
                    (game) => game.title === scheduledPlay.name,
                )
                this.gameSelected = !!this.selectedGame
                this.form.get('gameTitle')?.setValue(this.selectedGame)
            }

            // Match the day from scheduledPlay
            const selectedDay = this.conventionDays.find(
                (day) =>
                    new Date(day.date).toDateString() ===
                    new Date(scheduledPlay.date).toDateString(),
            )
            this.form.get('selectedDay')?.setValue(selectedDay)
            this.onDayChange(selectedDay)

            // Set duration & time
            this.form.get('selectedDuration')?.setValue(parseInt(scheduledPlay.duration, 10))
            this.onDurationChange(parseInt(scheduledPlay.duration, 10))

            const startTime = new Date(scheduledPlay.startTime)
            const timeString = startTime.toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            this.form.get('selectedTime')?.setValue(timeString)

            // Other form fields
            this.form.get('maxPlayers')?.setValue(parseInt(scheduledPlay.maxPlayers, 10))
            this.form
                .get('waitlistCapacity')
                ?.setValue(parseInt(scheduledPlay.waitlistCapacity, 10))
            this.form.get('notes')?.setValue(scheduledPlay.notes)
            const isPasswordProtected = scheduledPlay.isPasswordProtected === 'true'
            this.form.get('isPasswordProtected')?.setValue(isPasswordProtected)
            if (isPasswordProtected) {
                this.form.get('password')?.setValue(scheduledPlay.password || '')
            }

            this.form.get('title')?.setValue(scheduledPlay.eventTitle)
            this.form.get('cost')?.setValue(scheduledPlay.cost)

            // If user can manage location, set it
            if (this.hasAbilityToManage && scheduledPlay.location) {
                this.form.get('location')?.setValue(scheduledPlay.location)
            }

            // Generate time options again
            this.generateTimeOptions(selectedDay, parseInt(scheduledPlay.duration, 10))
        }
    }

    /**
     * Generate the possible time slots for a given day & duration
     */
    generateTimeOptions(day: any, duration: number): void {
        if (!day || !duration) {
            this.availableTimes = []
            return
        }

        const parseTime = (timeStr: string): Date => {
            const [time, modifier] = timeStr.split(' ')
            let [hours, minutes] = time.split(':').map(Number)
            if (modifier === 'PM' && hours < 12) {
                hours += 12
            }
            if (modifier === 'AM' && hours === 12) {
                hours = 0
            }
            const date = new Date()
            date.setHours(hours)
            date.setMinutes(minutes)
            date.setSeconds(0)
            date.setMilliseconds(0)
            return date
        }

        const startTime = parseTime(day.startTime)
        const endTime = parseTime(day.endTime)

        this.availableTimes = []
        let currentTime = new Date(startTime.getTime())

        // Step every 15 minutes
        while (currentTime.getTime() + duration * 60000 <= endTime.getTime()) {
            const timeString = currentTime.toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            this.availableTimes.push(timeString)
            currentTime = new Date(currentTime.getTime() + 15 * 60000)
        }
    }

    createGame() {
        let gamesDialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Add a Game to the Database',
                component: AddOrEditGameComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })
        gamesDialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (gameData) => {
                //this.showAddButton = true
                this.gamesService.initCoreGameData()
                await this.gamesService.coreGames$
                    .pipe(untilDestroyed(this))
                    .subscribe(async (coreGames) => {
                        if (coreGames) {
                            // Filter out expansions
                            coreGames = coreGames.filter((obj) => !obj.isExpansion)
                            this.coreGames.next(coreGames)
                            this.coreGamesToSearch = coreGames
                        }
                    })
                if (gameData) {
                    console.log('777 gameData', gameData)
                }
            })
    }
    /**
     * On day change, reset duration & time
     */
    onDayChange(day: any): void {
        this.form.get('selectedDuration')?.setValue(null)
        this.form.get('selectedTime')?.setValue(null)
        this.availableTimes = []
    }

    /**
     * On duration change, regenerate time options
     */
    onDurationChange(duration: number): void {
        const day = this.form.get('selectedDay')?.value
        this.generateTimeOptions(day, duration)
    }

    /**
     * Called when a game is selected from a search or selection component
     */
    selectGame(game: any): void {
        // Might come as an array, depending on your implementation
        this.selectedGame = game[0]
        this.gameSelected = !!this.selectedGame
        this.form.get('gameTitle')?.setValue(this.selectedGame)

        if (this.selectedGame && this.selectedGame.expansions !== '') {
            this.possibleExpansions = JSON.parse(this.selectedGame.expansions)
        } else {
            this.possibleExpansions = []
        }
    }

    /**
     * Submit form data to create or update the scheduled play item
     */
    async submitForm() {
        if (this.selectedGame == null) {
            this.snackbarService.openErrorSnackBar('Custom game input is not allowed.')
            return
        }

        if (this.form.valid && this.gameSelected) {
            // Prepare date/time
            const selectedDay = this.form.get('selectedDay')?.value
            const date = new Date(selectedDay.date)
            const selectedTime = this.form.get('selectedTime')?.value
            const [timePart, modifier] = selectedTime.split(' ')
            let [hours, minutes] = timePart.split(':').map(Number)

            if (modifier === 'PM' && hours < 12) {
                hours += 12
            }
            if (modifier === 'AM' && hours === 12) {
                hours = 0
            }

            date.setHours(hours)
            date.setMinutes(minutes)
            date.setSeconds(0)
            date.setMilliseconds(0)
            const startTime = date.toISOString()

            // PK/SK
            let pk: string
            let sk: string
            if (this.isEditing) {
                pk = this.data.inputData.scheduledPlay.pk
                sk = this.data.inputData.scheduledPlay.sk
            } else {
                pk = this.data.inputData.parentInputData.convention.pk
                sk = 'sp_' + ulid()
            }

            // Convention ID
            let conventionId = ''
            if (this.data.conventionId) {
                conventionId = this.data.inputData.conventionId
            } else if (
                this.data.inputData &&
                this.data.inputData.parentInputData &&
                this.data.inputData.parentInputData.convention &&
                this.data.inputData.parentInputData.convention.pk
            ) {
                conventionId = this.data.inputData.parentInputData.convention.pk.toString()
            } else {
                console.error('Convention ID not found')
                return
            }

            // Build the create/update object
            const createInput: any = {
                conventionId,
                date: selectedDay.date.toString(),
                duration: this.form.get('selectedDuration')?.value.toString(),
                maxPlayers: this.form.get('maxPlayers')?.value.toString(),
                name: this.selectedGame.title,
                eventTitle: this.form.get('title')?.value,
                cost: this.form.get('cost')?.value,
                notes: this.form.get('notes')?.value,
                pk,
                sk,
                startTime,
                waitlistCapacity: this.form.get('waitlistCapacity')?.value.toString(),
                isPasswordProtected: this.form.get('isPasswordProtected')?.value.toString(),
                isApproved: true,
                location: this.hasAbilityToManage ? this.form.get('location')?.value : 'Not set',
            }

            // Preserve currentPlayers & waitlist
            if (this.isEditing) {
                createInput.currentPlayers = this.data.inputData.scheduledPlay.currentPlayers || ''
                createInput.waitlist = this.data.inputData.scheduledPlay.waitlist || ''
            } else {
                createInput.currentPlayers = ''
                createInput.waitlist = ''
            }

            // Now ensure playerList is always a JSON string array
            if (this.isEditing) {
                // existing scheduledPlay data
                const oldPlayerList = this.data.inputData.scheduledPlay.playerList || '[]'

                // If it's already a string, see if it's valid JSON
                // If not valid JSON, wrap it in an array
                // If it's an array, stringify it
                if (typeof oldPlayerList === 'string') {
                    try {
                        JSON.parse(oldPlayerList) // will throw if invalid
                        // It's valid JSON, so keep as is
                        createInput.playerList = oldPlayerList
                    } catch (err) {
                        // Not valid JSON => wrap the plain string in an array
                        createInput.playerList = JSON.stringify([oldPlayerList])
                    }
                } else if (Array.isArray(oldPlayerList)) {
                    // If the DB somehow stored an actual array, stringify it
                    createInput.playerList = JSON.stringify(oldPlayerList)
                } else {
                    // Fallback if it's null/undefined
                    createInput.playerList = '[]'
                }
            } else {
                // New creation => empty array in JSON string form
                createInput.playerList = '[]'
            }

            // Handle password if protected
            if (this.form.get('isPasswordProtected')?.value) {
                createInput.password = this.form.get('password')?.value
            } else {
                createInput.password = ''
            }

            // Preserve createdBy fields if editing
            if (this.isEditing) {
                createInput.createdByTitle = this.data.inputData.scheduledPlay.createdByTitle
                createInput.createdByUID = this.data.inputData.scheduledPlay.createdByUID
            } else {
                createInput.createdByTitle = this.currentUser.title
                createInput.createdByUID = this.currentUser.username
            }

            // Send to backend
            await this.backendAPIContentService.createScheduledPlay(createInput)
            this.dialogRef.close(createInput)

            // (Optional) Create a notification
            let NotificationInput = {
                notificationType: 'scheduledPlay',
                addedByTitle: createInput.createdByTitle,
                addedByUID: createInput.createdByUID,
                date: createInput.startTime,
                eventId: undefined,
                eventTitle: undefined,
                conventionTitle:
                    this.data.inputData.parentInputData.convention.extendedProps.eventTitle,
                conventionId: this.data.inputData.parentInputData.convention.pk,
                gameId: this.selectedGame.pk,
                gameMainImageFiles: undefined,
                gameTitle: this.selectedGame.title,
                groupId: undefined,
                groupTitle: undefined,
                havenId: undefined,
                havenTitle: undefined,
                userId: this.data.inputData.parentInputData.convention.extendedProps
                    .eventOrganizerId,
                userImage: undefined,
                eventStart: undefined,
                eventEnd: undefined,
            }
            await this.backendApiContentService.createNotificationItems(NotificationInput)
        } else {
            // If form invalid or game not selected
            this.form.markAllAsTouched()
        }
    }
}
