<div class="parent-container" [ngClass]="{ isMobileScreen: isMobileScreen }">
    <div class="mx-0 mb-3 row">
        <div class="col-6">
            <mat-label class="m-4">Sound</mat-label>
            <mat-slide-toggle
                (change)="toggleSound($event)"
                [checked]="true"
                class="ml-2"
            ></mat-slide-toggle>
        </div>
        <!-- <div class="col-6">
            <mat-label class="m-4">Dark Mode (experimental)</mat-label>
            <mat-slide-toggle (change)="toggleDarkMode($event)" style="color: white">Dark Mode</mat-slide-toggle>
        </div> -->

        <div class="col-6">
            <button
                mat-raised-button
                class="gh-button gh-button-secondary"
                (click)="this.openResetPassword()"
            >
                Change Password
            </button>
        </div>
    </div>

    <form [formGroup]="accountForm">
        <div class="areas-of-interest-container">
            <div class="mx-0 row">
                <mat-card-title class="gh-xsm-title section-title"
                    >Current Area of Interest:</mat-card-title
                >
            </div>
            <div class="mx-0 row">
                <div class="col-12">
                    <app-gh-simple-zipcode-selector
                        [_zipToEdit]="zipToEdit ? zipToEdit : null"
                        (onZipSelectedEmitter)="selectZipCode($event)"
                    ></app-gh-simple-zipcode-selector>
                </div>
            </div>
        </div>

        <div *ngIf="false" class="optional-info-container">
            <div class="mx-0 row">
                <mat-card-title class="gh-xsm-title section-title"
                    >OPTIONAL Information:</mat-card-title
                >
            </div>
            <div class="mx-0 row">
                <div class="col-12 col-md-4">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Date of Birth:</mat-label>
                        <input
                            #date
                            matInput
                            [matDatepicker]="picker"
                            placeholder="Date"
                            formControlName="dob"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-8">
                    <mat-card-subtitle class="gh-md-subtitle">Gender:</mat-card-subtitle>
                    <div class="mb-3">
                        <mat-radio-group
                            name="radioGroup"
                            formControlName="gender"
                            (change)="setSelectedGender($event)"
                        >
                            <mat-radio-button
                                *ngFor="let gender of genderOptions"
                                name="radioGroup"
                                [checked]="checkedGenderOptionKey == gender"
                                [value]="gender"
                                class="ml-4"
                            >
                                {{ gender }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
