import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { untilDestroyed } from '@ngneat/until-destroy'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MessageDetailsComponent } from '../message-details/message-details.component'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { BackendApiUserTrackingService } from 'src/app/backend-api-services/backend-api-user-tracking.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ProfileAboutMeFriendsFriendDetailModalComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile-about-me/components/profile-about-me-friends/components/profile-about-me-friends-friend-detail-modal/profile-about-me-friends-friend-detail-modal.component'

@Component({
    selector: 'app-friend-requests-table',
    templateUrl: './friend-requests-table.component.html',
    styleUrls: ['./friend-requests-table.component.scss'],
})
export class FriendRequestsTableComponent implements OnInit {
    // Default image
    defaultUserImage = window.location.origin + '/assets/images/defaults/profile-placeholder.png'

    @ViewChild(MatTable) table: MatTable<any>
    dataSource = new MatTableDataSource<any>()

    // Inputs
    @Input() set _dataSource(dataSource: any[]) {
        if (dataSource) {
            // Transform incoming array to a new MatTableDataSource
            this.dataSource = new MatTableDataSource<any>(dataSource)
            // Attempt to fetch user images for each row
            this.getUserImages()
        }
    }

    displayedColumns: string[]
    @Input() set _displayedColumns(displayedColumns: string[]) {
        this.displayedColumns = displayedColumns
    }

    isIncoming: boolean
    @Input() set _isIncoming(isIncoming: boolean) {
        this.isIncoming = isIncoming
    }

    user: any
    @Input() set _user(user: any) {
        this.user = user
    }

    hasDeleteButtons: boolean
    @Input() set _hasDeleteButtons(hasDeleteButtons: boolean) {
        this.hasDeleteButtons = hasDeleteButtons
    }

    hasConfirmationButtons: boolean
    @Input() set _hasConfirmationButtons(hasConfirmationButtons: boolean) {
        this.hasConfirmationButtons = hasConfirmationButtons
    }

    // Output event that notifies the parent we updated the data
    @Output() requestsUpdated = new EventEmitter<void>()
    @Output() composeMessageEmitter = new EventEmitter()

    isMobileScreen = false

    constructor(
        private screenSizeService: ScreenSizeService,
        private dialog: MatDialog,
        private backendApiMessageService: BackendAPIMessageService,
        private backendAPIUserTrackingService: BackendApiUserTrackingService,
        private backendAPIUsersService: BackendAPIUsersService,
        private snackbar: MatSnackBar,
        private arrayHelperService: ArrayHelperService,
    ) {}

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    // Pre-fetch user images for each entry in the table
    async getUserImages() {
        if (this.dataSource.filteredData && this.dataSource.filteredData.length > 0) {
            for (const item of this.dataSource.filteredData) {
                try {
                    if (this.isIncoming) {
                        // If incoming, fetch image for the user who created (sent) the request
                        const res = await this.backendAPIUsersService.GetUserMainImageFiles(
                            item.createdBy,
                        )
                        item.mainImageFiles = res?.mainImageFiles
                    } else {
                        // If outgoing, fetch image for the user who is the target
                        const res = await this.backendAPIUsersService.GetUserMainImageFiles(
                            item.sentToUID,
                        )
                        item.mainImageFiles = res?.mainImageFiles
                    }
                } catch (err) {
                    console.error('Error fetching user image', err)
                }
            }
        }
    }

    // For demonstration, shows a user detail modal (clicking row)
    async seeMessageDetails(item: any) {
        // Example usage of a user details modal
        console.log('MessageDetails ITEM', item)
        const userForDetails = await this.backendAPIUsersService.getProfileById(item.createdBy)

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileAboutMeFriendsFriendDetailModalComponent,
                inputData: { user: userForDetails },
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data) {
                    this.composeMessageEmitter.emit({
                        currentUser: data.currentUser,
                        messageToReplyTo: data.messageToReplyTo,
                    })
                }
            })
    }

    // Decline an incoming invite
    async declineInviteItem(item: any, type: string) {
        // Delete from backend
        const messageInput = { pk: this.user.username, sk: item.sk_type_ULID }
        await this.backendApiMessageService.deleteMessage(messageInput)

        // Remove from table data immediately
        this.dataSource = this.arrayHelperService.removeFromArray(
            this.dataSource,
            item,
            true,
            'sk_type_ULID',
        )
        this.table.renderRows()

        // Notify parent
        this.requestsUpdated.emit()
    }

    // Accept an incoming request
    async confirmRequest(user: string, element: any, type: string) {
        // 1. Create user tracking items both ways
        const input1 = { userId: user, targetId: element.createdBy, type: 'friendOf' }
        this.backendAPIUserTrackingService.createUserTrackingItem(input1)

        const input42 = { userId: element.createdBy, targetId: user, type: 'friendOf' }
        this.backendAPIUserTrackingService.createUserTrackingItem(input42)

        // 2. Provide feedback
        const otherUserData = await this.backendAPIUsersService.getUserByTitle(
            element.sentFromTitle,
        )
        this.snackbar.open('Adding: ' + otherUserData.title, 'Processing.', {
            duration: 2500,
        })

        // 3. Clean up friend-request messages from both sides
        const removeInvitationInput = { pk: this.user.username, sk: element.sk_type_ULID }
        await this.backendApiMessageService.deleteMessage(removeInvitationInput)

        const carl = '#FriendRequestOutgoing#' + this.user.username
        const removeOtherInput = { pk: otherUserData.username, sk: carl }
        await this.backendApiMessageService.deleteMessage(removeOtherInput)

        const carl2 = '#FriendRequestIncoming#' + otherUserData.username
        const removeOtherInput2 = { pk: this.user.username, sk: carl2 }
        await this.backendApiMessageService.deleteMessage(removeOtherInput2)

        // 4. Remove from table
        this.dataSource = this.arrayHelperService.removeFromArray(
            this.dataSource,
            element,
            true,
            'sk_type_ULID',
        )
        this.table.renderRows()

        // 5. Refresh the parent’s user data
        await this.backendAPIUsersService.initCurrentUser()

        // Finally, notify the parent to re-fetch
        this.requestsUpdated.emit()
    }

    // Cancel an outgoing invite
    async cancelOutgoingInviteItem(item: any, type: string) {
        // 1. Delete from the current user’s Outgoing list
        const deleteOutgoingInviteInput = { pk: this.user.username, sk: item.sk_type_ULID }
        await this.backendApiMessageService.deleteMessage(deleteOutgoingInviteInput)

        // 2. Delete from the target user’s Incoming list
        const deleteIncomingInviteInput = {
            pk: item.sentToUID,
            sk: item.sk_type_ULID.replace('Outgoing', 'Incoming'),
        }
        await this.backendApiMessageService.deleteMessage(deleteIncomingInviteInput)

        // 3. Remove from table data
        const tempDataSource = this.arrayHelperService.removeFromArray(
            this.dataSource,
            item,
            true,
            'sk_type_ULID',
        )
        this.dataSource = new MatTableDataSource<any>(tempDataSource)
        this.table.renderRows()

        // 4. Notify parent
        this.requestsUpdated.emit()
    }
}
