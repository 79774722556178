<div *ngIf="isMobileScreen; else desktopView">
    <mat-form-field class="gh-form-field gh-form-field-outline mt-4 px-2" appearance="outline">
        <mat-label>Select Category</mat-label>
        <mat-select
            (selectionChange)="onMobileContentSelectionChange($event)"
            [(value)]="selectedMobileContent"
        >
            <mat-option *ngFor="let t of tabs; let i = index" [value]="t">
                {{ t.title }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-template #viewContainer></ng-template>
</div>

<ng-template #desktopView>
    <div class="parent-container">
        <span id="tabs-span">
            <div class="tabs">
                <a class="tab-button" *ngFor="let t of tabs; let i = index" (click)="selectTab(i)">
                    <div class="row tab-row">
                        <span
                            class="tab-title"
                            [ngClass]="{ selectedTabMarker: t.isSelected === true }"
                        >
                            <span class="inline-span" style="padding: 0 !important">
                                <mat-icon>{{ t.icon }}</mat-icon>
                            </span>
                            <span
                                class="inline-span ml-1"
                                [ngClass]="{ selectedTabTitle: t.isSelected === true }"
                                >{{ t.title }}</span
                            >
                        </span>
                    </div>
                </a>
            </div>
        </span>

        <span id="content-span" [ngClass]="{ animateOutUp: animateOutUp === true }">
            <mat-card-content style="margin-left: 3rem">
                <ng-template #viewContainer></ng-template>
            </mat-card-content>
        </span>
    </div>
</ng-template>
