import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MatSelectChange } from '@angular/material/select'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatDialog } from '@angular/material/dialog'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/profile.component'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { AnnouncementFormComponent } from '../announcement-form/announcement-form.component'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-manage-attendance',
    templateUrl: './manage-attendance.component.html',
    styleUrls: ['./manage-attendance.component.scss'],
})
export class ManageAttendanceComponent implements OnInit {
    @Input() inputData: any
    @Input() user: any
    @Input() isGHAdmin: boolean
    isMobileScreen: boolean

    // Holds the convention details
    conventionDetails = new BehaviorSubject<any>(null)
    conventionDetails$ = this.conventionDetails.asObservable()

    // Holds all core users from UsersService
    coreUsers = new BehaviorSubject<any[]>([])
    coreUsers$ = this.coreUsers.asObservable()

    // Arrays that represent attendees
    arrayOfAttendees: string[] = []
    arrayOfAttendeesTitles: string[] = []
    finalUserData: any[] = []

    dropdownOptions = [
        { label: 'Option 1', type: 'type1' },
        { label: 'Option 2', type: 'type2' },
    ]
    selectedTabIndex = 0

    displayedColumns: string[] = ['title', 'userLevel', 'email', 'state', 'zip']
    dataSource = new MatTableDataSource<any>([])

    @ViewChild(MatSort, { static: true }) sort: MatSort

    /**
     * This will hold the entire JSON array from cityStateZipArray.json
     * so we can look up the state by ZIP code.
     */
    private cityStateZipData: any[] = []

    constructor(
        private usersService: UsersService,
        private backendApiUserService: BackendAPIUsersService,
        private backendApiContentService: BackendAPIContentService, // Make sure this is available
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        // 1) Initialize convention details
        this.conventionDetails.next(this.inputData.convention)
        this.arrayOfAttendees = this.conventionDetails.value.attendingUIDArray || []

        // 2) Subscribe to the coreUsers$ to get the user list,
        //    then fetch cityStateZipArray.json, then initiate user data
        this.usersService.coreUsers$.pipe(untilDestroyed(this)).subscribe((coreUsersResult) => {
            this.coreUsers.next(coreUsersResult)

            // Build an array of user titles (based on the arrayOfAttendees)
            this.arrayOfAttendeesTitles = this.arrayOfAttendees.map((attendeeUsername) => {
                const matchedUser = this.coreUsers.value.find(
                    (usr) => usr.username === attendeeUsername,
                )
                return matchedUser ? matchedUser.title : null
            })

            // Fetch the ZIP–City–State file from assets, then call initiateUsers
            this.fetchCityStateZipData()
                .then(() => {
                    this.initiateUsers()
                })
                .catch((err) => console.error('Error fetching cityStateZipData:', err))
        })
    }

    /**
     * Fetches the JSON data from /assets/cityStateZipArray.json
     * and stores it in the `cityStateZipData` property.
     */
    private async fetchCityStateZipData(): Promise<void> {
        try {
            const response = await fetch('assets/cityStateZipArray.json')
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            this.cityStateZipData = await response.json()
        } catch (error) {
            console.error('Error fetching cityStateZipData:', error)
            this.cityStateZipData = []
        }
    }

    /**
     * Given a zip code, returns the corresponding state code
     * (or "Unknown" if not found).
     */
    private getStateByZip(zipCode: number | string): string {
        const numericZip = typeof zipCode === 'string' ? parseInt(zipCode, 10) : zipCode
        const match = this.cityStateZipData.find((item) => item.zip_code === numericZip)
        return match ? match.state : 'Unknown'
    }

    /**
     * Initiates the user data array by fetching each user’s details
     * and merging the state info from cityStateZipData.
     */
    async initiateUsers() {
        if (!this.arrayOfAttendeesTitles || !this.arrayOfAttendeesTitles.length) return

        try {
            this.finalUserData = await Promise.all(
                this.arrayOfAttendeesTitles.map(async (title) => {
                    const userData = await this.backendApiUserService.getUserByTitle(title)
                    // Attach the state info
                    if (userData.zipCode) {
                        userData.state = this.getStateByZip(userData.zipCode)
                    } else {
                        userData.state = 'Unknown'
                    }
                    return userData
                }),
            )

            this.dataSource.data = this.finalUserData
            this.dataSource.sort = this.sort
        } catch (error) {
            console.error('Error fetching user data:', error)
        }
    }

    onDropdownChange(event: MatSelectChange): void {
        this.selectedTabIndex = event.value
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }

    getFilterLabel(): string {
        return 'Filter by User Title'
    }

    /**
     * Opens a dialog to send an announcement to all attendees
     */
    sendAnnouncement(): void {
        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Send Convention Announcement',
                component: AnnouncementFormComponent,
                inputData: {},
                hasSubmitButton: false, // We'll handle submit inside the component
                hasCancelButton: false,
                hasCloseButton: true,
                allowParentClose: true,
            },
        })

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result && result.intro && result.details) {
                const theLoop = this.inputData.convention.attendingUIDArray
                try {
                    await Promise.all(
                        theLoop.map(async (userId) => {
                            const NotificationInput = {
                                addedByTitle: 'Game Haven',
                                addedByUID: '042',
                                date: new Date().toLocaleDateString(),
                                notificationType: 'Announcement',
                                messageIntro:
                                    this.inputData.convention.extendedProps.eventTitle +
                                    ' Announcement: ' +
                                    result.intro,
                                message: result.details,
                                mainImage: 'Main Image Files WIP',
                                paidAd: 'false',
                                userId: userId,
                                eventStart: null,
                                eventEnd: null,
                            }
                            return this.backendApiContentService.createNotificationItems(
                                NotificationInput,
                            )
                        }),
                    )

                    alert('Convention Announcement Sent to All Attendees!')
                } catch (error) {
                    console.error('Error sending announcement:', error)
                    alert('Error sending announcement. Please try again.')
                }
            }
        })
    }

    isFilterEnabled(): boolean {
        return true
    }

    /**
     * Opens a dialog displaying user details
     */
    async openUserDetails(userID) {
        const userDetails = await this.backendApiUserService.getProfileById(userID.username)

        const inputData = {
            user: userDetails,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileComponent,
                inputData: inputData,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
            },
        })
    }
}
