import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-announcement-form',
    template: `
        <h2 mat-dialog-title>Send Convention Announcement</h2>
        <mat-dialog-content>
            <div class="form-group">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Announcement Intro</mat-label>
                    <input
                        matInput
                        [(ngModel)]="announcementIntro"
                        placeholder="Enter an introductory headline..."
                    />
                </mat-form-field>
            </div>

            <div class="form-group mt-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Announcement Details</mat-label>
                    <textarea
                        matInput
                        [(ngModel)]="announcementDetails"
                        placeholder="Enter detailed announcement text..."
                    ></textarea>
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-raised-button color="primary" (click)="onSubmit()">Send</button>
        </mat-dialog-actions>
    `,
})
export class AnnouncementFormComponent {
    announcementIntro: string = ''
    announcementDetails: string = ''

    constructor(
        public dialogRef: MatDialogRef<AnnouncementFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    onSubmit(): void {
        // Return the input data back to the parent component
        this.dialogRef.close({ intro: this.announcementIntro, details: this.announcementDetails })
    }
}
