<div [hidden]="!dataSource || dataSource.filteredData.length === 0">
    <table #table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Avatar column -->
        <ng-container matColumnDef="avatar">
            <th mat-header-cell *matHeaderCellDef></th>
            <td class="p-3" mat-cell *matCellDef="let element">
                <img
                    class="avatar-img"
                    [src]="
                        element.mainImageFiles && element.mainImageFiles[0]
                            ? element.mainImageFiles[0]
                            : defaultUserImage
                    "
                    alt=""
                />
            </td>
        </ng-container>

        <!-- Sent To column (for outgoing requests) -->
        <ng-container matColumnDef="sentToTitle" *ngIf="!isIncoming">
            <th mat-header-cell *matHeaderCellDef>To</th>
            <td class="p-3" mat-cell *matCellDef="let element">
                {{ element.sentToTitle }}
            </td>
        </ng-container>

        <!-- Sent From column (for incoming requests) -->
        <ng-container matColumnDef="sentFromTitle">
            <th mat-header-cell *matHeaderCellDef>From</th>
            <td mat-cell *matCellDef="let element">
                {{ element.sentFromTitle }}
            </td>
        </ng-container>

        <!-- Created date column -->
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Created</th>
            <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date : 'MM/dd/yyyy' }}
            </td>
        </ng-container>

        <!-- Delete/Decline/Cancel column -->
        <ng-container matColumnDef="delete" class="ml-2" *ngIf="isIncoming">
            <th mat-header-cell *matHeaderCellDef [hidden]="!hasDeleteButtons">Decline</th>
            <td mat-cell *matCellDef="let element" [hidden]="!hasDeleteButtons">
                <button
                    mat-button
                    (click)="declineInviteItem(element, 'invite'); $event.stopPropagation()"
                >
                    <mat-icon class="gh-delete-icon">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete" class="ml-2" *ngIf="!isIncoming">
            <th mat-header-cell *matHeaderCellDef [hidden]="!hasDeleteButtons">Cancel</th>
            <td mat-cell *matCellDef="let element" [hidden]="!hasDeleteButtons">
                <button
                    mat-button
                    (click)="cancelOutgoingInviteItem(element, 'request'); $event.stopPropagation()"
                >
                    <mat-icon class="gh-delete-icon">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Confirm column (for incoming) -->
        <ng-container matColumnDef="confirm" class="ml-2" *ngIf="isIncoming">
            <th mat-header-cell *matHeaderCellDef [hidden]="!hasConfirmationButtons">Confirm</th>
            <td mat-cell *matCellDef="let element" [hidden]="!hasConfirmationButtons">
                <button
                    mat-button
                    (click)="
                        confirmRequest(user.username, element, 'request'); $event.stopPropagation()
                    "
                >
                    <mat-icon class="gh-confirm-icon">check</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Table rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="seeMessageDetails(row)"
        ></tr>
    </table>
</div>

<div [hidden]="dataSource && dataSource.filteredData.length > 0">
    <app-no-data [_message]="'No data...'"></app-no-data>
</div>
