<mat-card-content>

    <div class="row">

        <!-- LEFT SIDE -->
        <div class="col-12 file-uploader-container">
            <app-gh-file-uploader (selectedImageChanged)="setSelectedImage($event)" #fileUploader [_isProfileImg]="false" [_sourceOfInput]="'app-create-link'">
            </app-gh-file-uploader>
        </div>

        <!-- RIGHT SIDE -->
        <div class="col-12">
            <form [formGroup]="linkForm">
                <mat-form-field class="gh-form-field">
                    <mat-label>Title</mat-label>
                    <input (keyup)="sendData()" matInput placeholder="Ex. Pizza" value="" formControlName="title">
                    <mat-error *ngIf="linkForm.get('title').errors?.required">Required</mat-error>
                </mat-form-field>

                <mat-form-field class="gh-form-field">
                    <mat-label>Description</mat-label>
                    <textarea (keyup)="sendData()" id="link-text-area" matInput placeholder="More details about your link..." formControlName="description"></textarea>
                </mat-form-field>

                <mat-form-field class="gh-form-field">
                    <mat-label>Link</mat-label>
                    <textarea (keyup)="sendData()" matInput placeholder="Link Address here" formControlName="link"></textarea>
                    <mat-error *ngIf="linkForm.get('link').errors?.required">Required</mat-error>
                </mat-form-field>
            </form>
        </div>

    </div>

</mat-card-content>