import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    AfterViewChecked,
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ChartType } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import { BehaviorSubject } from 'rxjs'

import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'

import { RadarChartService } from 'src/app/dashboard/dashboard-shared/services/charts/radar/radar-chart.service'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { RADAR_CHART_PROFILE_FRIEND_GAME_LABELS } from 'src/app/dashboard/constants/radar-chart-constants'
import { defaultRatingConst } from 'src/app/dashboard/dashboard-shared/constants/games.constants'
import {
    RadarChartAttributeEnums,
    RadarChartOverlayTypeEnums,
    RadarChartProfileFriendGameAttributeEnums,
} from 'src/app/dashboard/enums/radar-chart-enums'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gh-game-radar-chart',
    templateUrl: './gh-game-radar-chart.component.html',
    styleUrls: ['./gh-game-radar-chart.component.scss'],
})
export class GhGameRadarChartComponent implements OnInit, AfterViewChecked {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective
    @Output() closeEmitter = new EventEmitter()

    // Ex: if the parent sets an `_inputData` property in data => modal
    // we'll read it here:
    inputData: any

    // Ratings (slider values)
    complexityRating: number = defaultRatingConst
    depthRating: number = defaultRatingConst
    durationRating: number = defaultRatingConst
    interactionRating: number = defaultRatingConst
    competitionRating: number = defaultRatingConst
    playersRating: number = defaultRatingConst
    chanceRating: number = defaultRatingConst
    thematicRating: number = defaultRatingConst

    // Chart config
    chartType: ChartType = 'radar'
    chartData: any
    chartLabels: string[] = []
    radarChartLabelsStringified: string
    radarChartLabelsShallowCopy: string[]
    radarChartOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                min: 0,
                max: 10,
                beginAtZero: true,
                angleLines: { color: 'rgba(245, 121, 58, 0.6)' },
                pointLabels: {
                    font: { size: 18 },
                },
            },
        },
        plugins: {
            legend: { display: false },
        },
    }

    // Editing or new vote
    isEditing = false

    // Form
    form: UntypedFormGroup
    formIsLoaded = false
    attributes: number[] = []
    mostRecentDataValues: number[] = []

    // States
    isMobileScreen = true
    isLoading = new BehaviorSubject<boolean>(true)
    isLoading$ = this.isLoading.asObservable()
    user: any
    selectedGame: any

    // Enums
    radarChartAttributeEnum: any
    radarChartTypeEnum: any

    chartIsLoaded = false

    constructor(
        private snackbar: MatSnackBar,
        private chartService: RadarChartService,
        private screenSizeService: ScreenSizeService,
        private formBuilder: UntypedFormBuilder,
        private snackbarService: SnackbarService,
        private backendApiUsersService: BackendAPIUsersService,
        private backendApiGameTrackingService: BackendApiGameTrackingService,
        private backendApiGamesService: BackendAPIGamesService,
        private dialogRef: MatDialogRef<GhGameRadarChartComponent>,
    ) {}

    // ------------------------------------------------------------------------
    // Inputs from parent’s data property
    // ------------------------------------------------------------------------
    @Input() set _inputData(data: any) {
        this.inputData = data
        this.selectedGame = data.selectedItem
        this.isEditing = data.isEditing
        // Initialize chart
        this.initChartData(this.inputData).then(() => {
            this.isLoading.next(false)
        })
    }

    // Called if parent triggers a "submit from outside"
    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submitRating()
        }
    }

    // ------------------------------------------------------------------------
    // Lifecycle
    // ------------------------------------------------------------------------
    async ngOnInit() {
        this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
            this.isMobileScreen = isMobile
        })

        this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })

        this.radarChartAttributeEnum = RadarChartProfileFriendGameAttributeEnums
        this.radarChartTypeEnum = RadarChartAttributeEnums.game

        // A copy of the labels so we can manipulate if needed
        this.radarChartLabelsStringified = JSON.stringify(RADAR_CHART_PROFILE_FRIEND_GAME_LABELS)
        this.radarChartLabelsShallowCopy = JSON.parse(this.radarChartLabelsStringified)
        this.chartLabels = JSON.parse(this.radarChartLabelsStringified)

        // Build form
        this.form = this.formBuilder.group({
            complexityRating: [],
            depthRating: [],
            durationRating: [],
            interactionRating: [],
            competitionRating: [],
            playersRating: [],
            chanceRating: [],
            thematicRating: [],
        })
        this.formIsLoaded = true
    }

    ngAfterViewChecked() {
        if (!this.chartIsLoaded && this.chart) {
            this.chartIsLoaded = true
            // For example: add some chart plugin or config
            this.radarChartOptions = this.chartService.updateChartOption(
                this.radarChartOptions,
                'dragData',
                true,
            )
            this.chart.update()
        }
    }

    // ------------------------------------------------------------------------
    // Initialize Chart Data
    // ------------------------------------------------------------------------
    async initChartData(inputData: any) {
        // If editing, parse or set the existing attributes
        if (inputData.isEditing && inputData.attributes) {
            // If it's already an array, just keep it. If it's stringified, parse:
            if (typeof inputData.attributes === 'string') {
                this.attributes = JSON.parse(inputData.attributes)
            } else {
                this.attributes = inputData.attributes
            }
        } else {
            // New vote defaults
            this.attributes = [5, 5, 5, 5, 5, 5, 5, 5]
        }

        // Build the chart data set
        this.chartData = [
            {
                data: this.attributes,
                borderColor: '#116466',
                fill: true,
                backgroundColor: '#11646675',
                pointRadius: 10,
                pointHoverRadius: 20,
                pointBackgroundColor: 'white',
                pointBorderColor: '#116466',
                pointBorderWidth: 2,
            },
        ]

        // Initialize the "previous data" array
        const mainData: number[] = this.chartData[0].data
        for (let i = 0; i < mainData.length; i++) {
            this.mostRecentDataValues.push(mainData[i])
        }

        // Once everything is set, patch form fields
        this.patchFormFromAttributes(this.attributes)
    }

    patchFormFromAttributes(attr: number[]) {
        if (!this.form) return
        this.form.get('complexityRating')?.patchValue(attr[0])
        this.form.get('depthRating')?.patchValue(attr[1])
        this.form.get('durationRating')?.patchValue(attr[2])
        this.form.get('interactionRating')?.patchValue(attr[3])
        this.form.get('competitionRating')?.patchValue(attr[4])
        this.form.get('playersRating')?.patchValue(attr[5])
        this.form.get('chanceRating')?.patchValue(attr[6])
        this.form.get('thematicRating')?.patchValue(attr[7])
    }

    // ------------------------------------------------------------------------
    // Chart Interaction
    // ------------------------------------------------------------------------
    chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {}
    chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {}

    getCanvasPx() {
        return this.isMobileScreen ? 200 : 400
    }

    // ------------------------------------------------------------------------
    // Slider Value Changed Handlers
    // ------------------------------------------------------------------------
    onComplexitySliderChanged() {
        const val = this.form.get('complexityRating')?.value
        this.complexityRating = val
        this.chartData[0].data[0] = val
        this.chart.update()
    }
    onDepthSliderChanged() {
        const val = this.form.get('depthRating')?.value
        this.depthRating = val
        this.chartData[0].data[1] = val
        this.chart.update()
    }
    onDurationSliderChanged() {
        const val = this.form.get('durationRating')?.value
        this.durationRating = val
        this.chartData[0].data[2] = val
        this.chart.update()
    }
    onInteractionSliderChanged() {
        const val = this.form.get('interactionRating')?.value
        this.interactionRating = val
        this.chartData[0].data[3] = val
        this.chart.update()
    }
    onCompetitionSliderChanged() {
        const val = this.form.get('competitionRating')?.value
        this.competitionRating = val
        this.chartData[0].data[4] = val
        this.chart.update()
    }
    onPlayersSliderChanged() {
        const val = this.form.get('playersRating')?.value
        this.playersRating = val
        this.chartData[0].data[5] = val
        this.chart.update()
    }
    onChanceSliderChanged() {
        const val = this.form.get('chanceRating')?.value
        this.chanceRating = val
        this.chartData[0].data[6] = val
        this.chart.update()
    }
    onThematicSliderChanged() {
        const val = this.form.get('thematicRating')?.value
        this.thematicRating = val
        this.chartData[0].data[7] = val
        this.chart.update()
    }

    // ------------------------------------------------------------------------
    // Submit Rating
    // ------------------------------------------------------------------------
    async submitRating() {
        if (!this.selectedGame) {
            return
        }

        // Current chart values
        const currentRatings = [
            this.chartData[0].data[0],
            this.chartData[0].data[1],
            this.chartData[0].data[2],
            this.chartData[0].data[3],
            this.chartData[0].data[4],
            this.chartData[0].data[5],
            this.chartData[0].data[6],
            this.chartData[0].data[7],
        ]

        // 1) Create or update the "tracking item" for the user
        const userVoteInput = {
            gameId: this.selectedGame.id,
            UID: this.user?.username,
            type: 'big8Vote',
            userState: this.user?.state,
            userZipCode: this.user?.zipCode,
            userTitle: this.user?.title,
            mainImageFiles: undefined,
            title: undefined,
            big8Vote: currentRatings,
        }
        try {
            // Creates or updates the user's personal "tracking" item
            await this.backendApiGameTrackingService.createGameTrackingItemAR(userVoteInput)
        } catch (err) {
            console.error('Error creating game tracking item:', err)
        }

        // 2) Update the aggregated Big8 array in the main game DB item
        let big8VotesLocal = this.selectedGame.big8Votes ?? 0
        let big8Current = [5, 5, 5, 5, 5, 5, 5, 5]
        if (this.selectedGame.big8) {
            // parse if needed
            if (typeof this.selectedGame.big8 === 'string') {
                big8Current = JSON.parse(this.selectedGame.big8)
            } else {
                big8Current = this.selectedGame.big8
            }
        }

        // If editing, we need to remove the old user’s rating from the average and add the new one.
        if (this.isEditing) {
            // old vote is in inputData.attributes
            let prevVote: number[] =
                typeof this.inputData.attributes === 'string'
                    ? JSON.parse(this.inputData.attributes)
                    : this.inputData.attributes

            // Weighted average adjustment: basically do:
            // new avg = ((oldAvg * n) + newVal - oldVal) / n
            // Because the # of votes doesn't change if user is just editing

            for (let i = 0; i < 8; i++) {
                big8Current[i] =
                    (big8Current[i] * big8VotesLocal + currentRatings[i] - prevVote[i]) /
                    big8VotesLocal
            }
        } else {
            // new vote
            // new avg = ((oldAvg * n) + newVal) / (n + 1)
            big8VotesLocal++
            for (let i = 0; i < 8; i++) {
                big8Current[i] =
                    (big8Current[i] * (big8VotesLocal - 1) + currentRatings[i]) / big8VotesLocal
            }
        }

        // 3) Push updated "big8" and "big8Votes" to the DB
        try {
            const updateBig8Votes = {
                pkName: 'pk',
                pkValue: this.selectedGame.id,
                skName: 'sk',
                skValue: 'BD#' + this.selectedGame.id,
                attributeName: 'big8Votes',
                attributeValue: JSON.stringify(big8VotesLocal),
            }
            await this.backendApiGamesService.updateSingleAttributeForGame(updateBig8Votes)

            const updateBig8 = {
                pkName: 'pk',
                pkValue: this.selectedGame.id,
                skName: 'sk',
                skValue: 'BD#' + this.selectedGame.id,
                attributeName: 'big8',
                attributeValue: JSON.stringify(big8Current),
            }
            await this.backendApiGamesService.updateSingleAttributeForGame(updateBig8)
            this.snackbarService.openSuccessSnackBar('Information Recorded in the Database')
        } catch (err) {
            console.error('Error updating Big8 or Big8Votes:', err)
        }

        // Finally, close dialog with a "refresh" signal
        this.dialogRef.close('resetData')
    }

    // ------------------------------------------------------------------------
    // Removing a previous vote
    // ------------------------------------------------------------------------
    async removeVoteAndCloseModal() {
        if (!this.isEditing) {
            return
        }
        // Example logic:
        // 1) Decrement or remove this user from "big8Votes"
        // 2) Recalculate the average
        // 3) Remove the user’s tracking item from DB
        // 4) Then close with 'resetData'
        // This part is extremely custom. Below is a rough example:

        try {
            let prevUserBig8Votes =
                await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
                    this.user?.username,
                    '#big8Vote#',
                    49,
                )

            const game = this.selectedGame
            let big8VotesLocal = game.big8Votes ?? 0
            if (big8VotesLocal <= 1) {
                // If only 1 or 0 votes, removing this one might reset Big8 to 0
                const resetVotes = {
                    pkName: 'pk',
                    pkValue: game.id,
                    skName: 'sk',
                    skValue: 'BD#' + game.id,
                    attributeName: 'big8Votes',
                    attributeValue: '0',
                }
                await this.backendApiGamesService.updateSingleAttributeForGame(resetVotes)

                const resetBig8 = {
                    pkName: 'pk',
                    pkValue: game.id,
                    skName: 'sk',
                    skValue: 'BD#' + game.id,
                    attributeName: 'big8',
                    attributeValue: null,
                }
                await this.backendApiGamesService.updateSingleAttributeForGame(resetBig8)

                // Remove user’s big8Vote item in the game-tracking table
                const pk = game.id
                const sk = '#big8Vote#' + this.user.username
                await this.backendApiGameTrackingService.deleteGameTrackingItemByPkSk({ pk, sk })

                this.snackbarService.openSuccessSnackBar('Vote Removed')
                this.dialogRef.close('resetData')
            } else {
                // If there are multiple votes, you’d need to do the "subtract out old vote" approach
                // from the average, and decrement big8VotesLocal. Then update DB, etc.
                // Here’s a skeleton:

                // 1) find the old vote
                const arrayOfObjects = prevUserBig8Votes?.games || []
                const existing = arrayOfObjects.find((item) => item.id === game.id)
                if (!existing) {
                    // no vote found
                    this.dialogRef.close('resetData')
                    return
                }
                const oldVote = existing.big8Vote // array of 8

                // 2) parse the game’s existing big8
                let big8Current = [5, 5, 5, 5, 5, 5, 5, 5]
                if (game.big8) {
                    if (typeof game.big8 === 'string') {
                        big8Current = JSON.parse(game.big8)
                    } else {
                        big8Current = game.big8
                    }
                }
                // 3) new avg = ((oldAvg * n) - oldVal) / (n - 1)
                let oldCount = big8VotesLocal
                big8VotesLocal = oldCount - 1
                for (let i = 0; i < 8; i++) {
                    big8Current[i] = (big8Current[i] * oldCount - oldVote[i]) / big8VotesLocal
                }

                // 4) update DB
                const updateBig8Votes = {
                    pkName: 'pk',
                    pkValue: game.id,
                    skName: 'sk',
                    skValue: 'BD#' + game.id,
                    attributeName: 'big8Votes',
                    attributeValue: JSON.stringify(big8VotesLocal),
                }
                await this.backendApiGamesService.updateSingleAttributeForGame(updateBig8Votes)

                const updateBig8 = {
                    pkName: 'pk',
                    pkValue: game.id,
                    skName: 'sk',
                    skValue: 'BD#' + game.id,
                    attributeName: 'big8',
                    attributeValue: JSON.stringify(big8Current),
                }
                await this.backendApiGamesService.updateSingleAttributeForGame(updateBig8)

                // 5) remove user tracking
                const pk = game.id
                const sk = '#big8Vote#' + this.user.username
                await this.backendApiGameTrackingService.deleteGameTrackingItemByPkSk({ pk, sk })

                this.snackbarService.openSuccessSnackBar('Vote Removed')
                this.dialogRef.close('resetData')
            }
        } catch (error) {
            console.error('Error removing vote:', error)
        }
    }
}
