import { Component, HostListener, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, Subscription, interval } from 'rxjs'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MessagingComponent } from 'src/app/dashboard/components/sidebar/components/messaging/messaging.component'
import { TournamentBuilderSetupComponent } from 'src/app/dashboard/dashboard-shared/components/tournaments/tournament-builder-setup/tournament-builder-setup.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { SoundEffectsService } from 'src/app/dashboard/dashboard-shared/services/audio/sound-effects.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import { NotificationPanelService } from 'src/app/dashboard/dashboard-shared/services/panels/notification-panel.service'
import { SidebarService } from 'src/app/dashboard/dashboard-shared/services/sidebar/sidebar.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { GlobalService } from 'src/app/shared/services/global/global.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { AuthenticatedHeaderAnimations } from './animations/authenticated-header-animations'
import { BulkRateGamesComponent } from 'src/app/dashboard/dashboard-shared/components/ratings/bulk-rate-games/bulk-rate-games.component'
import { GhLocationInputComponent } from 'src/app/dashboard/dashboard-shared/components/custom-inputs/gh-location-input/gh-location-input.component'
import { TempHavenComponentsComponent } from 'src/app/dashboard/pages/havens/pages/havens-dashboard/temp-haven-components/temp-haven-components.component'
import { GhLocationSelectorMapboxGLComponent } from 'src/app/dashboard/dashboard-shared/components/mapping/gh-location-selector-mapbox-gl/gh-location-selector-mapbox-gl.component'
import { GhMapLocationSelectorTextOnlyComponent } from 'src/app/dashboard/dashboard-shared/components/mapping/gh-map-location-selector-text-only/gh-map-location-selector-text-only.component'
import { ColorSchemeService } from 'src/app/shared/services/color-scheme.service'
import { ManageAccountComponent } from 'src/app/dashboard/components/account-panel/components/manage-account/manage-account.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { EditMyProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/components/edit-my-profile/edit-my-profile.component'
import { BannerSizeEnums } from 'src/app/dashboard/dashboard-shared/enums/banner-size-enums'
import { BannerLocationEnums } from 'src/app/dashboard/dashboard-shared/enums/banner-location-enums'
import { GamenightComponent } from 'src/app/dashboard/dashboard-shared/components/events/gamenight/gamenight.component'
import { HopperComponent } from 'src/app/dashboard/dashboard-shared/components/hopper/hopper/hopper.component'
import { HopperCompetitionLevelEnums } from 'src/app/dashboard/dashboard-shared/enums/hopper-enums'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { PlayNowModalComponent } from 'src/app/dashboard/components/sidebar/components/play-now/components/play-now-modal/play-now-modal.component'
import { EventBuilderComponent } from 'src/app/dashboard/dashboard-shared/generics/event-builder/event-builder.component'
import { GamesBuySellSwapComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/components/games-buy-sell-swap/games-buy-sell-swap.component'
import { CalendarManagementComponent } from 'src/app/dashboard/components/sidebar/components/calendar-management/calendar-management.component'
import { FriendsAndFollowersComponent } from 'src/app/dashboard/components/sidebar/components/friends-and-followers/friends-and-followers.component'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TrendingDataComponent } from 'src/app/dashboard/components/sidebar/components/trending-data/trending-data.component'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { TheFeedComponent } from 'src/app/dashboard/components/sidebar/components/the-feed/the-feed.component'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-authenticated-header',
    templateUrl: './authenticated-header.component.html',
    styleUrls: ['./authenticated-header.component.scss'],
    animations: [AuthenticatedHeaderAnimations.growIn],
})
export class AuthenticatedHeaderComponent implements OnInit {
    @ViewChild('header', { static: false }) header: any
    @ViewChild('logo', { static: false }) logo: any

    user = new BehaviorSubject<any>(null)
    user$ = this.user.asObservable()

    userAvatarImg = window.location.origin + '/assets/images/defaults/profile-placeholder.png'

    showAlphaBadge = true

    pageTitleIcon: any
    pageTitle: any
    allEventsSort: MatSort

    animState: any

    userIsActive = true

    notificationCount: number = null
    messagesCount: number = null
    newFriendCount: number = 0
    newInviteCount: number = 0

    hideSecretButtonsForAnimation = false

    @ViewChild('secretButton', { static: false }) secretButton: any
    @ViewChild('key', { static: false }) key: any

    showTestingToggles = false
    keepLockVisible = false

    shouldAnimateOpen = false
    shouldAnimateClose = false

    private intervalId: any

    username: string

    notificationPanelIsActive = false
    sidebarIsActive = false

    isMobileScreen = true
    conventionDetails = new BehaviorSubject<any>(null)
    conventionDetails$ = this.conventionDetails.asObservable()

    showMobileMenuIcon = false
    private subscription: Subscription
    allEventsDataSource = new MatTableDataSource<any>()

    constructor(
        private soundEffectsService: SoundEffectsService,
        private sidebarService: SidebarService,
        private notificationPanelService: NotificationPanelService,
        private globalService: GlobalService,
        private screenSizeService: ScreenSizeService,
        private timingHelperService: TimingHelperService,
        private router: Router,
        private userService: UsersService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private authService: AuthService,
        private backendApiMessageService: BackendAPIMessageService,
        private backendAPIContentService: BackendAPIContentService,
        private backendAPIUsersService: BackendAPIUsersService,
        private colorSchemeService: ColorSchemeService,
        private gamesService: GamesService,
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.showMobileMenuIcon = !this.router.url.includes('/public/')
            }
        })
    }
    daySort: MatSort
    dropdownOptions: Array<{ label: string; type: string; data?: any }> = []
    selectedTabIndex: number = 0

    @ViewChild('daySort') set matSortDay(ms: MatSort) {
        if (ms) {
            this.daySort = ms
            const selectedOption = this.dropdownOptions[this.selectedTabIndex]
            if (selectedOption?.type === 'day') {
                const day = selectedOption?.data
                if (day?.games) {
                    day.games.sort = this.daySort
                }
            }
        }
    }
    isGHAdmin = false
    isGHStaff = false
    isHaven = false

    userData: any
    currentUser: any

    async ngOnInit() {
        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user) {
                this.userAvatarImg = user.mainImageFiles
                    ? user.mainImageFiles[0]
                    : window.location.origin + '/assets/images/defaults/profile-placeholder.png'
                this.user.next(user)
                this.currentUser = user
                console.log('~~~ user', this.currentUser)

                // Only fetch notifications/messages if username is defined
                if (user?.username) {
                    this.fetchNotifications(user.username)
                    this.fetchMyMessages()
                }
            }
        })

        this.fetchMyEventInvites()
        this.fetchMyFriendInvites()
        this.startInterval()

        this.userService
            .getUserIsActive()
            .pipe(untilDestroyed(this))
            .subscribe((isActive) => {
                this.userIsActive = isActive
            })

        this.notificationPanelService.panelIsActive$
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.notificationPanelIsActive = value
            })

        this.globalService.sidebarIsActive$
            .pipe(untilDestroyed(this))
            .subscribe((sidebarIsActive: boolean) => {
                this.sidebarIsActive = sidebarIsActive
            })

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.globalService.currentPageInfo$
            .pipe(untilDestroyed(this))
            .subscribe((currentPageInfo) => {
                if (currentPageInfo) {
                    this.pageTitleIcon = currentPageInfo.icon
                    this.pageTitle = currentPageInfo.title
                }
            })
    }

    customSortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
        switch (sortHeaderId) {
            case 'day':
                return data.date ? new Date(data.date).getDay() : 0
            case 'game':
                return data.name || ''
            case 'title':
                return data.title || ''
            case 'cost':
                return data.cost || ''
            case 'players':
                return data.currentPlayers || 0
            case 'startTime':
                return data.startTime ? data.startTime.getTime() : 0
            case 'duration':
                return data.duration || 0
            case 'createdByTitle':
                return data.createdByTitle || ''
            case 'date':
                return data.date ? new Date(data.date).getTime() : 0
            default:
                return data[sortHeaderId]
        }
    }

    async fetchNotifications(username: string) {
        if (!username) {
            return
        }

        this.fetchMyNotifications()

        // Wrap in try/catch to avoid errors if service calls fail
        try {
            await this.backendApiMessageService.listMyFriendRequests(username, 50)
        } catch (error) {
            console.error('Error fetching friend requests:', error)
        }

        try {
            await this.backendApiMessageService.listMyInvites(username, 50)
        } catch (error) {
            console.error('Error fetching invites:', error)
        }

        try {
            await this.backendApiMessageService.listMyReceivedMessages(username, 50)
        } catch (error) {
            console.error('Error fetching messages:', error)
        }
    }

    private startInterval() {
        // Initial immediate fetch
        this.fetchMyNotifications()
        this.fetchMyMessages()
        this.fetchMyFriendInvites()
        this.fetchMyEventInvites()

        // Define the progression of intervals in milliseconds
        const intervals = [3000, 6000, 15000] // 3s, 6s, 15s
        let index = 0

        const scheduleNext = () => {
            if (this.userIsActive && this.user?.value?.username) {
                this.fetchMyNotifications()
                this.fetchMyMessages()
                this.fetchMyFriendInvites()
                this.fetchMyEventInvites()
            } else {
                console.log('User is inactive or user not defined')
            }

            if (index < intervals.length) {
                // Use setTimeout for the next progressive interval
                this.intervalId = setTimeout(() => {
                    index++
                    scheduleNext()
                }, intervals[index])
            } else {
                // After completing the 3 progressive intervals, set a recurring 30 second interval
                this.intervalId = setInterval(() => {
                    if (this.userIsActive && this.user?.value?.username) {
                        this.fetchMyNotifications()
                        this.fetchMyMessages()
                        this.fetchMyFriendInvites()
                        this.fetchMyEventInvites()
                    } else {
                        console.log('User is inactive or user not defined')
                    }
                }, 30000)
            }
        }

        // Start the cycle by calling scheduleNext for the first time
        scheduleNext()
    }

    async fetchMyEventInvites() {
        if (!this.user?.value?.username) {
            this.newInviteCount = 0
            return
        }

        try {
            const invitesRes = await this.backendApiMessageService.listMyInvites(
                this.user.value.username,
                20,
            )
            this.newInviteCount = invitesRes?.invites ? invitesRes.invites.length : 0
        } catch (error) {
            console.error('Error fetching event invites:', error)
            this.newInviteCount = 0
        }
    }

    async fetchMyFriendInvites() {
        if (!this.user?.value?.username || !this.user?.value?.title) {
            this.newFriendCount = 0
            return
        }

        try {
            const requestsRes = await this.backendApiMessageService.listMyFriendRequests(
                this.user.value.username,
                20,
            )
            const requests = requestsRes?.requests || []
            this.newFriendCount = requests.filter(
                (request) => request.sentToTitle === this.user.value.title,
            ).length
        } catch (error) {
            console.error('Error fetching friend invites:', error)
            this.newFriendCount = 0
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
        this.stopInterval()
    }

    private stopInterval() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
    }

    shouldCheck = true
    secretButtonIsLoaded = false
    ngAfterViewChecked() {
        if (!this.secretButtonIsLoaded && this.secretButton) {
            this.secretButtonIsLoaded = true
            this.secretButton.nativeElement.style.cursor =
                "url('../../../../assets/images/coolStuff/key-only.png'), auto"
            this.toggleShowExpirimentalItems({ checked: true })
        }

        if (this.user?.value && this.shouldCheck) {
            this.shouldCheck = false

            if (this.user.value.userLevel > 6 && this.user.value.userLevel < 7) {
                this.isGHStaff = true
            }
            if (this.user.value.userLevel > 8) {
                this.isGHAdmin = true
            }
            if (this.user.value.userIsHaven) {
                this.isHaven = true
            }
        }
    }

    toggleDarkMode(event: any) {
        this.colorSchemeService.update(event.checked ? 'dark' : 'light')
    }

    toggleUserIsHaven(event: any) {
        this.globalService.setUserIsHaven(event.checked)
    }

    toggleShowExpirimentalItems(event: any) {
        this.globalService.setShowExpirimentalItems(event.checked)
    }

    toggleNotificationPanel() {
        this.notificationPanelIsActive = !this.notificationPanelIsActive
        this.notificationPanelService.setPanelIsActive(this.notificationPanelIsActive)
    }

    manageAccountSettings() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Manage Account',
                component: ManageAccountComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }
    toggleMessagingPanel() {
        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Messaging',
                component: MessagingComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })

        dialogRef.afterClosed().subscribe(() => {
            // Refresh messages and notifications after the messaging panel closes
            if (this.user?.value?.username) {
                this.fetchMyMessages()
                this.fetchMyNotifications()
            }
        })
    }

    toggleCalendarManagementPanel() {
        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Calendar Management',
                component: CalendarManagementComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })

        dialogRef.afterClosed().subscribe(() => {
            // Refresh event invites and notifications after calendar management panel closes
            if (this.user?.value?.username) {
                this.fetchMyEventInvites()
                this.fetchMyNotifications()
            }
        })
    }

    toggleFriendsAndFollowers() {
        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Friend Invites',
                component: FriendsAndFollowersComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })

        dialogRef.afterClosed().subscribe(() => {
            // Refresh friend invites and notifications after the friends/followers panel closes
            if (this.user?.value?.username) {
                this.fetchMyFriendInvites()
                this.fetchMyNotifications()
            }
        })
    }

    currentListOfMessagess: Array<{ date: string }> = []
    async fetchMyMessages() {
        if (!this.user?.value?.username) {
            this.messagesCount = 0
            this.currentListOfMessagess = []
            return
        }
        try {
            const myMessages = await this.backendApiMessageService.listMyReceivedMessages(
                this.user.value.username,
                500,
            )
            const messagesCount = myMessages?.messages || []
            this.messagesCount = messagesCount.length
            this.currentListOfMessagess = messagesCount
        } catch (error) {
            console.error('Error fetching messages:', error)
            this.messagesCount = 0
            this.currentListOfMessagess = []
        }
    }

    currentListOfNotifications: Array<{ date: string }> = []
    async fetchMyNotifications() {
        if (!this.user?.value?.username) {
            this.notificationCount = 0
            this.currentListOfNotifications = []
            return
        }

        try {
            const myNotifications = await this.backendAPIContentService.listContentByUserID(
                this.user.value.username,
                'notification',
                500,
            )

            const notificationCount = myNotifications?.content || []

            // Sort notifications by date descending
            notificationCount.sort((a, b) => {
                let dateA: number = new Date(a.date).getTime()
                let dateB: number = new Date(b.date).getTime()
                return dateB - dateA
            })

            this.notificationCount = notificationCount.length
            this.currentListOfNotifications = notificationCount
        } catch (error) {
            console.error('Error fetching notifications:', error)
            this.notificationCount = 0
            this.currentListOfNotifications = []
        }
    }

    toggleSidebar() {
        this.globalService.toggleSidebarIsActive()
    }

    editProfile() {
        const editProfileRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Edit Profile',
                component: EditMyProfileComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                hasCloseButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        editProfileRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                await this.timingHelperService.delay(1000)
                try {
                    if (this.currentUser) {
                        await this.backendAPIUsersService.updateUser(this.currentUser, null)
                        this.backendAPIUsersService.initCurrentUser()
                    }
                } catch (error) {
                    console.error('Error updating user:', error)
                }
            })
    }

    dataToExport: any
    async createConventionData() {
        try {
            let dataForPublicDisplay = await this.backendAPIContentService.getContentItemByPkSk(
                '01jcex3dxxj5fj2nss4wffyf7p',
                'e',
            )
            console.log('Data for Public Display:', dataForPublicDisplay)

            let otherDataForPublicDisplay = await this.fetchScheduledPlaysForThisConvention()
            console.log('Other Data to Export:', otherDataForPublicDisplay)
        } catch (error) {
            console.error('Error creating convention data:', error)
        }
    }

    bannerAdInputDataNonSpecific = {
        bannerSizeEnum: BannerSizeEnums.banner300x250,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: null,
        isIFrame: false,
    }

    async onSecretButtonMouseDown() {
        try {
            let getProfileResult = await this.backendAPIUsersService.getMyProfile()

            this.hideSecretButtonsForAnimation = true
            this.showTestingToggles = !this.showTestingToggles

            if (this.showTestingToggles) {
                this.keepLockVisible = true
                this.shouldAnimateOpen = true
                this.shouldAnimateClose = false
            } else {
                this.shouldAnimateOpen = false
                this.shouldAnimateClose = true
            }

            if (this.secretButton?.nativeElement) {
                this.secretButton.nativeElement.style.cursor = 'none'
            }

            // Even though SCSS animation is 0.5s, 400ms works best here
            await this.timingHelperService.delay(400)
            if (this.showTestingToggles) {
                this.soundEffectsService.playLockNoise()
                this.soundEffectsService.playSecretFoundNoise()
                if (this.key?.nativeElement) {
                    this.key.nativeElement.style.display = 'block'
                }
                if (this.secretButton?.nativeElement) {
                    this.secretButton.nativeElement.style.cursor = 'pointer'
                }
            } else {
                this.soundEffectsService.playLockNoise()
                this.keepLockVisible = false
                if (this.key?.nativeElement) {
                    this.key.nativeElement.style.display = 'none'
                }
                if (this.secretButton?.nativeElement) {
                    this.secretButton.nativeElement.style.cursor =
                        "url('../../../../assets/images/coolStuff/key-only.png'), auto"
                }
            }
        } catch (error) {
            console.error('Error on secret button mouse down:', error)
        }
    }

    onAnimStart(event: any) {}

    onAnimDone(event: any) {}

    goHome() {
        this.sidebarService.updateSelectedItem('PROFILE')
        this.router.navigate(['./../../dashboard/my-profile'], { relativeTo: this.route })
    }

    openTournamentBuilderSetup() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Create Tournement',
                component: TournamentBuilderSetupComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    openMapTesting() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Pick Haven Location',
                component: GhLocationSelectorMapboxGLComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    openBulkRateGames() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Bulk Rate Games',
                component: BulkRateGamesComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openMapLocationSelectorTextOnly() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Location Selector',
                component: GhMapLocationSelectorTextOnlyComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openEventBuilder() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Event Builder',
                component: EventBuilderComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                inputData: {
                    events: null,
                    rooms: JSON.stringify([
                        'Main Room Table 1',
                        'Main Room Table 2',
                        'Back Room Table 1',
                    ]),
                    timeInterval: 1,
                    startHour: 15,
                    endHour: 20,
                    haven: 'testing',
                },
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openCastleConDetails() {
        this.sidebarService.updateSelectedItem('PROFILE')
        this.router.navigate(['./../../dashboard/my-profile'], { relativeTo: this.route })
        this.timingHelperService.delay(1000).then(() => {
            this.sidebarService.updateSelectedItem('FIND EVENTS')
            this.router.navigate(['../../../dashboard/events'], { relativeTo: this.route })
        })
    }

    openLocationSelector() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Location Selector',
                component: GhLocationInputComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openHavenTestingButtons() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Haven Testing Buttons',
                component: TempHavenComponentsComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    logout() {
        this.authService.logout().then(() => {
            this.router.navigate(['../../../public'])
        })
    }

    goToConventionsTempPage() {
        this.router.navigate(['../../../temp-conventions'])
    }

    testBuySellSwap() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Preview Buy/Sell/Swap',
                component: GamesBuySellSwapComponent,
                inputData: null,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Submit',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    testPlayNow() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Play Now Preview',
                component: PlayNowModalComponent,
                inputData: null,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    openTheFeed() {
        this.dialog
            .open(GhDialogWrapperComponent, {
                panelClass: this.isMobileScreen
                    ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                    : DESKTOP_MODAL_PANEL_CLASS,
                backdropClass: 'gh-dialog-backdrop',
                data: {
                    title: 'The Feed',
                    component: TheFeedComponent,
                    inputData: this.currentListOfNotifications || [],
                    hasSubmitButton: false,
                    hasCancelButton: false,
                    hasCloseButton: true,
                    width: '99%',
                    allowParentClose: true,

                    height: '99%',
                },
            })
            .afterClosed()
            .subscribe(() => {
                if (this.currentUser?.username) {
                    this.fetchNotifications(this.currentUser.username)
                }
            })
    }

    trendingDataModal() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Trending Games',
                component: TrendingDataComponent,
                inputData: null,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    testGamenight() {
        let gameNightInputData = {
            attendingList: [],
            invitedList: [],
            games: [],
            food: [
                {
                    eventFood: 'Pizza',
                    contributor: '01gejf8bxqk0hkcmz99tk4da4c',
                    requestor: '01gejf8bxqk0hkcmz99tk4da4c',
                    isCommitted: true,
                },
            ],
            beverages: [],
            description: 'here is event description!!!',
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Game Night Preview',
                component: GamenightComponent,
                inputData: gameNightInputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    testHopper() {
        const coreGames = this.gamesService.getCoreGames()
        const gamesSample = coreGames.slice(0, 4)

        let inputData = {
            hopperData: [
                {
                    itemId: 'abc',
                    group: {
                        admin: 'Shay',
                        members: [{ title: 'Shay' }],
                    },
                    game: {
                        title: gamesSample[0].title,
                        currentPlayerCount: 1,
                        minPlayers: 1,
                        maxPlayers: 2,
                        votedBestMinPlayers: 1,
                        votedBestMaxPlayers: 2,
                        hasCustomBounds: false,
                        customLowerBound: 0,
                        customUpperBound: 0,
                        minPlayersCanAccept: 1,
                        maxPlayersCanAccept: 1,
                        idealPlayersToAccept: 1,
                        isFlaggedForMatch: false,
                        mainImage: gamesSample[0].mainImageFiles[0],
                    },
                    desiredIndices: [],
                    comments: 'Hello everyone',
                    competitionLevel: HopperCompetitionLevelEnums.tournament,
                    postedTimestamp: '',
                },
                {
                    itemId: 'def',
                    group: {
                        admin: 'Dave',
                        members: [{ title: 'Dave' }, { title: 'Shay' }],
                    },
                    game: {
                        title: gamesSample[1].title,
                        currentPlayerCount: 2,
                        minPlayers: 2,
                        maxPlayers: 4,
                        votedBestMinPlayers: 4,
                        votedBestMaxPlayers: 4,
                        hasCustomBounds: false,
                        customLowerBound: 0,
                        customUpperBound: 0,
                        minPlayersCanAccept: 2,
                        maxPlayersCanAccept: 2,
                        idealPlayersToAccept: 2,
                        isFlaggedForMatch: false,
                        mainImage: gamesSample[1].mainImageFiles[0],
                    },
                    desiredIndices: [],
                    comments: '',
                    competitionLevel: HopperCompetitionLevelEnums.serious,
                    postedTimestamp: '',
                },
                {
                    itemId: 'ghi',
                    group: {
                        admin: 'Shay',
                        members: [{ title: 'Shay' }],
                    },
                    game: {
                        title: gamesSample[2].title,
                        currentPlayerCount: 1,
                        minPlayers: 4,
                        maxPlayers: 6,
                        votedBestMinPlayers: 4,
                        votedBestMaxPlayers: 6,
                        hasCustomBounds: true,
                        customLowerBound: 2,
                        customUpperBound: 8,
                        minPlayersCanAccept: 1,
                        maxPlayersCanAccept: 99,
                        idealPlayersToAccept: 7,
                        isFlaggedForMatch: true,
                        mainImage: gamesSample[2].mainImageFiles[0],
                    },
                    desiredIndices: [],
                    comments: 'Im the guy in the blue shirt',
                    competitionLevel: HopperCompetitionLevelEnums.competitive,
                    postedTimestamp: '',
                },
                {
                    itemId: 'jkl',
                    group: {
                        admin: 'Gina',
                        members: [{ title: 'Gina' }],
                    },
                    game: {
                        title: gamesSample[3].title,
                        currentPlayerCount: 1,
                        minPlayers: 2,
                        maxPlayers: 2,
                        votedBestMinPlayers: 2,
                        votedBestMaxPlayers: 2,
                        hasCustomBounds: false,
                        customLowerBound: 0,
                        customUpperBound: 0,
                        minPlayersCanAccept: 1,
                        maxPlayersCanAccept: 1,
                        idealPlayersToAccept: 2,
                        isFlaggedForMatch: false,
                        mainImage: gamesSample[3].mainImageFiles[0],
                    },
                    desiredIndices: [],
                    comments: 'Free beer to join!',
                    competitionLevel: HopperCompetitionLevelEnums.casual,
                    postedTimestamp: '',
                },
            ],
            displayedColumns: [
                'group',
                'game',
                'playersNeeded',
                'competition',
                'comments',
                'minutesSincePosted',
                'join',
                'actions',
            ],
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Hopper Preview: Let us know what you think!',
                component: HopperComponent,
                inputData: inputData,
                hasSubmitButton: false,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    events: any[]

    async fetchScheduledPlaysForThisConvention() {
        try {
            const response = await this.backendAPIContentService.listScheduledPlaysByConventionId(
                '01jcex3dxxj5fj2nss4wffyf7p',
                'sp',
                500,
            )

            this.dataToExport = response

            const approvedItems =
                response?.items?.filter((game: any) => game.isApproved === 'true') || []
            this.events = approvedItems.map((game: any) => {
                let playerList = []
                let waitlist = []

                // Parse playerList
                if (game.playerList) {
                    if (typeof game.playerList === 'string' && game.playerList.trim() !== '') {
                        try {
                            playerList = JSON.parse(game.playerList)
                        } catch (e) {
                            console.error('Error parsing playerList:', e)
                            playerList = []
                        }
                    } else if (Array.isArray(game.playerList)) {
                        playerList = game.playerList
                    }
                }

                // Parse waitlist
                if (game.waitlist) {
                    if (typeof game.waitlist === 'string' && game.waitlist.trim() !== '') {
                        try {
                            waitlist = JSON.parse(game.waitlist)
                        } catch (e) {
                            console.error('Error parsing waitlist:', e)
                            waitlist = []
                        }
                    } else if (Array.isArray(game.waitlist)) {
                        waitlist = game.waitlist
                    }
                }

                let startTime = game.startTime ? new Date(game.startTime) : null
                let endTime = startTime
                    ? new Date(startTime.getTime() + (game.duration || 0) * 60000)
                    : null

                return {
                    ...game,
                    startTime: startTime,
                    endTime: endTime,
                    playerList: playerList,
                    waitlist: waitlist,
                    currentPlayers: playerList.length,
                }
            })

            this.allEventsDataSource = new MatTableDataSource(this.events)
            this.allEventsDataSource.filterPredicate = (data, filter) => {
                return data.name?.toLowerCase().includes(filter)
            }
            this.allEventsDataSource.sortingDataAccessor = this.customSortingDataAccessor
            if (this.allEventsSort) {
                this.allEventsDataSource.sort = this.allEventsSort
            }

            this.updateDaysWithEvents()
            this.updateMyEvents()

            // Trigger JSON file download
            const jsonData = JSON.stringify(response, null, 2)
            const blob = new Blob([jsonData], { type: 'application/json' })
            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = url
            link.download = 'scheduled_plays.json'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            URL.revokeObjectURL(url)

            return response
        } catch (error) {
            console.error('Error fetching scheduled plays:', error)
            return null
        }
    }

    private updateDaysWithEvents(): void {
        const conventionVal = this.conventionDetails.value
        if (conventionVal?.extendedProps?.days && Array.isArray(conventionVal.extendedProps.days)) {
            const newDays = conventionVal.extendedProps.days.map((day: any) => {
                const eventsForDay = (this.events || []).filter(
                    (event) =>
                        event.date &&
                        new Date(event.date).toDateString() === new Date(day.date).toDateString(),
                )

                const eventsWithFlags = eventsForDay.map((game: any) => ({
                    ...game,
                    isOverlapping: game.isOverlapping || false,
                }))

                const gamesDataSource = new MatTableDataSource(eventsWithFlags)
                gamesDataSource.sortingDataAccessor = this.customSortingDataAccessor

                if (this.daySort) {
                    gamesDataSource.sort = this.daySort
                }

                return {
                    ...day,
                    games: gamesDataSource,
                }
            })

            const newExtendedProps = {
                ...conventionVal.extendedProps,
                days: newDays,
            }

            const conventionDetailsClone = {
                ...conventionVal,
                extendedProps: newExtendedProps,
            }

            this.conventionDetails.next(conventionDetailsClone)
        }
    }

    private updateMyEvents(): void {
        const conventionVal = this.conventionDetails.value
        if (conventionVal?.extendedProps?.days && Array.isArray(conventionVal.extendedProps.days)) {
            // If needed, implement user-specific event filtering here.
            // The original code is commented out, so we leave it as is for no functionality change.
        }
    }
}
