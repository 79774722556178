<div class="parent-container">
    <div class="row centered-div" style="width: 100%;">

        <!-- <div class="col-12 text-center">
            <mat-card-subtitle>Only use the icon to directly paste
                in
                your youtube
                link</mat-card-subtitle>
        </div> -->


        <div class="col-12" style="margin-top: -1rem">
            <mat-form-field class="gh-form-field">
                <mat-label>Youtube URL</mat-label>
                <input (input)="processUrl($event.target.value)" matInput placeholder="URL" formControlName="rawURL">
            </mat-form-field>
        </div>


        <!-- 
        <div class="col-12">
            <h4>raw {{urlToDisplay}}</h4>
        </div>
        <div class="col-12">
            <h4>processed <span style="color: red;">{{processedUrlToDisplay}}</span></h4>
        </div> -->

        <!-- <iframe width="560" height="315" [src]="safeReconstructedURL" frameborder="0" allowfullscreen></iframe> -->
        <iframe *ngIf="safeReconstructedURL" width="426" height="240" [src]="safeReconstructedURL" frameborder="0"
            allowfullscreen></iframe>

    </div>




    <!-- <div class="col-12 w-100">
        <editor class="m-4" apiKey="3gji6f46ozf9p0mnr5tjx6ci1vt5457ku6so2ux0chbvsw38"
            [init]="{ plugins: 'media', media_dimensions: false, toolbar: 'media', menubar: false, statusbar: false }"
            [(ngModel)]="video" (onSelectionChange)="handleEvent($event)"></editor>
    </div> -->

</div>