import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import Fuse from 'fuse.js'
import { Observable } from 'rxjs'
import { GeoService } from 'src/app/shared/services/geo.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map, startWith } from 'rxjs/operators'
import {
    STATE_ABBREVIATIONS,
    STATE_ABBREVIATIONS_OHIO_ONLY,
} from '../../../constants/shared.constants'
import { iGeoLocation } from '../../../interfaces/location/IGeoLocation'
import { LOCATION_COUNTRY_NAME_USA } from '../../../constants/geoLocation.constants'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gh-location-input',
    templateUrl: './gh-location-input.component.html',
    styleUrls: ['./gh-location-input.component.scss'],
})
export class GhLocationInputComponent implements OnInit {
    maxRows = 1
    @Input() set _maxRows(maxRows: number) {
        if (maxRows) {
            this.maxRows = maxRows
        }
    }

    @Output() outputEmitter = new EventEmitter()

    geoData

    // stateOptions = STATE_ABBREVIATIONS_OHIO_ONLY // temp
    stateOptions = STATE_ABBREVIATIONS // temp

    stateFilteredOptions1: Observable<string[]>
    stateFilteredOptions2: Observable<string[]>
    stateFilteredOptions3: Observable<string[]>
    stateFilteredOptions4: Observable<string[]>

    locationOptions1
    filteredLocationOptions1: Observable<string[]>

    locationOptions2
    filteredLocationOptions2: Observable<string[]>

    locationOptions3
    filteredLocationOptions3: Observable<string[]>

    locationOptions4
    filteredLocationOptions4: Observable<string[]>

    locationSelection1: iGeoLocation
    locationSelection2: iGeoLocation
    locationSelection3: iGeoLocation
    locationSelection4: iGeoLocation

    // move to constants
    fuseOptions = {
        threshold: 0.15, // the lower the number the better match needed, 0-1
        keys: ['zip', 'city'],
    }

    fuse: any
    // fuseResults1: any[] = []

    locationForm

    constructor(private formBuilder: UntypedFormBuilder, private geoService: GeoService) {}

    ngOnInit(): void {
        this.locationForm = this.formBuilder.group({
            areaOfInterestItems: this.formBuilder.array([]),
        })
        this.addAreaOfInterestItem()

        this.geoService.coreLocationData$.pipe(untilDestroyed(this)).subscribe((geoData) => {
            if (geoData) {
                this.geoData = geoData
            }
            // ! this is coming back null
        })

        this.setStateFilterOptions()
    }

    setStateFilterOptions() {
        let areasOfInterestControlsLength =
            this.locationForm.controls.areaOfInterestItems.controls.length

        if (areasOfInterestControlsLength >= 1) {
            this.stateFilteredOptions1 = this.locationForm.controls.areaOfInterestItems.controls[
                areasOfInterestControlsLength - 1
            ]
                .get('state')
                .valueChanges.pipe(
                    startWith(''),
                    map((value) => this.stateFilter1(value)),
                )
        }

        if (areasOfInterestControlsLength >= 2) {
            this.stateFilteredOptions2 = this.locationForm.controls.areaOfInterestItems.controls[
                areasOfInterestControlsLength - 2
            ]
                .get('state')
                .valueChanges.pipe(
                    startWith(''),
                    map((value) => this.stateFilter1(value)),
                )
        }

        if (areasOfInterestControlsLength >= 3) {
            this.stateFilteredOptions3 = this.locationForm.controls.areaOfInterestItems.controls[
                areasOfInterestControlsLength - 3
            ]
                .get('state')
                .valueChanges.pipe(
                    startWith(''),
                    map((value) => this.stateFilter1(value)),
                )
        }

        if (areasOfInterestControlsLength >= 4) {
            this.stateFilteredOptions4 = this.locationForm.controls.areaOfInterestItems.controls[
                areasOfInterestControlsLength - 4
            ]
                .get('state')
                .valueChanges.pipe(
                    startWith(''),
                    map((value) => this.stateFilter1(value)),
                )
        }
    }

    submitSimulation() {}

    areaOfInterestItemsList = []

    get areaOfInterestItems(): UntypedFormArray {
        return this.locationForm.get('areaOfInterestItems') as UntypedFormArray
    }

    addAreaOfInterestItem(value?) {
        if (this.areaOfInterestItems.length < this.maxRows) {
            this.areaOfInterestItems.push(this.newAreaOfInterestItem(value))
            this.areaOfInterestItemsList.push({ value: value ? value : null })
        }
    }

    newAreaOfInterestItem(value?): UntypedFormGroup {
        return this.formBuilder.group({
            state: '',
            zipCode: '',
            // zipCode: [{value: '', disabled: this.isDisabled}] // replace this.isDisabled with some logic for the index of the 4 possible items
        })
    }

    removeAreaOfInterestItem(i: number) {
        this.areaOfInterestItems.removeAt(i)
        this.areaOfInterestItemsList.splice(i, 1)
        if (this.areaOfInterestItems.length == 0) {
            this.addAreaOfInterestItem()
        }
    }

    private _filter1(value) {
        // TODO: need to modify other filter methods to be like this one (if statement and return res)
        if (typeof value != 'string') {
            return
        }

        var res
        if (value == '' || value == null || !value) {
            res = this.locationOptions1
        } else {
            this.fuse = new Fuse(this.locationOptions1, this.fuseOptions)
            res = this.fuse.search(value)
        }
        return res
    }

    private _filter2(value) {
        if (value == '' || value == null || !value) {
            return this.locationOptions1
        } else {
            this.fuse = new Fuse(this.locationOptions2, this.fuseOptions)
            return this.fuse.search(value)
        }
    }

    private _filter3(value) {
        if (value == '' || value == null || !value) {
            return this.locationOptions1
        } else {
            this.fuse = new Fuse(this.locationOptions3, this.fuseOptions)
            return this.fuse.search(value)
        }
    }

    private _filter4(value) {
        if (value == '' || value == null || !value) {
            return this.locationOptions1
        } else {
            this.fuse = new Fuse(this.locationOptions4, this.fuseOptions)
            return this.fuse.search(value)
        }
    }

    private stateFilter1(value): string[] {
        const filterValue = value.toLowerCase()
        return this.stateOptions.filter((option) => option.toLowerCase().includes(filterValue))
    }

    cityOptions = []
    selectState(event, index?) {
        this.cityOptions = []
        index = index ? index : 0

        // const acceptableZipCodes = ['43001', '43002', '43003', '43004', '43008', '43013', '43015', '43016', '43017', '43021', '43023', '43025', '43026', '43029', '43030', '43031', '43032', '43033', '43035', '43036', '43040', '43045', '43046', '43054', '43055', '43056', '43061', '43062', '43064', '43065', '43066', '43067', '43068', '43071', '43074', '43076', '43077', '43080', '43081', '43082', '43085', '43102', '43103', '43105', '43107', '43109', '43110', '43111', '43112', '43113', '43116', '43117', '43119', '43123', '43125', '43126', '43127', '43130', '43135', '43136', '43137', '43138', '43140', '43143', '43144', '43145', '43146', '43147', '43148', '43149', '43150', '43151', '43152', '43153', '43154', '43155', '43156', '43157', '43158', '43162', '43164', '43201', '43202', '43203', '43204', '43205', '43206', '43207', '43209', '43210', '43211', '43212', '43213', '43214', '43215', '43217', '43219', '43220', '43221', '43222', '43223', '43224', '43227', '43228', '43229', '43230', '43231', '43232', '43235', '43240', '43315', '43317', '43320', '43321', '43334', '43338', '43344', '43721', '43730', '43731', '43739', '43740', '43748', '43760', '43761', '43764', '43766', '43782', '43783']

        //! to toggle between Ohio only and all locations, also need to modify the importants constants at the top of this class

        //! this is for Ohio only
        // for (let item of this.geoData) {
        //   if (item.state_id == event.option.value && acceptableZipCodes.includes(item.zip)) {
        //     this.cityOptions.push(item)
        //   } else {
        //   }
        // }

        //! this is for all locations
        for (let item of this.geoData) {
            if (item.state_id == event.option.value) {
                this.cityOptions.push(item)
            } else {
            }
        }

        switch (index) {
            case 0:
                this.locationOptions1 = this.cityOptions
                this.filteredLocationOptions1 =
                    this.locationForm.controls.areaOfInterestItems.controls[index]
                        .get('zipCode')
                        .valueChanges.pipe(
                            startWith(''),
                            map((value) => this._filter1(value)),
                        )
                break

            case 1:
                this.locationOptions2 = this.cityOptions
                this.filteredLocationOptions2 =
                    this.locationForm.controls.areaOfInterestItems.controls[index]
                        .get('zipCode')
                        .valueChanges.pipe(
                            startWith(''),
                            map((value) => this._filter2(value)),
                        )
                break

            case 2:
                this.locationOptions3 = this.cityOptions
                this.filteredLocationOptions3 =
                    this.locationForm.controls.areaOfInterestItems.controls[index]
                        .get('zipCode')
                        .valueChanges.pipe(
                            startWith(''),
                            map((value) => this._filter3(value)),
                        )
                break

            case 3:
                this.locationOptions4 = this.cityOptions
                this.filteredLocationOptions4 =
                    this.locationForm.controls.areaOfInterestItems.controls[index]
                        .get('zipCode')
                        .valueChanges.pipe(
                            startWith(''),
                            map((value) => this._filter4(value)),
                        )
                break
        }
    }

    index
    indexOfGeoTarget
    selectSpecificLocation(index, indexOfGeoTarget) {
        this.index = index
        this.indexOfGeoTarget = indexOfGeoTarget

        // !wtf
        // this.areaOfInterestItems.controls[index].get('zipcode').patchValue(event.option.value.city + ', '
        // + event.option.value.zip + ' (' + event.option.value.county_name + ' county)')
        // !wtf

        if (index == 0) {
            this.locationSelection1 = {
                country: LOCATION_COUNTRY_NAME_USA,
                state: this.cityOptions[indexOfGeoTarget].state_name,
                county: this.cityOptions[indexOfGeoTarget].county_name,
                city: this.cityOptions[indexOfGeoTarget].city,
                zipCode: this.cityOptions[indexOfGeoTarget].zip,
                lat: this.cityOptions[indexOfGeoTarget].lat,
                lng: this.cityOptions[indexOfGeoTarget].lng,
            }

            this.outputEmitter.emit(this.locationSelection1)
        }
        // else if (index == 1) {
        //   this.locationSelection2 = {
        //     country: LOCATION_COUNTRY_NAME_USA,
        //     state: event.option.value.state_id,
        //     county: event.option.value.county_name,
        //     city: event.option.value.city,
        //     zipCode: +event.option.value.zip
        //   }
        // }
        // else if (index == 2) {
        //   this.locationSelection3 = {
        //     country: LOCATION_COUNTRY_NAME_USA,
        //     state: event.option.value.state_id,
        //     county: event.option.value.county_name,
        //     city: event.option.value.city,
        //     zipCode: +event.option.value.zip
        //   }
        // }
        // else if (index == 3) {
        //   this.locationSelection4 = {
        //     country: LOCATION_COUNTRY_NAME_USA,
        //     state: event.option.value.state_id,
        //     county: event.option.value.county_name,
        //     city: event.option.value.city,
        //     zipCode: +event.option.value.zip
        //   }
        // }

        this.outputEmitter.emit({
            primaryAOI: this.locationSelection1,
            additionalAOI1: this.locationSelection2, // not collected in MVP
            additionalAOI2: this.locationSelection3, // not collected in MVP
            additionalAOI3: this.locationSelection4, // not collected in MVP
        })
    }
}
