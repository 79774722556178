import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service'
import { UserGameCollectionTypeEnums } from '../../../../../../../../../../common/enums/CommonEnums'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatDialogRef } from '@angular/material/dialog'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-edit-profile-game-collections',
    templateUrl: './edit-profile-game-collections.component.html',
    styleUrls: ['./edit-profile-game-collections.component.scss'],
})
export class EditProfileGameCollectionsComponent implements OnInit {
    @ViewChild('variableLengthSelector', { static: false }) variableLengthSelector

    userGameCollectionType: UserGameCollectionTypeEnums

    collection
    coreGameData = new BehaviorSubject(null)
    coreGameData$ = this.coreGameData.asObservable()

    coreGames = new BehaviorSubject(null)
    coreGames$ = this.coreGames.asObservable()
    updatedCollectionToEmit

    @Input() set _inputData(inputData) {
        this.userGameCollectionType = inputData.userGameCollectionType
        this.collection = inputData.collection
    }

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submit()
        }
    }

    constructor(
        private dialogRef: MatDialogRef<EditProfileGameCollectionsComponent>,
        private backendApiGamesService: BackendAPIGamesService,
        private arrayHelperService: ArrayHelperService,
        private screenSizeService: ScreenSizeService,
        private gamesService: GamesService,

        private backendApiGameTrackingService: BackendApiGameTrackingService,
        private snackbarService: SnackbarService,
    ) {}

    isMobileScreen = false
    coreGamesToSearch
    ngOnInit(): void {
        // this.fetchCoreGameData()

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.gamesService.coreGames$.pipe(untilDestroyed(this)).subscribe(async (coreGames) => {
            if (coreGames) {
                // Filter out expansions
                coreGames = coreGames.filter((obj) => !obj.isExpansion)
                this.coreGames.next(coreGames)
                this.coreGamesToSearch = coreGames
            }
        })
    }

    nextCoreGameDataToken //todo need to finish next token logic

    // async fetchCoreGameData() {
    //   let coreGameDataResult = await this.backendApiGamesService.listAllGameCoreData(5000, this.nextCoreGameDataToken)
    //   this.coreGameData.next(coreGameDataResult.coreGames)

    //   //! once done, move this logic tp helper class (array helper service)
    //   this.coreGameData.next(await this.arrayHelperService.removePrependingCharacters(this.coreGameData.value, '#'))
    // }

    onSelectionsChanged(event) {
        this.collection = event
        this.updatedCollectionToEmit = event
    }

    submit() {
        if (this.variableLengthSelector.formIsInvalid()) {
            this.variableLengthSelector.submitIsClicked = true
            this.snackbarService.openErrorSnackBar('Form is invalid.')
        } else {
            this.dialogRef.close(this.updatedCollectionToEmit)
        }
    }
}
