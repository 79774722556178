<div class="parent-container">
    <div class="mx-0 mt-4 row">
        <div class="col-12">
            <app-profile-avatar-card
                [_isAuthenticated]="isAuthenticated.value"
                [_selectedEntity]="selectedEntity.value"
                [_radarData]="radarData"
                [_friendRequestData]="friendRequestData.value"
                [_selectedEntityBio]="selectedEntity.value?.bio"
                [_isFriend]="selectedUserIsFriend"
                [_isCurrentUsersProfile]="isCurrentUsersProfile"
                [_userIsAdmin]="userIsAdmin"
                [_currentRoute]="currentRoute"
                (onContactButtonClicked)="contactButtonClicked()"
            >
            </app-profile-avatar-card>
        </div>
    </div>

    <div class="mx-0 mt-3 row">
        <div class="col-sm-12 col-12 col-lg-6 col-md-12 games-i-love">
            <mat-card class="mat-elevation-z6">
                <div class="gh-card-header-row has-button row">
                    <div class="col-12">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">
                                {{
                                    isAuthenticated.value
                                        ? gamesILoveLoggedIn
                                        : gamesILovedNotLoggedIn
                                }}</mat-card-title
                            >
                        </span>
                        <span *ngIf="isCurrentUsersProfile" class="gh-header-row-item-right mr-1">
                            <mat-icon
                                class="gh-edit-icon gh-icon gh-icon-clickable"
                                matTooltip="Edit"
                                matTooltipPosition="above"
                                (click)="editGamesILove()"
                            >
                                edit
                            </mat-icon>
                        </span>
                    </div>
                    <div class="col-12">
                        <mat-divider></mat-divider>
                    </div>
                    <div *ngIf="isMobileScreen" class="col-12">
                        <app-gh-swiper
                            [_items]="gamesILove"
                            [_numberOfTiles]="2"
                            [_imgMaxSizePx]="128"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        >
                        </app-gh-swiper>
                    </div>
                    <div *ngIf="!isMobileScreen" class="col-12">
                        <app-gh-swiper
                            [_items]="gamesILove"
                            [_numberOfTiles]="4"
                            [_imgMaxSizePx]="100"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        >
                        </app-gh-swiper>
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="looking-to-play-col col-sm-12 col-12 col-lg-6 col-md-12">
            <mat-card class="mat-elevation-z6">
                <div class="gh-card-header-row has-button row">
                    <div class="col-12">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">
                                {{
                                    isAuthenticated.value
                                        ? gamesIWantToPlayLoggedIn
                                        : gamesIWantToPlayNotLoggedIn
                                }}</mat-card-title
                            >
                        </span>
                        <span *ngIf="isCurrentUsersProfile" class="gh-header-row-item-right mr-1">
                            <mat-icon
                                class="gh-edit-icon gh-icon gh-icon-clickable"
                                matTooltip="Edit"
                                matTooltipPosition="above"
                                (click)="editLookingToPlay()"
                            >
                                edit
                            </mat-icon>
                        </span>
                    </div>
                    <div class="col-12">
                        <mat-divider></mat-divider>
                    </div>
                    <div *ngIf="isMobileScreen" class="col-12">
                        <app-gh-swiper
                            [_items]="lookingToPlay"
                            [_imgMaxSizePx]="128"
                            [_numberOfTiles]="2"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        ></app-gh-swiper>
                    </div>
                    <div *ngIf="!isMobileScreen" class="col-12">
                        <app-gh-swiper
                            [_items]="lookingToPlay"
                            [_imgMaxSizePx]="128"
                            [_numberOfTiles]="4"
                            (_itemClickedEmitter)="onGameDetailSelected($event)"
                        ></app-gh-swiper>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="mx-0 mt-3 mb-3 row">
        <div class="col-sm-12 col-12 col-lg-6 col-md-12">
            <mat-card class="mat-elevation-z6">
                <div class="gh-card-header-row has-button row">
                    <div class="col-12">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">My Recent Plays:</mat-card-title>
                        </span>
                    </div>
                    <div class="col-12">
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <div class="content-row row">
                    <div class="w-100 col-12">
                        <app-profile-recent-logged-plays
                            [_selectedEntity]="this.selectedEntity.value"
                            [_inputData]="isCurrentUsersProfile"
                        >
                        </app-profile-recent-logged-plays>
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="col-sm-12 col-12 col-lg-6 col-md-12 radar-mini-col">
            <div class="row" *ngIf="!isViewingFriend">
                <div class="col-sm-12 col-12 col-lg-12 col-md-12">
                    <ng-container *ngTemplateOutlet="myGamePreferencesCard"></ng-container>
                </div>
            </div>

            <div class="row" *ngIf="isViewingFriend">
                <div class="col-12">
                    <ng-container *ngTemplateOutlet="myGamePreferencesCard"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingSpinner>
    <div class="gh-loading-container">
        <app-gh-loading-spinner></app-gh-loading-spinner>
    </div>
</ng-template>

<ng-template #myGamePreferencesCard>
    <mat-card class="mat-elevation-z6 radar-mini-card">
        <div class="gh-card-header-row has-button row">
            <div class="col-12">
                <span class="inline-span" (click)="viewFullRadarChart()">
                    <mat-card-title class="gh-sm-title">My Game Preferences:</mat-card-title>
                </span>
                <span class="inline-span inline-span-align-right" *ngIf="!isMobileScreen"> </span>
            </div>
            <div class="col-12">
                <mat-divider></mat-divider>
            </div>
        </div>

        <div class="content-row row">
            <app-gh-profile-radar-chart-mini
                #miniRadarChart
                (radarChartResultEmitter)="logEmittedResults($event)"
                [_miniRadarData]="radarData"
                [_type]="radarChartType"
                [_selectedItem]="selectedEntity.value"
                [_showMatchPercentage]="!isCurrentUsersProfile"
                [_isCurrentUsersProfile]="isCurrentUsersProfile"
                [_overlayType]="radarOverlayType"
                [_editButtonShows]="false"
                [_uniqueAttribute]="'username'"
                class="mini-radar-chart"
            >
            </app-gh-profile-radar-chart-mini>
        </div>
    </mat-card>
</ng-template>
