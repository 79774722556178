<nav class="navbar navbar-dark navbar-expand-lg" id="headerId">
    <span (click)="goHome()" class="logo-container">
        <a class="navbar-brand">
            <img
                id="logo"
                src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                alt=""
            />
        </a>
    </span>

    <span class="right-side-span">
        <button class="cta-button-mobile" (click)="signUp()">Signup</button>
        <button class="login-button-mobile" (click)="logIn()">Login</button>
    </span>
</nav>
