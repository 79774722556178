<mat-card-content #viewContent>
    <form [formGroup]="form">
        <div class="mx-0 mt-2 row section">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12">
                        <app-autocomplete-chiplist
                            style="width: 100%"
                            *ngIf="coreGames$ | async"
                            [_items]="coreGames.value"
                            [_selectedItems]="gameToEdit ? [gameToEdit] : null"
                            [_label]="'Game Title'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                            [_useFuzzySearch]="true"
                            (selectionChanged)="selectGame($event)"
                        >
                            <!-- ! Is the app-autocomplete-chiplist working properly here to not allow custom inputs -->
                        </app-autocomplete-chiplist>
                    </div>
                    <div class="col-12">
                        <app-autocomplete-chiplist
                            *ngIf="coreHavens$ | async"
                            [_items]="coreHavens.value"
                            [_selectedItems]="havenToEdit ? [havenToEdit] : null"
                            [_label]="'Haven Title'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-share-modal-advanced-log-play2'"
                            (selectionChanged)="selectHaven($event)"
                        >
                        </app-autocomplete-chiplist>
                    </div>
                    <div class="col-12">
                        <app-autocomplete-chiplist
                            *ngIf="coreGroups"
                            [_items]="coreGroups"
                            [_selectedItems]="groupToEdit ? [groupToEdit] : null"
                            [_label]="'Your Group Title'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-share-modal-advanced-log-play3'"
                            (selectionChanged)="selectGroup($event)"
                        >
                        </app-autocomplete-chiplist>
                    </div>

                    <div class="col-12">
                        <app-autocomplete-chiplist
                            *ngIf="myEvents$ | async"
                            [_items]="myEvents.value"
                            [_selectedItems]="eventToEdit ? [eventToEdit] : null"
                            [_label]="'Your Event Title'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-share-modal-advanced-log-play3'"
                            (selectionChanged)="selectEvent($event)"
                        >
                        </app-autocomplete-chiplist>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <app-gh-file-uploader
                    (selectedImageChanged)="setSelectedImage($event)"
                    #fileUploader
                    [_isProfileImg]="false"
                    [_defaultImage]="selectedImage"
                    [_sourceOfInput]="'app-share-modal-image'"
                    [_isEditing]="isEditing"
                >
                </app-gh-file-uploader>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Duration</mat-label>
                            <mat-select [compareWith]="compareFn" formControlName="duration">
                                <mat-option
                                    *ngFor="let option of durationOptions"
                                    [value]="option.value"
                                >
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="date" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="text-center col-12 col-md-4" style="padding-top: 1rem">
                        <mat-label>I Won?</mat-label>

                        <mat-checkbox
                            value="0"
                            [checked]="form.get('iWon').value"
                            (change)="iWon = !iWon"
                            formControlName="iWon"
                        >
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isEditing" class="mx-0 row section">
            <!-- Label -->
            <div class="mt-2 text-center col-12">
                <mat-card-title class="gh-xsm-title">Log Play for a Friend?</mat-card-title>
            </div>

            <div class="col-12">
                <div class="row" *ngIf="coreUsers$ | async">
                    <!-- USER 2 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist
                            [_items]="coreUsers.value"
                            [_label]="'2nd Player'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-messaging'"
                            (selectionChanged)="selectUser2($event)"
                        >
                        </app-autocomplete-chiplist>
                    </div>

                    <!-- USER 3 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist
                            [_items]="coreUsers.value"
                            [_label]="'3rd Player'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-messaging'"
                            (selectionChanged)="selectUser3($event)"
                        >
                        </app-autocomplete-chiplist>
                    </div>

                    <!-- USER 4 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist
                            [_items]="coreUsers.value"
                            [_label]="'4th Player'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-messaging'"
                            (selectionChanged)="selectUser4($event)"
                        >
                        </app-autocomplete-chiplist>
                    </div>

                    <!-- USER 5 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist
                            [_items]="coreUsers.value"
                            [_label]="'5th Player'"
                            [_allowDuplicates]="false"
                            [_allowCustomInput]="false"
                            [_itemsAreObjects]="true"
                            [_singleInput]="true"
                            [_sourceOfInput]="'app-messaging'"
                            (selectionChanged)="selectUser5($event)"
                        >
                        </app-autocomplete-chiplist>
                    </div>
                </div>
            </div>
        </div>

        <!-- ! FUTURE  --  expansions -->
        <div class="m-0 row section" *ngIf="possibleExpansions && possibleExpansions.length > 0">
            <div class="col-12">
                <h1>hi</h1>
                <!-- <div class="col-12 col-md-4">
                <mat-form-field class="gh-form-field">
                    <mat-label>Expansions</mat-label>
                    <mat-select (selectionChange)="setExpansions($event)" multiple formControlName="expansions">
                        <mat-option *ngFor="let expansion of possibleExpansions" [value]="expansion">
                            {{expansion.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            </div>
        </div>

        <div class="mx-0 row section">
            <div class="mt-2 text-center col-12">
                <mat-card-title class="gh-xsm-title"
                    >Tag Other Non-Game Haven Users?</mat-card-title
                >
            </div>

            <div class="w-100 text-center col-12 col-md-4">
                <mat-form-field class="gh-form-field">
                    <mat-label>Non-GH User</mat-label>
                    <mat-select
                        formControlName="nonGhUser1"
                        multiple
                        (selectionChange)="setNonGhUser1($event)"
                    >
                        <mat-option *ngFor="let player of players.value" [value]="player"
                            >{{ player }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="w-100 text-center col-12 col-md-4">
                <mat-form-field class="gh-form-field">
                    <mat-label>Add Non-Game Haven User:</mat-label>
                    <input
                        type="text"
                        placeholder="Name"
                        matInput
                        formControlName="taggableNonGhUsers"
                        autocompleteOff
                    />
                </mat-form-field>
                <button mat-raised-button (click)="addNameToPersonalListOfNonGhUsers()">Add</button>
            </div>

            <div class="w-100 text-center col-12 col-md-3">
                <mat-form-field class="gh-form-field">
                    <mat-label>Remove Non-Game Haven User:</mat-label>
                    <mat-select
                        formControlName="removeNonGHUser"
                        placeholder="Name"
                        (selectionChange)="selectionChanged($event)"
                    >
                        <mat-option *ngFor="let user of players.value" [value]="user">
                            {{ user }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button (click)="removeNameFromPersonalListOfNonGhUsers()">
                    Remove
                </button>
            </div>
        </div>

        <div class="mx-0 row section">
            <div class="col-12">
                <div class="mt-2 gh-bordered-textarea-container">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Public Notes:</mat-label>
                        <textarea
                            style="white-space: pre-wrap"
                            matInput
                            class="gh-bordered-textarea"
                            autocomplete="off"
                            name=""
                            id=""
                            cols="30"
                            rows="5"
                            formControlName="publicNotes"
                        >
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="mx-0 row section">
            <div class="col-12">
                <div class="mt-2 gh-bordered-textarea-container">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Notes to Self:</mat-label>
                        <textarea
                            style="white-space: pre-wrap"
                            matInput
                            class="gh-bordered-textarea"
                            autocomplete="off"
                            name=""
                            id=""
                            cols="30"
                            rows="5"
                            formControlName="notesToSelf"
                        >
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-card-content>
