import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-trending-data',
    templateUrl: './trending-data.component.html',
    styleUrls: ['./trending-data.component.scss'],
})
export class TrendingDataComponent implements OnInit {
    constructor(
        private backendApiGamesService: BackendAPIGamesService,
        private dialog: MatDialog,
        private screenSizeService: ScreenSizeService,
    ) {}

    trendingData
    isMobileScreen
    async ngOnInit(): Promise<void> {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.trendingData = await this.backendApiGamesService.listTrendingFactorData(50)
    }

    async openGameDetails(element) {
        // todo this is the LP ide not game
        let gameDetails = await this.backendApiGamesService.GetGame(element.id)

        let inputData = {
            limitDataFecthing: true,
            game: gameDetails,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: gameDetails.title,
                component: MdGameDetailComponent,
                hasSubmitButton: false,
                hasCloseButton: true,
                hasCancelButton: false,
                inputData: inputData, //! what does limit data fetching do???
                allowParentClose: true,
            },
            maxWidth: '90%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    getBadgeColor(order: number): string {
        if (order < 11) {
            return 'red'
        } else if (order >= 11 && order <= 20) {
            return 'orange'
        } else {
            return 'green'
        }
    }
}
