// compose-message.component.ts

import { Component, Input, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-compose-message',
    templateUrl: './compose-message.component.html',
    styleUrls: ['./compose-message.component.scss'],
})
export class ComposeMessageComponent implements OnInit {
    // Selected users from single-select and multi-select components
    selectedUser: any[] = []
    selectedUsersFromMultiselect: any[] = []
    allUsersSelected = false

    // Current user information
    user: any = null

    // Message content
    sendMessageMessage: string = ''
    previousMessage: string = ''
    previousMessageExists = false

    // Observables for friends and core users
    myFriends = new BehaviorSubject<any[]>([])
    myFriends$ = this.myFriends.asObservable()

    coreUsers = new BehaviorSubject<any[]>([])
    coreUsers$ = this.coreUsers.asObservable()

    // Input data from parent component
    inputData: any = null

    // Setter for input data from parent
    @Input() set _inputData(inputData: any) {
        if (!inputData) {
            return
        }
        this.inputData = inputData
        this.previousMessageExists = !!this.inputData.message

        let coreUsersToProcess = this.inputData.coreUsers || []
        coreUsersToProcess = coreUsersToProcess.filter((user: any) => user.coreSK !== '#undefined')
        this.coreUsers.next(coreUsersToProcess)
    }

    // Setter for parent submit click
    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            if (!this.sendMessageMessage || this.sendMessageMessage.trim() === '') {
                alert('Message Required')
            } else {
                this.sendMessageToASpecificUsers()
            }
        }
    }

    constructor(
        private backendAPIUsersService: BackendAPIUsersService,
        private backendApiMessageService: BackendAPIMessageService,
        private dialogRef: MatDialogRef<ComposeMessageComponent>,
        private usersService: UsersService,
    ) {}

    ngOnInit(): void {
        // Subscribe to the current user
        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((user: any) => {
                this.user = user

                // Pre-select user if inputData has a different user
                if (
                    this.inputData &&
                    this.inputData.user &&
                    this.user.username !== this.inputData.user.username
                ) {
                    this.selectedUser = [
                        {
                            title: this.inputData.user.title,
                            username: this.inputData.user.username,
                        },
                    ]
                }

                // Pre-fill previous message if available
                if (this.inputData && this.inputData.message) {
                    this.previousMessage = this.inputData.message.message
                    this.selectedUser = [
                        {
                            title: this.inputData.message.sentFromTitle,
                            username: this.inputData.message.createdBy,
                        },
                    ]
                }
            })

        // Combine friends and coreUsers to transform friends data
        combineLatest([this.usersService.myFriends$, this.coreUsers$])
            .pipe(untilDestroyed(this))
            .subscribe(([friends, coreUsers]) => {
                if (!friends || !coreUsers) {
                    this.myFriends.next(friends || [])
                    return
                }
                const transformedFriends = friends.map((friend: any) => {
                    // Remove '#friendOf#' from the sk field
                    const cleanedUsername = friend.sk.replace('#friendOf#', '')

                    // Find the matched user in coreUsers using the cleaned username
                    const matchedUser = coreUsers.find((u: any) => u.username === cleanedUsername)

                    return {
                        ...friend,
                        // If found, use matchedUser's title; otherwise, fallback to cleanedUsername
                        title: matchedUser ? matchedUser.title : cleanedUsername,
                        username: matchedUser ? matchedUser.username : cleanedUsername, // Ensure username is present
                    }
                })

                this.myFriends.next(transformedFriends)
            })
    }

    /**
     * Handler for selecting users from single-select component
     * @param usersSelected Array of selected User objects
     */
    selectUser(usersSelected: any[]): void {
        this.selectedUser = usersSelected
    }

    /**
     * Handler for changes in multi-select component
     * @param event Event data from multi-select component
     */
    onMultiSelectChange(event: any): void {
        const allFriends = this.myFriends.value || []
        this.allUsersSelected = this.selectedUsersFromMultiselect.length === allFriends.length
    }

    /**
     * Toggles the selection of all users in the multi-select component
     */
    toggleSelectAll(): void {
        const allFriends = this.myFriends.value || []
        if (this.allUsersSelected) {
            this.selectedUsersFromMultiselect = []
            this.allUsersSelected = false
        } else {
            this.selectedUsersFromMultiselect = [...allFriends]
            this.allUsersSelected = true
        }
    }

    /**
     * Sends messages to the selected users and closes the dialog with the result
     */
    async sendMessageToASpecificUsers(): Promise<void> {
        const recipients = [...this.selectedUser, ...this.selectedUsersFromMultiselect]

        if (!recipients || recipients.length === 0) {
            alert('No user selected')
            return
        }

        // Array to collect sentToTitle for all recipients
        const sentToTitles: string[] = []

        const promises = recipients.map((recipient: any) => {
            const payload = {
                message: this.sendMessageMessage,
                createdByUID: this.user.username,
                sentFromTitle: this.user.title,
                sentToTitle: recipient.title,
                sentToUID: recipient.username || recipient.sk.replace('#friendOf#', ''),
            }

            // Collecting sentToTitle
            sentToTitles.push(recipient.title)

            return this.backendApiMessageService.createMessageItem(payload)
        })

        try {
            await Promise.all(promises)
            this.sendMessageMessage = ''

            // Close the dialog and pass back the success message and sentToTitles
            this.dialogRef.close({
                successMessage: 'Messages sent successfully',
                sentToTitles: sentToTitles,
            })
        } catch (error) {
            console.error('Error sending messages:', error)
            // Optionally, you can pass back an error message
            this.dialogRef.close({
                successMessage: 'Failed to send some or all messages',
                sentToTitles: sentToTitles,
            })
        }
    }

    /**
     * Creates request items for the selected users
     */
    async createRequestItem(): Promise<void> {
        const recipients = [...this.selectedUser, ...this.selectedUsersFromMultiselect]

        if (!recipients || recipients.length === 0) {
            alert('No user selected')
            return
        }

        const promises = recipients.map((recipient: any) => {
            let sendMessageInput = {
                type: this.inputData?.type,
                createdByUID: this.user.username,
                sentFromTitle: this.user.title,
                sentToTitle: recipient.title,
                sentToUID: recipient.username || recipient.id,
            }
            return this.backendApiMessageService.createRequestItem(sendMessageInput)
        })

        try {
            await Promise.all(promises)
            this.sendMessageMessage = ''
            // Optionally, you can close the dialog or provide feedback
            this.dialogRef.close({
                successMessage: 'Requests created successfully',
                sentToTitles: recipients.map((recipient) => recipient.title),
            })
        } catch (error) {
            console.error('Error creating request items:', error)
            // Optionally, provide feedback to the user
            this.dialogRef.close({
                successMessage: 'Failed to create some or all requests',
                sentToTitles: recipients.map((recipient) => recipient.title),
            })
        }
    }
}
