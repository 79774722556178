<div class="parent-container" *ngIf="coreGames$ | async as games; else isLoading">
    <div class="m-0 row">
        <div class="w-100 col-12">
            <app-gh-variable-length-autocomplete-selector
                #variableLengthSelector
                [_limit]="12"
                [_itemsAreObjects]="true"
                [_options]="games"
                [_currentSelections]="collection"
                (onChangeEmitter)="onSelectionsChanged($event)"
            >
            </app-gh-variable-length-autocomplete-selector>
        </div>
    </div>

    <!-- MOBILE -->
    <div class="mx-0 mt-5 row" *ngIf="isMobileScreen">
        <div class="p-2 col-6" *ngFor="let item of collection">
            <app-gh-expandable-image
                [_img]="item.mainImageFiles"
                [_title]="item.title"
                [_caption]=""
                matTooltip="{{ item.title }}"
                matTooltipPosition="above"
            >
            </app-gh-expandable-image>
        </div>
    </div>

    <!-- DESKTOP -->
    <div class="mx-0 mt-5 row" *ngIf="!isMobileScreen">
        <div class="p-2 col-3" *ngFor="let item of collection">
            <app-gh-expandable-image
                [_img]="item.mainImageFiles"
                [_title]="item.title"
                [_caption]=""
                matTooltip="{{ item.title }}"
                matTooltipPosition="above"
            >
            </app-gh-expandable-image>
        </div>
    </div>
</div>

<ng-template #isLoading>
    <div class="mt-5 gh-loading-container">
        <app-gh-loading-spinner></app-gh-loading-spinner>
    </div>
</ng-template>
